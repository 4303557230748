import axios from "axios";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';


class BaseService {
    HOST = '';

    constructor() {
        this.HOST = process.env.REACT_APP_HOST;
    }

    createHeader(){

      this.service = axios.create({
          headers: {
              'Authorization': this.authHeader(),
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Expose-Headers': 'X-refreshtoken, X-Authorization, X-Access-Token, X-Uid, X-Requested-With',
              'Access-Control-Allow-Headers': 'X-Requested-With, Origin, X-Requested-With, Content-Type, Accept, Authorization, refreshtoken, X-refreshtoken, X-Authorization, X-Access-Token, X-Uid'
          }
      });
      this.service.interceptors.response.use(this.handleSuccess, this.handleError);
    }

    authHeader() {
        let token = localStorage.getItem('token');

        if (token) {
            return 'Bearer ' + token;
        } else {
            return '';
        }
    }

    handleSuccess(response) {
        return response;
    }

    handleError(error){
        console.log("ERROR", error.response.status, error)
        if (!error || !error.response){
            return Promise.reject(error)
        }
        switch (error.response.status) {
            case 401:
                //let  history = useHistory()
                //history.push("/login");
                //history.pushState(null, 'login');
                break;
            case 404:
                break;
            default:
                break;
        }
        return Promise.reject(error)
    }

    doRequest(params, callback, isLogin=false, withHeader=true, file=false, nameFile = ''){
        if(withHeader){
            this.createHeader();
        }
        //const errors = strings.error
        //const netMsg = strings.net

        return this.service.request(params).then((response) => {

            //let error  = response.error

          /*  if (isLogin){
                data   = response.data
            }*/
            let status = response.status

            if (status !== 200){
                return
            }

            if (file) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', nameFile); //or any other extension
              document.body.appendChild(link);
              link.click();
            } else{
              let data   = response.data

              if (params.method === 'PUT' || params.method === 'POST'){
                    if (!isLogin) {
                      const msg = "Guardado correctamente"
                      toast.notify(msg, {position:'top', duration: 5000})
                    }
              } else if (params.method === 'DELETE'){
                  const msg = "Borrado correctamente"
                  toast.notify(msg, {position:'top', duration: 5000})
              }

              callback(data, null)
            }


        }).catch(err =>{
            console.log(err.response?.status, err)
            if(err.response !== undefined && err.response.data !== undefined){
              let bodyError = err.response.data;
              if (bodyError && bodyError.message){
                //if (params.method === 'DELETE'){
                //  toast.notify("Esta relaccionada con otros elementos por lo que no puede ser borrada", {position:'top', duration: 10000})
                //} else {
                  toast.notify(bodyError.message, {position:'top', duration: 10000})
                //}
              }else if (params.method === 'PUT' || params.method === 'POST'){
                    if (!isLogin){
                        const msg = "Error al guardar"
                        toast.notify(msg, {position:'top', duration: 10000})
                    }
              } else if (params.method === 'DELETE'){
                    if (!isLogin){
                        const msg = "Esta relaccionada con otros elementos por lo que no puede ser borrada"
                        toast.notify(msg, {position:'top', duration: 10000})
                    }
              }
            } else if (params.method === 'PUT' || params.method === 'POST'){
                  if (!isLogin){
                    const msg = "Error al guardar"
                    toast.notify(msg, {position:'top', duration: 10000})
                  }
            } else if (params.method === 'DELETE'){
                const msg = "Esta relaccionada con otros elementos por lo que no puede ser borrada"
                toast.notify(msg, {position:'top', duration: 10000})

            }
            callback(null, err)
        });
    }

    async get(path, callback) {
        let params = {
            method: 'GET',
            url: this.HOST+path,
            responseType: 'json'
        }
        return this.doRequest(params, callback)
    }

    getFile(path,name, callback) {
        let params = {
            method: 'GET',
            url: this.HOST+path,
            responseType: 'blob'
        }
        return this.doRequest(params, callback, false,true, true,name)
    }

    getFilePost(path,name,payload, callback) {
        let params = {
            method: 'POST',
            url: this.HOST+path,
            responseType: 'blob',
            data: payload
        }
        return this.doRequest(params, callback, false,true, true,name)
    }

    patch(path, payload, callback) {
        let params = {
            method: 'PATCH',
            url: this.HOST+path,
            responseType: 'json',
            data: payload
        }
        return this.doRequest(params, callback)
    }

    post(path, payload, callback, isLogin=false) {
        let params = {
            method: 'POST',
            url: this.HOST+path,
            responseType: 'json',
            data: payload
        }
        return this.doRequest(params, callback, isLogin)
    }

    postMultipartWithName(url, file, filename, callback, isLogin=false){

        let formData = new FormData();
        formData.append('file', file,filename);

        let params = {
            method: 'POST',
            url: this.HOST+url,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return this.doRequest(params, callback, isLogin)
    }

    postMultipart(url, file, callback, isLogin=false){

        let formData = new FormData();
        formData.append('file', file);

        let params = {
            method: 'POST',
            url: this.HOST+url,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return this.doRequest(params, callback, isLogin)
    }

    delete(path, callback) {
        let params = {
            method: 'DELETE',
            url: this.HOST+path,
            responseType: 'json'
        }
        return this.doRequest(params, callback)
        //return this.service.delete(path).then((response) => callback(response.status, response.data));
    }

    put(path, payload, callback) {


        let params = {
            method: 'PUT',
            url: this.HOST+path,
            responseType: 'json',
            data: payload
        }
        return this.doRequest(params, callback, false)

    }
}
export default new BaseService();
