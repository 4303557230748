import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CrudForm from '../../crud/CrudForm';
import { cashService as service, printService, laundryService } from '../../../services';
import { isAdmin, idLaundry, nameLaundry } from '../../utils/Utils';
import Button from '@material-ui/core/Button';

const moment = require('moment');

const initElement = {
      laundry: { id: isAdmin() ? null : idLaundry(), name: isAdmin() ? '' : nameLaundry() },
      date: moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00Z',
      hourFrom: '08:00',
      hourTo: moment(new Date()).format('HH:mm'),
      effectiveInit: 0.0,
      effectiveRetire: 0.0,
      effective: 0.0,
      web: 0.0,
      card: 0.0,
      effectiveClose: 0.0,
      effectiveDiff: 0.0,
      effectivePending: 0.0,
};

export default function FormCash() {
      const [objectform, setObjectform] = React.useState({ ...initElement });
      let { id } = useParams();

      const setValueEffectiveInit = (setValueField, objectform, value, valueform, setValueform) => {
            if (objectform) {
                  debugger;

                  var effecopen = +objectform['effectiveInit'];
                  var effective = +objectform['effective'];
                  var effectiveRetire = +objectform['effectiveRetire'];
                  var effectiveclose = effecopen + effectiveRetire - effectiveRetire;

                  var effectiveDiff = effectiveclose - effective + effectiveRetire - effecopen;

                  var newobject = setValueField(objectform, 'effectiveDiff', effectiveDiff);
                  newobject = setValueField(objectform, 'effectiveClose', effectiveclose);
                  setValueform((currentValueform) => ({ ...currentValueform, ['effectiveDiff']: effectiveDiff }));
                  setValueform((currentValueform) => ({ ...currentValueform, ['effectiveClose']: effectiveclose }));

                  return newobject;
            }
      };

      const setValueEffective = (setValueField, objectform, value, valueform, setValueform) => {
            if (objectform) {
                  var effecopen = objectform['effectiveInit'];
                  var effective = objectform['effective'];
                  var effectiveclose = objectform['effectiveClose'];
                  var effectiveRetire = objectform['effectiveRetire'];

                  var effectiveDiff = effectiveclose - effective + effectiveRetire - effecopen;

                  var newobject = setValueField(objectform, 'effectiveDiff', effectiveDiff);
                  setValueform((currentValueform) => ({ ...currentValueform, ['effectiveDiff']: effectiveDiff }));

                  return newobject;
            }
      };

      const setValueData = (setValueField, objectform, value, valueform, setValueform) => {
            if (objectform && objectform['date'] && objectform['date'] != '' && objectform['hourFrom'] && objectform['hourFrom'] != '' && objectform['hourTo'] && objectform['hourTo'] != '') {
                  var newobject = objectform;

                  service.cashdata(objectform, (data, error) => {
                        if (data && data.data) {
                              var close = objectform['effectiveInit'] ? objectform['effectiveInit'] : 0;

                              if (data.data.effective) {
                                    newobject = setValueField(objectform, 'effective', data.data.effective);
                                    close = close + data.data.effective;
                                    setValueform((currentValueform) => ({ ...currentValueform, ['effective']: data.data.effective }));
                              } else {
                                    newobject = setValueField(objectform, 'effective', 0);
                                    setValueform((currentValueform) => ({ ...currentValueform, ['effective']: 0 }));
                              }

                              if (data.data.card) {
                                    newobject = setValueField(objectform, 'card', data.data.card);
                                    setValueform((currentValueform) => ({ ...currentValueform, ['card']: data.data.card }));
                              } else {
                                    newobject = setValueField(objectform, 'card', 0);
                                    setValueform((currentValueform) => ({ ...currentValueform, ['card']: 0 }));
                              }
                              if (data.data.web) {
                                    newobject = setValueField(objectform, 'web', data.data.web);
                                    setValueform((currentValueform) => ({ ...currentValueform, ['web']: data.data.web }));
                              } else {
                                    newobject = setValueField(objectform, 'web', 0);
                                    setValueform((currentValueform) => ({ ...currentValueform, ['web']: 0 }));
                              }
                              if (data.data.effectivePending) {
                                    newobject = setValueField(objectform, 'effectivePending', data.data.effectivePending);
                                    setValueform((currentValueform) => ({ ...currentValueform, ['effectivePending']: data.data.effectivePending }));
                              } else {
                                    newobject = setValueField(objectform, 'effectivePending', 0);
                                    setValueform((currentValueform) => ({ ...currentValueform, ['effectivePending']: 0 }));
                              }
                              if (data.data.retire) {
                                    newobject = setValueField(objectform, 'effectiveRetire', data.data.retire);
                                    close = close - data.data.retire;
                                    setValueform((currentValueform) => ({ ...currentValueform, ['effectiveRetire']: data.data.retire }));
                              } else {
                                    newobject = setValueField(objectform, 'effectiveRetire', 0);
                                    setValueform((currentValueform) => ({ ...currentValueform, ['effectiveRetire']: 0 }));
                              }

                              setValueform((currentValueform) => ({ ...currentValueform, ['effectiveClose']: close }));
                        }
                  });

                  return newobject;
            } else {
                  return objectform;
            }
      };

      const estructureform = [
            {
                  type: 'input',
                  name: 'date',
                  label: 'Desde',
                  inputtype: 'date',
                  col: 4,
                  setvalue: setValueData,
            },
            {
                  type: 'input',
                  name: 'hourFrom',
                  label: 'H. Inicio',
                  inputtype: 'time',
                  col: 4,
                  setvalue: setValueData,
            },
            {
                  type: 'input',
                  name: 'hourTo',
                  label: 'H. Fin',
                  inputtype: 'time',
                  col: 4,
                  setvalue: setValueData,
            },
            {
                  type: 'input',
                  name: 'effectiveInit',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Efectio al abrir',
                  col: 4,
                  setvalue: setValueEffectiveInit,
            },
            {
                  type: 'input',
                  name: 'effective',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Compras en efectivo',
                  col: 4,
                  readonly: true,
            },
            {
                  type: 'input',
                  name: 'effectiveRetire',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Retirada efectivo',
                  col: 4,
                  readonly: true,
            },
            {
                  type: 'input',
                  name: 'card',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Compras en tarjeta/bizum/...',
                  col: 4,
                  readonly: true,
            },
            {
                  type: 'input',
                  name: 'effectiveClose',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Efectivo al cerrar',
                  col: 4,
                  setvalue: setValueEffective,
            },
            {
                  type: 'input',
                  name: 'effectiveDiff',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Cierre de caja',
                  col: 4,
                  readonly: true,
            },
            {
                  type: 'input',
                  name: 'effectivePending',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Pedidos de hoy no pagados',
                  col: 4,
                  readonly: true,
            },
      ];

      const recoveryElement = function (objectedit, element) {
            objectedit['date'] = element.date ? element.date : null;
            objectedit['hourFrom'] = element.hourFrom ? element.hourFrom : null;
            objectedit['hourTo'] = element.hourTo ? element.hourTo : null;
            objectedit['effectiveInit'] = element.effectiveInit ? element.effectiveInit : 0.0;
            objectedit['effective'] = element.effective ? element.effective : 0.0;
            objectedit['effectiveRetire'] = element.effectiveRetire ? element.effectiveRetire : 0.0;
            objectedit['web'] = element.web ? element.web : 0.0;
            objectedit['card'] = element.card ? element.card : 0.0;
            objectedit['effectiveClose'] = element.effectiveClose ? element.effectiveClose : 0.0;
            objectedit['effectiveDiff'] = element.effectiveDiff ? element.effectiveDiff : 0.0;
            objectedit['effectivePending'] = element.effectivePending ? element.effectivePending : 0.0;

            return objectedit;
      };

      const isValid = function (object) {
            if (!object['date'] || object['date'] == '') {
                  toast.notify('Debe indicar la fecha', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['hourFrom'] || object['hourFrom'].length == 0) {
                  toast.notify('Debe indicar la hora de inicio', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['hourTo'] || object['hourTo'].length == 0) {
                  toast.notify('Debe indicar la hora de fin', { position: 'top', duration: 10000 });
                  return false;
            }
            return true;
      };

      const searchData = (newobjectForm) => {
            service.cashdata(newobjectForm, (data, error) => {
                  if (data && data.data) {
                        var close = newobjectForm['effectiveInit'] ? newobjectForm['effectiveInit'] : 0;

                        if (data.data.effective) {
                              close = close + data.data.effective;
                              newobjectForm['effective'] = data.data.effective;
                        }
                        if (data.data.card) {
                              newobjectForm['card'] = data.data.card;
                        }
                        if (data.data.web) {
                              newobjectForm['web'] = data.data.web;
                        }

                        if (data.data.effectivePending) {
                              newobjectForm['effectivePending'] = data.data.effectivePending;
                        }
                        if (data.data.retire) {
                              newobjectForm['effectiveRetire'] = data.data.retire;
                              close = close - data.data.retire;
                        }

                        newobjectForm['effectiveClose'] = close;

                        setObjectform(newobjectForm);
                  }
            });
      };

      const initTask = (setObject, setReload) => {
            service.last((data, error) => {
                  var newobjectForm = { ...objectform };
                  debugger;

                  let sameDay = false;

                  if (data.data) {
                        var date = data.data['date'];

                        var last = moment(Date.parse(date));

                        newobjectForm['effectiveInit'] = data.data['effectiveClose'];
                        if (last.isSame(moment(new Date()), 'day')) {
                              sameDay = true;
                              let min = Number(data.data['hourTo'].split(':')[1]) + 2;
                              let hour = Number(data.data['hourTo'].split(':')[0]);

                              if (min < 10) {
                                    newobjectForm['hourFrom'] = data.data['hourTo'].split(':')[0] + ':0' + min;
                              } else if (min > 60) {
                                    hour = hour + 1;
                                    min = min - 60;
                                    newobjectForm['hourFrom'] = (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min);
                              } else {
                                    newobjectForm['hourFrom'] = data.data['hourTo'].split(':')[0] + ':' + min;
                              }
                        }
                  }

                  if (sameDay) {
                        searchData(newobjectForm);
                  } else {
                        laundryService.one(idLaundry(), (data, error) => {
                              if (data.data) {
                                    var ldata = data.data;
                                    if (ldata.initCash) {
                                          newobjectForm['effectiveInit'] = ldata.initCash;
                                    }
                                    if (ldata.hourFrom) {
                                          newobjectForm['hourFrom'] = ldata.hourFrom;
                                    }
                                    if (ldata.hourTo) {
                                          newobjectForm['hourTo'] = ldata.hourTo;
                                    }
                              }

                              searchData(newobjectForm);
                        });
                  }
            });
      };

      const handlePrintTicker = (cash) => {
            debugger;
            printService.cash(cash, (data, error) => {});
      };

      const moreActions = (getObject) => {
            return (
                  <>
                        {id && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={() => handlePrintTicker(getObject())}>
                                    {' '}
                                    Imprimir
                              </Button>
                        )}
                  </>
            );
      };

      return (
            <CrudForm
                  objectform={{ ...objectform }}
                  estructureform={estructureform}
                  titleEdit={'Editar cierre'}
                  titleNew={'Nuevo cierre'}
                  urledit={'/cash/edit/'}
                  urlCancel={'/cash'}
                  service={service}
                  recoveryElement={recoveryElement}
                  valid={isValid}
                  initTask={initTask}
                  moreActions={moreActions}
            />
      );
}
