import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import CrudFormTable from "../../crud/CrudFormTable"
import Grid from '@material-ui/core/Grid';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import Form from "../../form/Form"
import Tab from "./Tab"
import {laundryService as service, productService} from "../../../services"
import {isAdmin,idLaundry,nameLaundry,setNumDaysAndTicketDefault, web} from '../../utils/Utils'

const initElement = {
     name:"",
     email:null,
     picture:null,
     street:"",
     phone:"",
     number:"",
     door:"",
     floor:"",
     stairs:"",
     city:"",
     postalcode:"",
     active:true,
     latitude:null,
     longitude:null,
     nif:null
  };


export default function FormLaundry() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams();
  const [idRow, setIdrow] = useState(id);


  const estructureform =  [
          {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
           type:"input",
           name: "email",
           label: "Email",
           col: 4
          },
          {
           type:"input",
           name: "nif",
           label: "Cif/Nif",
           col: 4
          },
          {
           type:"input",
           name: "phone",
           label: "Teléfono",
           col: 4
          },
          {
           type:"input",
           name: "street",
           label: "Calle",
           col: 4
          },
          {
           type:"input",
           name: "number",
           label: "Nº",
           col: 2
          },
          {
           type:"input",
           name: "door",
           label: "Puerta",
           col: 2
          },
          {
           type:"input",
           name: "floor",
           label: "Piso",
           col: 2
          },
          {
           type:"input",
           name: "stairs",
           label: "Escalera",
           col: 2
          },
          {
           type:"input",
           name: "postalcode",
           label: "Código Postal",
           col: 4
          },
          {
           type:"input",
           name: "city",
           label: "Ciudad",
           col: 4
          },
          {
           type:"input",
           name: "country",
           label: "País",
           col: 4
          },
          {
           type:"input",
           name: "latitude",
           label: "Latitud",
           col: 4
          },
          {
           type:"input",
           name: "longitude",
           label: "Longitud",
           col: 4
          },
          {
           type:"input",
           name: "textTicket",
           label: "Texto de ticket",
           col: 4
          },
          {
           type:"input",
           name: "serieBill",
           label: "Serie Fac.",
           col: 4
          },
          {
           type:"input",
           name: "numBill",
           label: "Nº Fact. Ini",
           col: 4
          },
          {
           type:"input",
           name: "serieTicket",
           label: "Serie Factura Ticket",
           col: 4
          },
          {
           type:"input",
           name: "serieRectificative",
           label: "Serie Factura Rectificativa",
           col: 4
          },
          {
           type:"checkbox",
           name: "active",
           label: "Impresion Ticket por defecto",
           col: 4
          },
          {
           type:"input",
           name: "daysDefault",
           label: "Días pro defecto en pedidos TPV",
           col: 4,
           inputtype:"number"
          },
          {
           type:"checkbox",
           name: "active",
           label: "Activa",
           col: 2
          },
          {
           type:"checkbox",
           name: "web",
           label: "Activar función web",
           col: 2
          },
          {
           type:"checkbox",
           name: "numberintpv",
           label: "Indicar numero lavado al recoger",
           col: 2
          },
          {
           type:"image",
           name: "picture",
           label: "Logo",
           col: 4
          },
          {
           type:"title",
           name: "titleAsesor",
           label: "Envío de facturas al asesor",
           col: 12
          },
          {
           type:"input",
           name: "emailAsesor",
           label: "Email asesor",
           col: 6,
          },
          {
           type:"input",
           name: "subjectAsesor",
           label: "Asunto de email",
           col: 6
          },
          {
           type:"input",
           name: "textMailAsesor",
           label: "Texto de email",
           col: 6
          },
          {
           type:"checkbox",
           name: "automatic",
           label: "Envíar automáticamente",
           col: 6
          },
          {
           type:"checkbox",
           name: "automatic",
           label: "Envíar automáticamente",
           col: 6
          },
          {
            type:"input",
            name: "hourFrom",
            label: "H. Inicio para cierre de caja",
            inputtype: "time",
            col: 4,
           },
           {
             type:"input",
             name: "hourTo",
             label: "H. Fin  para cierre de caja",
             inputtype: "time",
             col: 4,
            },
            {
              type:"input",
              name: "initCash",
              label: "Dinero inicial en apertura de caja",
              inputtype: "number",
              step:0.01,
              col: 4,
             },
    ];


      const estructureformLaundry =  [
              {
               type:"input",
               name: "name",
               label: "Nombre",
               col: 4,
               readonly: true,
              },
              {
               type:"input",
               name: "email",
               label: "Email",
               col: 4,
               readonly: true,
              },
              {
               type:"input",
               name: "nif",
               label: "Cif/Nif",
               col: 4
              },
              {
               type:"input",
               name: "phone",
               label: "Teléfono",
               col: 4
              },
              {
               type:"input",
               name: "street",
               label: "Calle",
               col: 4
              },
              {
               type:"input",
               name: "number",
               label: "Nº",
               col: 2
              },
              {
               type:"input",
               name: "door",
               label: "Puerta",
               col: 2
              },
              {
               type:"input",
               name: "floor",
               label: "Piso",
               col: 2
              },
              {
               type:"input",
               name: "stairs",
               label: "Escalera",
               col: 2
              },
              {
               type:"input",
               name: "postalcode",
               label: "Código Postal",
               col: 4
              },
              {
               type:"input",
               name: "city",
               label: "Ciudad",
               col: 4
              },
              {
               type:"input",
               name: "textTicket",
               label: "Texto de ticket",
               col: 4
              },
              {
               type:"input",
               name: "serieBill",
               label: "Serie Fac.",
               col: 4
              },
              {
               type:"input",
               name: "numBill",
               label: "Nº Fact. Ini",
               col: 4
              },
              {
               type:"input",
               name: "serieTicket",
               label: "Serie Factura Ticket",
               col: 4
              },
              {
               type:"input",
               name: "serieRectificative",
               label: "Serie Factura Rectificativa",
               col: 4
              },
              {
               type:"checkbox",
               name: "printticket",
               label: "Impresion Ticket por defecto",
               col: 4
              },
              {
               type:"input",
               name: "daysDefault",
               label: "Días por defecto en pedidos TPV",
               col: 4,
               inputtype:"number"
              },

              {
                type:"title",
                name: "titlecash",
                label: "Configuración cierre de caja",
                col: 12
               },
              {
                type:"input",
                name: "hourFrom",
                label: "H. Inicio para cierre de caja",
                inputtype: "time",
                col: 4,
               },
               {
                 type:"input",
                 name: "hourTo",
                 label: "H. Fin  para cierre de caja",
                 inputtype: "time",
                 col: 4,
                },
                {
                  type:"input",
                  name: "initCash",
                  label: "Dinero inicial en apertura de caja",
                  inputtype: "number",
                  step:0.01,
                  col: 4,
                 },

              {
                type:"title",
                name: "titleimage",
                label: "",
                col: 12
               },
              {
               type:"image",
               name: "picture",
               label: "Logo",
               col: 4
              },
              {
               type:"title",
               name: "titleAsesor",
               label: "Envío de facturas al asesor",
               col: 12
              },
              {
               type:"input",
               name: "emailAsesor",
               label: "Email asesor",
               col: 6,
              },
              {
               type:"input",
               name: "subjectAsesor",
               label: "Asunto de email",
               col: 6
              },
              {
               type:"input",
               name: "textMailAsesor",
               label: "Texto de email",
               col: 6
              },
              {
               type:"checkbox",
               name: "automatic",
               label: "Envíar automáticamente",
               col: 6
              },
        ];


  const recoveryElement = function(objectedit,element){


    objectedit['name']=element.name?element.name:"";
    objectedit['email']=element.email?element.email:"";
    objectedit['picture']=element.picture?element.picture:null;
    objectedit['street']=element.street?element.street:"";
    objectedit['phone']=element.phone?element.phone:"";
    objectedit['number']=element.number?element.number:null;
    objectedit['door']=element.door?element.door:"";
    objectedit['floor']=element.floor?element.floor:"";
    objectedit['stairs']=element.stairs?element.stairs:null;
    objectedit['city']=element.city?element.city:"";
    objectedit['postalcode']=element.postalcode?element.postalcode:"";
    objectedit['active']=element.active?element.active:null;
    objectedit['latitude']=element.latitude?element.latitude:"";
    objectedit['longitude']=element.longitude?element.longitude:"";
    objectedit['nif']=element.nif?element.nif:"";
    objectedit['emailAsesor']=element.emailAsesor?element.emailAsesor:null;
    objectedit['subjectAsesor']=element.subjectAsesor?element.subjectAsesor:"";
    objectedit['textMailAsesor']=element.textMailAsesor?element.textMailAsesor:"";
    objectedit['automatic']=element.automatic?element.automatic:null;
    objectedit['printticket']=element.printticket?element.printticket:false;
    objectedit['daysDefault']=element.daysDefault?element.daysDefault:3;
    objectedit['serieTicket']=element.serieTicket?element.serieTicket:"";
    objectedit['serieRectificative']=element.serieRectificative?element.serieRectificative:null;
    objectedit['web']=element.web?element.web:false;
    objectedit['numberintpv']=element.numberintpv?element.numberintpv:false;


    return objectedit;
  }


  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['email']){
        toast.notify("Debe indicar el Email", {position:'top', duration: 10000})
        return false;
      }
      setNumDaysAndTicketDefault(object['daysDefault'],object['printticket'])
      return true;
  }



  return (
      <CrudForm
          objectform = {{...initElement}}
          estructureform = {isAdmin()?estructureform:estructureformLaundry}
          titleEdit = {"Editar lavandería"}
          titleNew = {"Nueva lavandería"}
          urledit={"/laundry/edit/"}
          urlCancel={"/laundry"}
          service = {service}
          recoveryElement = {recoveryElement}
          valid = {isValid}
          othersform = {<Tab />}
          haveother = {isAdmin() || web()}
        />

  );
}
