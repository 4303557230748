import React from 'react';
import {BrowserRouter as Router, Route, Redirect,Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import PrivateRoute from './PrivateRoute';
import LoginRoute from './LoginRoute';
import Login from './Login';
import Dashboard from './component/page/dashboard/Dashboard';
import AlertAvailable from './component/page/alertavailable/AlertAvailable';
import FormAlertavailable from './component/page/alertavailable/FormAlertavailable';
import StateOrder from './component/page/master/StateOrder';
import FormStateOrder from './component/page/master/FormStateOrder';
import StateWork from './component/page/master/StateWork';
import FormStateWork from './component/page/master/FormStateWork';
import FormConfiguration from './component/page/configuration/FormConfiguration';
import Category from './component/page/products/Category';
import FormCategory from './component/page/products/FormCategory';
import Product from './component/page/products/Product';
import ProductLaundry from './component/page/products/ProductLaundry';
import FormProduct from './component/page/products/FormProduct';
import Laundry from './component/page/laundry/Laundry';
import FormLaundry from './component/page/laundry/FormLaundry';
import Order from './component/page/order/Order';
import FormOrder from './component/page/order/FormOrder';
import FormWork from './component/page/work/FormWork';
import WizardWork from './component/page/work/WizardWork';
import Work from './component/page/work/Work';
import Suscription from './component/page/suscription/Suscription';
import Administrator from './component/page/user/Administrator';
import FormAdministrator from './component/page/user/FormAdministrator';
import Client from './component/page/user/Client';
import FormClient from './component/page/user/FormClient';
import Holiday from './component/page/holiday/Holiday';
import FormHoliday from './component/page/holiday/FormHoliday';
import Tpv from './component/page/tpv/Tpv';
import Tickets from './component/page/tpv/Tickets';
import Bill from './component/page/bill/Bill';
import FormBill from './component/page/bill/FormBill';
import FormCash from './component/page/cash/FormCash';
import Cash from './component/page/cash/Cash';
import FormCashout from './component/page/cashout/FormCashout';
import Cashout from './component/page/cashout/CashOut';
import ResolveIssue from './component/page/resolveissue/ResolveIssue';
import Statistics from './component/page/statistics/Statistics';
import Budget from './component/page/budget/Budget';
import FormBudget from './component/page/budget/FormBudget';
import Operator from './component/page/operator/Operator';
import FormOperator from './component/page/operator/FormOperator';
import Deliverynote from './component/page/deliverynote/Deliverynote';
import FormDeliverynote from './component/page/deliverynote/FormDeliverynote';
import Paymenttype from './component/page/master/PaymentType';
import FormPaymentType from './component/page/master/FormPaymentType';
import PrintConfig from './component/page/print/PrintConfig';
import FormPrintConfig from './component/page/printconfig/FormPrintConfig';
import CategoryLaundry from './component/page/products/CategoryLaundry';
import FormCategoryLaundry from './component/page/products/FormCategoryLaundry';





import jwtDecode from 'jwt-decode';



interface TokenInfo {
  exp: number;
}

function isLogin(){
  var expisold = false;
  if(localStorage.getItem("token") != null && localStorage.getItem("token") != "" && localStorage.getItem("token") != undefined && localStorage.getItem("token") != "undefined"){
    const token:string = localStorage.getItem("token")+'';
    const tokende =  jwtDecode<TokenInfo>(token);
    if (Date.now() >= tokende.exp * 1000) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export default function App() {

  const isLoggedNotIn = isLogin();

  return (
    <>
    <Router>
      <Switch>
        <LoginRoute path="/login" component={Login} />
        <PrivateRoute path="/dashboard/" component={Dashboard} />
        <PrivateRoute path="/alertavailable/new"  component={FormAlertavailable} />
        <PrivateRoute path="/alertavailable/edit/:id"  component={FormAlertavailable} />
        <PrivateRoute path="/alertavailable"  component={AlertAvailable} />
        <PrivateRoute path="/stateorder/new"  component={FormStateOrder} />
        <PrivateRoute path="/stateorder/edit/:id"  component={FormStateOrder} />
        <PrivateRoute path="/stateorder"  component={StateOrder} />
        <PrivateRoute path="/statework/new"  component={FormStateWork} />
        <PrivateRoute path="/statework/edit/:id"  component={FormStateWork} />
        <PrivateRoute path="/statework"  component={StateWork} />
        <PrivateRoute path="/paymenttype/new"  component={FormPaymentType} />
        <PrivateRoute path="/paymenttype/edit/:id"  component={FormPaymentType} />
        <PrivateRoute path="/paymenttype"  component={Paymenttype} />
        <PrivateRoute path="/configuration/edit/:id"  component={FormConfiguration} />
        <PrivateRoute path="/category/new"  component={FormCategory} />
        <PrivateRoute path="/category/edit/:id"  component={FormCategory} />
        <PrivateRoute path="/category"  component={Category} />
        <PrivateRoute path="/categorylaundry/new"  component={FormCategoryLaundry} />
        <PrivateRoute path="/categorylaundry/edit/:id"  component={FormCategoryLaundry} />
        <PrivateRoute path="/categorylaundry"  component={CategoryLaundry} />
        <PrivateRoute path="/product/new"  component={FormProduct} />
        <PrivateRoute path="/product/edit/:id"  component={FormProduct} />
        <PrivateRoute path="/product"  component={Product} />
        <PrivateRoute path="/productlaundry"  component={ProductLaundry} />
        <PrivateRoute path="/laundry/new"  component={FormLaundry} />
        <PrivateRoute path="/laundry/edit/:id"  component={FormLaundry} />
        <PrivateRoute path="/laundry"  component={Laundry} />
        <PrivateRoute path="/order/new"  component={FormOrder} />
        <PrivateRoute path="/order/edit/:id"  component={FormOrder} />
        <PrivateRoute path="/order"  component={Order} />
        <PrivateRoute path="/work/new"  component={FormWork} />
        <PrivateRoute path="/work/edit/wizard/:id/:ticket"  component={WizardWork} />
        <PrivateRoute path="/work/edit/:id/:ticket"  component={FormWork} />
        <PrivateRoute path="/work"  component={Work} />
        <PrivateRoute path="/suscription"  component={Suscription} />
        <PrivateRoute path="/administrator/new"  component={FormAdministrator} />
        <PrivateRoute path="/administrator/edit/:id"  component={FormAdministrator} />
        <PrivateRoute path="/administrator"  component={Administrator} />
        <PrivateRoute path="/client/new"  component={FormClient} />
        <PrivateRoute path="/client/edit/:id"  component={FormClient} />
        <PrivateRoute path="/client"  component={Client} />
        <PrivateRoute path="/holiday/new"  component={FormHoliday} />
        <PrivateRoute path="/holiday/edit/:id"  component={FormHoliday} />
        <PrivateRoute path="/holiday"  component={Holiday} />
        <PrivateRoute path="/tpv/:id"  component={Tpv} />
        <PrivateRoute path="/tpv"  component={Tpv} />
        <PrivateRoute path="/tickets"  component={Tickets} />
        <PrivateRoute path="/bill/new"  component={FormBill} />
        <PrivateRoute path="/bill/edit/:id"  component={FormBill} />
        <PrivateRoute path="/bill"  component={Bill} />
        <PrivateRoute path="/budget/new"  component={FormBudget} />
        <PrivateRoute path="/budget/edit/:id"  component={FormBudget} />
        <PrivateRoute path="/budget"  component={Budget} />
        <PrivateRoute path="/cash/new"  component={FormCash} />
        <PrivateRoute path="/cash/edit/:id"  component={FormCash} />
        <PrivateRoute path="/cash"  component={Cash} />
        <PrivateRoute path="/cashout/new"  component={FormCashout} />
        <PrivateRoute path="/cashout/edit/:id"  component={FormCashout} />
        <PrivateRoute path="/cashout"  component={Cashout} />
        <PrivateRoute path="/statistics"  component={Statistics} />
        <PrivateRoute path="/operator/new"  component={FormOperator} />
        <PrivateRoute path="/operator/edit/:id"  component={FormOperator} />
        <PrivateRoute path="/operator"  component={Operator} />
        <PrivateRoute path="/deliverynote/new"  component={FormDeliverynote} />
        <PrivateRoute path="/deliverynote/edit/:id"  component={FormDeliverynote} />
        <PrivateRoute path="/deliverynote"  component={Deliverynote} />
        <PrivateRoute path="/printconfig/"  component={PrintConfig} />
        <PrivateRoute path="/printservice/edit/:id"  component={FormPrintConfig} />
        <Route path="/resolverincidencia/:token" component={ResolveIssue} />

        

        {isLoggedNotIn ? <Route path="/" component={Login} /> : <PrivateRoute path="/" component={Dashboard} />}
      </Switch>
    </Router>
    </>
  );
}
