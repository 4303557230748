import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {billService as service, clientService, laundryService, printService} from "../../../services"
import {isAdmin} from '../../utils/Utils'
import PrintIcon from '@material-ui/icons/Print';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';

const initFilter =  {
    number:null,
    idUser : null,
    idLaundry: null,
    idState:null,
    web:true
};


export default function Bill() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "number",
      label: "Nº (Sin serie)",
      col: 4
     },
     {
       type:"autocomplete",
       name: "idUser",
       label: "Usuario",
       service: clientService.combo,
       col: 4
      },
      {
       type:"autocomplete",
       name: "idLaundry",
       label: "Lavandería",
       service: laundryService.combo,
       col: 4
      }
  ];

  const createEstructureFilterLaundry =  [
    {
      type:"input",
      name: "number",
      label: "Nº (Sin serie)",
      col: 4
     },
     {
       type:"autocomplete",
       name: "idUser",
       label: "Usuario",
       service: clientService.combo,
       col: 4
     },
     {
       type:"input",
       name: "number",
       label: "Nº pedido",
       col: 4
      },
      {
        type:"input",
        name: "from",
        label: "Desde",
        inputtype:"date",
        col: 4
       },
       {
         type:"input",
         name: "to",
         label: "Hasta",
         inputtype:"date",
         col: 4
        },
  ];

  const handlePrint = (bill) => {
    if (!bill.isDraft){
      printService.bill(bill,(data,error)=>{});
    } else {
      toast.notify("Solo se puede imprimir factura ya generadas", {position:'top', duration: 10000})
    }
    
  }
  
  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }



  return (
    <CrudTable
          service={service}
          titlePage={"Facturas"}
          titleButton={"Nueva factura"}
          urledit={"/bill/edit/"}
          urlnew={"/bill/new/"}
          filter={initFilter}
          fieldstable={["numberBill","date","client.name|client.email|client.phone","base","discount","iva","total"]}
          headersTable={["#", "Fecha","Cliente","Base","Descuento","Iva" ,"Total"]}
          defaultValues={{numberBill:"Borrador"}}
          estructureForm={isAdmin()?createEstructureFilter:createEstructureFilterLaundry}
          moreActions={[{"handle":handlePrint, icon:<PrintIcon />}]}
          paintCell={paintCell}
      />
  );
}
