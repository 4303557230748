import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {categoryService as service} from "../../../services"

const initFilter =  {
    name : "",
};


export default function Category() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
  ];




  return (
    <CrudTable
          service={service}
          titlePage={"Categorias"}
          titleButton={"Nueva categoria"}
          urledit={"/category/edit/"}
          urlnew={"/category/new/"}
          filter={initFilter}
          fieldstable={["imageprin","name","shortDescription"]}
          headersTable={["#","Nombre","Descripcion"]}
          estructureForm={createEstructureFilter}
      />
  );
}
