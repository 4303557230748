import React, {useEffect, useState} from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import {workService} from "../../../services"
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import logo from '../../../assets/img/logo.png'


const useStyles2 = makeStyles({
  panelIssue:{
    textAlign: "center",
    background: "rgba(255,0,0,0.2)",
    color: "darkred",
    border: "1px solid darkred",
    borderRadius: "6px",
    padding: "0.5em",
    margin: "0 5px"
  },
  panelIssueAccept:{
    textAlign: "center",
    background: "rgba(0,255,0,0.2)",
    color: "darkgreen",
    border: "1px solid darkgreen",
    borderRadius: "6px",
    padding: "0.5em",
    margin: "0 5px"
  },
  btnsi:{
    display:"inline-block",
    margin:"10px",
    lineHeight:"60px",
    width:"100px",
    background:"rgba(0,255,0,0.2)",
    border:"1px solid darkgreen",
    color:"darkgreen",
    fontWeight:"bold",
    borderRadius: "6px"
  },
  btnno:{
    display:"inline-block",
    margin:"10px",
    lineHeight:"60px",
    width:"100px",
    background:"rgba(255,0,0,0.2)",
    border:"1px solid darkred",
    color:"darkred",
    fontWeight:"bold",
    borderRadius: "6px"
  }
});


const initElement = {
     id:null,
     client:{id:null,name:""},
     laundry:{id:null,name:"", email:"", phone:""},
     state:{id:null,name:""},
     numberOrder: null,
     date: null,
     dateDelivery: null,
     hour:"",
     hourDelivery: "",
     isSuscription:null,
     labelwash:null,
     numberkey:null,
     locationInLocal:null,
     orderclient:{
       id:null,
       numberOrder:"",
       lines:[

       ]
     },
     ticket:{
       id:null,
       numberTicketPickup:"",
       numberTicket:"",
       lines:[

       ]
     },
     location:{
        id:null,
        name:"",
        street:"",
        phone:"",
        number:"",
        door:"",
        floor:"",
        stairs:"",
        city:"",
        country:"España",
        postalcode:"",
        latitude:"",
        longitude:"",
     },
     haveIssueFromTicket: false,
     resolverIsuseByClient: false,
     resolverAdmin: false,
     notPermitedIsuseByClient: false,
     haveIssue: false,
     motiveIssue:""


  };


export default function ResolveIssue() {
  const classes2 = useStyles2();
  const classes = useStyles();
  let { token } = useParams();
  const [work,setWork] = useState({...initElement});

  useEffect(() => {
    if(token){
      workService.issuenoti(token,(data,error) => {
        if(data){
          setWork(data.data);
        }
      })
    }
  },[])


  const clickYes = () => {
    workService.issuenotiAccept(token,(data,error) => {
      if(data){
        setWork(data.data);
      }
    });

  }
  const clickNo = () => {
    workService.issuenotiReject(token,(data,error) => {
      if(data){
        setWork(data.data);
      }
    });

  }


  const infoWork = () => {
    return <Grid container style={{marginBottom:"1em", fontSize:"1.3em"}}>
        <Grid item xs={6} style={{textAlign:"right", paddingRight:"5px"}}>
             <b>{work.orderclient?"Pedido:":"Ticket:"}</b>
        </Grid>
        <Grid item xs={6} style={{textAlign:"left"}}>
              {work.orderclient?work.orderclient.numberOrder:(work.ticket && work.ticket.numberTicket?work.ticket.numberTicket:work.ticket.numberTicketPickup)}
        </Grid><Grid item xs={6} style={{textAlign:"right", paddingRight:"5px"}}>
             <b>Estado:</b>
        </Grid>
        <Grid item xs={6} style={{textAlign:"left"}}>
              {work.state.name}
        </Grid>
    </Grid>
  }

  const infoLaundry = () => {
    return <Grid container style={{marginBottom:"1em", fontSize:"1.1em"}}>
              <Grid item xs={12} sm={4} lg={5} style={{textAlign:"center"}}></Grid>
              <Grid item xs={12} sm={4} lg={2} style={{textAlign:"center"}}>
                <Grid container style={{margin:"0em", background:"#a9b9d3", padding:"1em", color:"white", border:"1px solid #3c3c44", borderRadius:"6px", wordBreak:"break-word"}}>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                         <b>Nombre:</b>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                          {work.laundry.name}
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                          <b>Email:</b>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                          <a href={"mailto:0034"+work.laundry.email} style={{color:"white", textDecoration:"none"}}>{work.laundry.email}</a>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                          <b>Tlf:</b>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                          <a href={"tel:0034"+work.laundry.phone} style={{color:"white", textDecoration:"none"}}>{work.laundry.phone}</a>
                    </Grid>
                </Grid>
              </Grid>
              <Grid item  xs={12} sm={4} lg={5} style={{textAlign:"center"}}></Grid>
    </Grid>
  }

  const resolverMessage = () => {
    return <Grid container style={{marginBottom:"1em"}}>
        <Grid item xs={12} style={{textAlign:"center"}}>
              <h2>La incidencia ha sido resuelta. Si tiene alguna duda puede ponerse en contacto con la lavandería</h2>
        </Grid>
        <Grid item xs={12} style={{textAlign:"center"}}>
              {infoLaundry()}
        </Grid>
    </Grid>
  }

  const notResolverMessage = () => {
    return <Grid container style={{marginBottom:"1em"}}>
        <Grid item xs={12} style={{textAlign:"center"}}>
              Ya has marcado que no se continue con el trabajo. Si tiene alguna duda puede ponerse en contacto con la lavandería
        </Grid>
        <Grid item xs={12} style={{textAlign:"center"}}>
              {infoLaundry()}
        </Grid>
    </Grid>
  }


    const questionMessage = () => {
      return <Grid container style={{marginBottom:"1em"}}>
          <Grid item xs={12} style={{textAlign:"center"}}>
                <h2>La lavanderia ha encontrado una incidencia, que indicamos a continuación:</h2>
          </Grid>
          <Grid item xs={12} style={{textAlign:"center", marginTop:"0.5em", marginBottom:"1em"}}>
                <p className={classes2.panelIssue}> <ReportProblemIcon style={{marginBottom: "-7px"}}/> {work.motiveIssue}</p>
          </Grid>
          <Grid item xs={12} style={{textAlign:"center"}}>
                <h2>¿Quieres que continuemos con el trabajo igualmente?</h2>
          </Grid>
          <Grid item xs={12} style={{textAlign:"center"}}>
                <button  className={classes2.btnsi} onClick={clickYes}>SI</button>
                <button  className={classes2.btnno} onClick={clickNo}>NO</button>
          </Grid>
          <Grid item xs={12} style={{textAlign:"center"}}>
                Si tienes alguna duda puedes consultarle a la lavendería
          </Grid>
          <Grid item xs={12} style={{textAlign:"center"}}>
                {infoLaundry()}
          </Grid>
      </Grid>
    }

  return (
    <div className={classes.root} style={{color:"#3c3c44"}}>
      <CssBaseline />
      <main className={classes.content}>
      <div  style={{background:"#a9b9d3"}}>
        <Container maxWidth="lg">
          <Grid container style={{marginBottom:"1em"}}>
              <Grid item xs={12} style={{textAlign:"center"}}>
                    <img src={logo} style={{width:"400px", maxWidth:"70%", height:"auto"}} />
              </Grid>
          </Grid>
      </Container>
      </div>
      <Container maxWidth="lg" className={classes.container}>
          {work.id &&
          <>
            {infoWork()}
            {(work.haveIssueFromTicket || work.resolverIsuseByClient || work.resolverAdmin) && resolverMessage()}
            {(work.notPermitedIsuseByClient ) && notResolverMessage()}
            {(!(work.haveIssueFromTicket || work.resolverIsuseByClient || work.resolverAdmin || work.notPermitedIsuseByClient ) && work.haveIssue) && questionMessage()}
          </>}

          {!work.id &&
          <>
          <Grid container style={{marginBottom:"1em"}}>
              <Grid item xs={12} style={{textAlign:"center"}}>
                    No hemos encontrado el pedido que a que intenta acceder
              </Grid>
          </Grid>
          </>}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
