import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {workService as service, clientService, stateworkService, laundryService, printService} from "../../../services"
import {isAdmin} from '../../utils/Utils'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';

const moment = require("moment");

const initFilter =  {
    numberOrder:null,
    numberWork:null,
    numberTicket:null,
    idUser : null,
    idLaundry: null,
    idState:null,
    from:moment(new Date()).format("YYYY-MM-DD") + "T00:00:00Z",
    to:moment(new Date()).add(0,'days').format("YYYY-MM-DD") + "T23:59:00Z",
    labelwash:null,
    numberkey:null,
    haveIssue:null

};


export default function Work() {
const history = useHistory();

  const paintCell = {
    haveIssue: (value,object) => {
      if (value) {
        return <ReportProblemIcon style={{color:"darkred"}}/>
      } else {
        return <></>
      }
    },
    resolve: (value,object) => {
      if (object["haveIssueFromTicket"] || object["resolverIsuseByClient"] || object["resolverAdmin"]) {
        return <DoneIcon style={{color:"darkgreen"}}/>
      } else if (object["notPermitedIsuseByClient"]) {
        return <ClearIcon style={{color:"darkred"}}/>
      } else {
        return <></>
      }
    },
    origen: (value,object) => {
      if (object["ticket"] && object["ticket"]["id"]) {
        return <>LOCAL</>
      } else {
        return <>WEB</>
      }
    },

  }

  const setValueNumticket= (setValueField,objectform,value,valueform,setValueform) => {

      var dates = {"to":"","from":""};
      setValueform(currentValueform => ({...currentValueform, ...dates}))

      var newobject =  setValueField(objectform,"numberTicket",value);
      newobject =  setValueField(objectform,"from",null);
      newobject =  setValueField(objectform,"to",null);


      return newobject;
  }

  const setValueNumOrder= (setValueField,objectform,value,valueform,setValueform) => {

      var dates = {"to":"","from":""};
      setValueform(currentValueform => ({...currentValueform, ...dates}))

      var newobject =  setValueField(objectform,"numberOrder",value);
      newobject =  setValueField(objectform,"from",null);
      newobject =  setValueField(objectform,"to",null);


      return newobject;
  }

  const createEstructureFilter =  [
    {
      type:"input",
      name: "numberWork",
      label: "Nº Pedido",
      inputtype:"number",
      step:1,
      col: 4
     },
    {
      type:"input",
      name: "numberOrder",
      label: "Nº Pedido Online",
      setvalue: setValueNumOrder,
      col: 4
     },
     {
       type:"input",
       name: "numberTicket",
       label: "Nº Ticket",
       setvalue: setValueNumticket,
       col: 4
      },
      {
        type:"input",
        name: "labelwash",
        label: "Nº Llavero",
        col: 4
       },
       {
         type:"input",
         name: "numberkey",
         label: "Nº Etiqueta Lavado",
         col: 4
        },
     {
       type:"autocomplete",
       name: "idUser",
       label: "Usuario",
       service: clientService.combo,
       col: 4
      },
      {
       type:"autocomplete",
       name: "idLaundry",
       label: "Lavandería",
       service: laundryService.combo,
       col: 4
      },
      {
       type:"autocomplete",
       name: "idState",
       label: "Estado",
       service: stateworkService.combo,
       col: 4
      },
      {
        type:"input",
        name: "from",
        label: "Desde",
        inputtype:"date",
        col: 4
       },
       {
         type:"input",
         name: "to",
         label: "Hasta",
         inputtype:"date",
         col: 4
        },
        {
          type:"checkbox",
          name: "haveIssue",
          label: "Con incidencia",
          col: 4
         },

  ];



    const createEstructureFilterLaundry =  [
      {
        type:"input",
        name: "numberWork",
        label: "Nº Pedido",
        inputtype:"number",
        step:1,
        col: 4
       },
      {
        type:"input",
        name: "numberOrder",
        label: "Nº Pedido Online",
        setvalue: setValueNumOrder,
        col: 4
       },
       {
         type:"input",
         name: "numberTicket",
         label: "Nº Ticket",
         setvalue: setValueNumticket,
         col: 4
        },
        {
          type:"input",
          name: "numberkey",
          label: "Nº Llavero",
          col: 4
         },
         {
           type:"input",
           name: "labelwash",
           label: "Nº Etiqueta Lavado",
           col: 4
          },
       {
         type:"autocomplete",
         name: "idUser",
         label: "Cliente",
         service: clientService.combo,
         col: 4
        },
        {
         type:"autocomplete",
         name: "idState",
         label: "Estado",
         service: stateworkService.comboall,
         col: 4
        },
        {
          type:"input",
          name: "from",
          label: "Desde",
          inputtype:"date",
          col: 4
         },
         {
           type:"input",
           name: "to",
           label: "Hasta",
           inputtype:"date",
           col: 4
          },
          {
            type:"checkbox",
            name: "haveIssue",
            label: "Con incidencia",
            col: 4
           },
    ];


  const gotoEdit = (element) => {
    if (isAdmin()){
      if (element && element.ticket && element.ticket.id && element.ticket.id > 0 ){
         history.push("/work/edit/"+element.id+"/"+1);
      } else if (element && element.deliveryNote && element.deliveryNote.id && element.deliveryNote.id > 0 ){
        history.push("/work/edit/"+element.id+"/"+2);
     } else {
        history.push("/work/edit/"+element.id+"/"+0);
      }
    } else {
      if (element && element.ticket && element.ticket.id && element.ticket.id > 0 ){
         history.push("/work/edit/wizard/"+element.id+"/"+1);
      } else if (element && element.deliveryNote && element.deliveryNote.id && element.deliveryNote.id > 0 ){
        history.push("/work/edit/wizard/"+element.id+"/"+2);
     } else {
        history.push("/work/edit/wizard/"+element.id+"/"+0);
      }
    }

  }


  const handlePrint = (t) => {    
   printService.work(t,(data,error)=>{});
 }



  return (
    <CrudTable
          service={service}
          titlePage={"Ordenes de trabajo"}
          titleButton={"Nueva orden de trabajo"}
          urledit={"/work/edit/"}
          urlnew={"/work/new/"}
          filter={initFilter}
          hiddenButton={true}
          deleteaction={false}
          gotoEdit={gotoEdit}
          fieldstable={["numberWork","createDate","client.name|client.email|client.phone","date|hour","dateDelivery|hourDelivery","location.streetComplete","state.name","haveIssue","resolve","origen"]}
          headersTable={["Nº","Fecha", "Cliente","Fecha de recogida","Fecha de entrega","Calle","Estado","Incidencia","In. Resuelta","Origen"]}
          estructureForm={isAdmin()?createEstructureFilter:createEstructureFilterLaundry}
          paintCell={paintCell}
          moreActions={[{"handle":handlePrint, icon:<PrintIcon />}]}
      />
  );
}
