import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CrudForm from '../../crud/CrudForm';
import { orderclientService as service, laundryService, clientService, stateorderService, productService, printService, paymenttypeService } from '../../../services';
import { isAdmin, idLaundry, nameLaundry } from '../../utils/Utils';
import { Button } from '@material-ui/core';
import ModalClient from '../../client/ModalClient';

const moment = require('moment');

const initline = {
      id: -1,
      product: { id: null, name: '' },
      amount: 0.0,
      price: 0.0,
      total: 0.0,
      base: 0.0,
      discount: 0.0,
      discountPercent: 0.0,
};

const initElement = {
      client: { id: null, name: '' },
      laundry: { id: isAdmin() ? null : idLaundry(), name: isAdmin() ? '' : nameLaundry() },
      stateOrder: { id: null, name: '' },
      numberOrder: null,
      pickUp: null,
      delivery: null,
      hourPickup: '',
      hourDelivery: '',
      total: 0.0,
      base: 0.0,
      tax: 0.0,
      discountPercent: 0.0,
      discount: 0.0,
      isSuscription: null,
      lines: [],
      pickupLocation: {
            id: null,
            name: '',
            street: '',
            phone: '',
            number: '',
            door: '',
            floor: '',
            stairs: '',
            city: '',
            country: 'España',
            postalcode: '',
            latitude: '',
            longitude: '',
      },
};

export default function FormOrder() {
      const [objectform, setObjectform] = React.useState({ ...initElement });

      const setValuePickup = (setValueField, objectform, value) => {
            if (value && value.date && value.hour) {
                  var newobject = setValueField(objectform, 'pickUp', value.date);
                  newobject = setValueField(objectform, 'hourPickup', value.hour);
                  return newobject;
            } else {
                  return objectform;
            }
      };

      const setValueDelivery = (setValueField, objectform, value) => {
            if (value && value.date && value.hour) {
                  var newobject = setValueField(objectform, 'delivery', value.date);
                  newobject = setValueField(objectform, 'hourDelivery', value.hour);
                  return newobject;
            } else {
                  return objectform;
            }
      };

      const searchDayDelivery = (setOptions, objectform) => {
            if (objectform.laundry.id != null) {
                  laundryService.daysDelivery(objectform.laundry.id, (data, error) => {
                        if (data && data.data) {
                              setOptions((currentOptions) => ({ ...currentOptions, ['delivery']: data.data }));
                        }
                  });
            }
      };

      const setValueRow = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            if (aux && aux['other']) {
                  var newobject = setValueField(objectform, 'amount', 1.0);
                  newobject = setValueField(newobject, 'price', +aux.other);
                  newobject = setValueField(newobject, 'discountPercent', 0.0);
                  newobject = setValueField(newobject, 'discount', 0.0);
                  newobject = setValueField(newobject, 'base', (aux.other / 1.21).toFixed(2));
                  newobject = setValueField(newobject, 'total', aux.other);

                  var rv = valueform[id];
                  rv['amount'] = newobject['amount'];
                  rv['price'] = newobject['price'];
                  rv['discountPercent'] = newobject['discountPercent'];
                  rv['discount'] = newobject['discount'];
                  rv['base'] = newobject['base'];
                  rv['total'] = newobject['total'];
                  setValueform((currentValueform) => ({ ...currentValueform, [id]: rv }));

                  return newobject;
            } else {
                  return objectform;
            }
      };

      const setValueAmout = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            var rv = valueform[id];

            var ammount = +value;
            var price = rv['price'];
            var discper = rv['discountPercent'];

            var dis = price * (discper / 100.0) * ammount;
            var total = price * ammount - dis;
            var base = total / 1.21;

            var newobject = setValueField(objectform, 'discount', dis);
            newobject = setValueField(newobject, 'total', total);

            rv['discount'] = newobject['discount'].toFixed(2);
            rv['total'] = newobject['total'].toFixed(2);
            setValueform((currentValueform) => ({ ...currentValueform, [id]: rv }));

            return newobject;
      };

      const setValueDiscount = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            var rv = valueform[id];

            var ammount = rv['amount'];
            var price = rv['price'];
            var discper = +value;

            var dis = price * (discper / 100.0) * ammount;
            var total = price * ammount - dis;
            var base = total / 1.21;

            var newobject = setValueField(objectform, 'discount', dis);
            newobject = setValueField(newobject, 'total', total);

            rv['discount'] = newobject['discount'].toFixed(2);
            rv['total'] = newobject['total'].toFixed(2);
            setValueform((currentValueform) => ({ ...currentValueform, [id]: rv }));

            return newobject;
      };

      const updateRows = (setValueField, objectform, rows, setValueform) => {
            var totalLines = 0.0;

            for (let row of rows) {
                  if (row['total']) {
                        totalLines = totalLines + +row['total'];
                  }
            }

            var disper = +objectform['discountPercent'];
            var dis = (totalLines * (disper / 100.0)).toFixed(2);
            var total = totalLines - +dis;
            var base = (total / 1.21).toFixed(2);
            var tax = (total - total / 1.21).toFixed(2);

            setValueform((currentValueform) => ({ ...currentValueform, ['total']: total }));
            setValueform((currentValueform) => ({ ...currentValueform, ['discount']: dis }));
            setValueform((currentValueform) => ({ ...currentValueform, ['base']: base }));
            setValueform((currentValueform) => ({ ...currentValueform, ['tax']: tax }));
            var newobject = setValueField(objectform, 'total', total);
            newobject = setValueField(newobject, 'discount', dis);
            newobject = setValueField(newobject, 'base', base);
            return newobject;
      };

      const updateDiscountTotal = (setValueField, objectform, value, valueform, setValueform) => {
            var totalLines = 0.0;

            for (let row of objectform['lines']) {
                  if (row['total']) {
                        totalLines = totalLines + +row['total'];
                  }
            }

            var disper = +objectform['discountPercent'];
            var dis = (totalLines * (disper / 100.0)).toFixed(2);
            var total = totalLines - +dis;
            var base = (total / 1.21).toFixed(2);
            var tax = (total - total / 1.21).toFixed(2);

            setValueform((currentValueform) => ({ ...currentValueform, ['total']: total }));
            setValueform((currentValueform) => ({ ...currentValueform, ['discount']: dis }));
            setValueform((currentValueform) => ({ ...currentValueform, ['base']: base }));
            setValueform((currentValueform) => ({ ...currentValueform, ['tax']: tax }));
            var newobject = setValueField(objectform, 'total', total);
            newobject = setValueField(newobject, 'discount', dis);
            newobject = setValueField(newobject, 'base', base);
            return newobject;
      };

      const setValueClient = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            if (value) {
                  var newobject = objectform;

                  clientService.one(value.key, (data, error) => {
                        var user = data.data;

                        newobject = setValueField(newobject, 'client', user);
                        if (user.paymentType && user.paymentType.id) {
                              newobject = setValueField(newobject, 'paymentType', user.paymentType);
                              setValueform((currentValueform) => ({ ...currentValueform, ['paymentType.id']: { key: user.paymentType.id, name: user.paymentType.name } }));
                        }
                        if (user.percentDiscount) {
                              newobject = setValueField(newobject, 'discountPercent', user.percentDiscount);
                              setValueform((currentValueform) => ({ ...currentValueform, ['discountPercent']: user.percentDiscount }));
                        }

                        setValueform((currentValueform) => ({ ...currentValueform, ['pickupLocation.street']: user.streetDefault.street }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['pickupLocation.postalcode']: user.streetDefault.postalcode }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['pickupLocation.country']: user.streetDefault.country }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['pickupLocation.latitude']: user.streetDefault.latitude }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['pickupLocation.longitude']: user.streetDefault.longitude }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['pickupLocation.city']: user.streetDefault.city }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['pickupLocation.number']: user.streetDefault.number }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['pickupLocation.floor']: user.streetDefault.floor }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['pickupLocation.stairs']: user.streetDefault.stairs }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['pickupLocation.door']: user.streetDefault.door }));

                        newobject = setValueField(newobject, 'pickupLocation.street', user.streetDefault.street);
                        newobject = setValueField(newobject, 'pickupLocation.postalcode', user.streetDefault.postalcode);
                        newobject = setValueField(newobject, 'pickupLocation.country', user.streetDefault.country);
                        newobject = setValueField(newobject, 'pickupLocation.latitude', user.streetDefault.latitude);
                        newobject = setValueField(newobject, 'pickupLocation.longitude', user.streetDefault.longitude);
                        newobject = setValueField(newobject, 'pickupLocation.city', user.streetDefault.city);
                        newobject = setValueField(newobject, 'pickupLocation.number', user.streetDefault.number);
                        newobject = setValueField(newobject, 'pickupLocation.floor', user.streetDefault.floor);
                        newobject = setValueField(newobject, 'pickupLocation.stairs', user.streetDefault.stairs);
                        newobject = setValueField(newobject, 'pickupLocation.door', user.streetDefault.door);
                  });

                  return newobject;
            } else {
                  return objectform;
            }
      };

      const estructureformline = [
            {
                  type: 'autocomplete',
                  name: 'product',
                  label: 'Producto',
                  service: productService.comboWithCategory,
                  colSpan: '30%',
                  setvalue: setValueRow,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'amount',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Cantidad',
                  setvalue: setValueAmout,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'price',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Precio',
                  readonly: true,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'discountPercent',
                  inputtype: 'number',
                  step: 0.01,
                  label: '% Decuento',
                  setvalue: setValueDiscount,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'discount',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Decuento',
                  readonly: true,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'total',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Total',
                  readonly: true,
                  col: 3,
            },
      ];

      const estructureform = [
            {
                  type: 'title',
                  name: 'titledata',
                  label: 'Datos pedido',
                  col: 12,
            },
            {
                  type: 'autocomplete',
                  name: 'client.id',
                  label: 'Cliente',
                  service: clientService.combo,
                  setvalue: setValueClient,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'laundry.id',
                  label: 'Lavandería',
                  service: laundryService.combo,
                  readonly: !isAdmin(),
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'numberOrder',
                  label: 'Nº Pedido',
                  readonly: true,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pickUp',
                  inputtype: 'date',
                  label: 'Fecha Recogida',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'hourPickup',
                  label: 'Hora Recogida',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'delivery',
                  inputtype: 'date',
                  label: 'Fecha. Devolucion',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'hourDelivery',
                  label: 'Hora Devolución',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'paymentType.id',
                  label: 'Forma de pago',
                  service: paymenttypeService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'stateOrder.id',
                  label: 'Estado',
                  service: stateorderService.combo,
                  col: 4,
            },
            {
                  type: 'title',
                  name: 'titlestreet',
                  label: 'Dirección',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'pickupLocation.street',
                  label: 'Calle',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pickupLocation.number',
                  label: 'Nº',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'pickupLocation.stairs',
                  label: 'Escl.',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'pickupLocation.floor',
                  label: 'Piso',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'pickupLocation.door',
                  label: 'Puerta',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'pickupLocation.city',
                  label: 'Ciudad',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pickupLocation.postalcode',
                  label: 'C.P',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pickupLocation.phone',
                  label: 'Tlf.',
                  col: 4,
            },
            {
                  type: 'title',
                  name: 'titlelineas',
                  label: 'Productos',
                  col: 12,
            },
            {
                  type: 'table',
                  name: 'lines',
                  label: 'Lineas',
                  headers: [
                        { label: 'Producto', colSpan: '30%' },
                        { label: 'Cantidad', colSpan: '10%' },
                        { label: 'Precio', colSpan: '10%' },
                        { label: '% Desc', colSpan: '10%' },
                        { label: 'Descuento', colSpan: '10%' },
                        { label: 'Total', colSpan: '10%' },
                  ],
                  initrow: { ...initline },
                  fields: estructureformline,
                  fieldId: 'id',
                  setvalue: updateRows,
                  col: 12,
            },
            {
                  type: 'title',
                  name: 'titletotal',
                  label: 'Total',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'discountPercent',
                  inputtype: 'number',
                  step: 0.01,
                  label: '% Decuento',
                  setvalue: updateDiscountTotal,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'discount',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Decuento',
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'base',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Base Imponible',
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'tax',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Iva',
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'total',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Total',
                  readonly: true,
                  col: 3,
            },
      ];

      const recoveryElement = function (objectedit, element) {
            objectedit['name'] = element.name ? element.name : '';
            objectedit['price'] = element.price ? element.price : 0.0;
            objectedit['priceToSuscription'] = element.priceToSuscription ? element.priceToSuscription : 0.0;
            objectedit['iva'] = element.iva ? element.iva : 21.0;
            objectedit['shortDescription'] = element.shortDescription ? element.shortDescription : '';
            objectedit['description'] = element.description ? element.description : '';
            objectedit['isSuscription'] = element.isSuscription ? element.isSuscription : false;
            objectedit['allowIncludeInSuscription'] = element.allowIncludeInSuscription ? element.allowIncludeInSuscription : false;
            objectedit['mountForMonth'] = element.mountForMonth ? element.mountForMonth : '';
            objectedit['image1'] = element.image1 ? element.image1 : null;
            objectedit['image2'] = element.image2 ? element.image2 : null;
            objectedit['image3'] = element.image3 ? element.image3 : null;
            objectedit['image4'] = element.image4 ? element.image4 : null;
            objectedit['image5'] = element.image5 ? element.image5 : null;
            objectedit['image6'] = element.image6 ? element.image6 : null;
            objectedit['image6'] = element.image6 ? element.image6 : null;
            objectedit['imageprin'] = element.imageprin ? element.imageprin : '';

            objectedit['client'] = { id: element.client && element.client.id ? element.client.id : null, name: element.client && element.client.name ? element.client.name : null };
            objectedit['laundry'] = { id: element.laundry && element.laundry.id ? element.laundry.id : null, name: element.laundry && element.laundry.name ? element.laundry.name : null };
            objectedit['stateOrder'] = { id: element.stateOrder && element.stateOrder.id ? element.stateOrder.id : null, name: element.stateOrder && element.stateOrder.name ? element.stateOrder.name : null };
            objectedit['paymentType'] = { id: element.paymentType && element.paymentType.id ? element.paymentType.id : null, name: element.paymentType && element.paymentType.name ? element.paymentType.name : null };
            objectedit['lines'] = element.lines ? element.lines : [];
            objectedit['pickupLocation'] = element.pickupLocation
                  ? element.pickupLocation
                  : {
                          id: null,
                          name: '',
                          street: '',
                          phone: '',
                          number: '',
                          door: '',
                          floor: '',
                          stairs: '',
                          city: '',
                          country: 'España',
                          postalcode: '',
                          latitude: '',
                          longitude: '',
                    };
            objectedit['pickupLocation'] = element.deliveryLocation
                  ? element.deliveryLocation
                  : {
                          id: null,
                          name: '',
                          street: '',
                          phone: '',
                          number: '',
                          door: '',
                          floor: '',
                          stairs: '',
                          city: '',
                          country: 'España',
                          postalcode: '',
                          latitude: '',
                          longitude: '',
                    };
            objectedit['numberOrder'] = element.numberOrder ? element.numberOrder : null;

            return objectedit;
      };

      const isValid = function (object) {
            if (!object['client'] || !object['client']['id']) {
                  toast.notify('Debe indicar el cliente', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['laundry'] || !object['laundry']['id']) {
                  toast.notify('Debe indicar la lavandería', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['stateOrder'] || !object['stateOrder']['id']) {
                  toast.notify('Debe indicar el estado', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['pickupLocation'] || !object['pickupLocation']['street'] || object['pickupLocation']['street'] == '') {
                  toast.notify('Debe indicar la calle', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['pickupLocation'] || !object['pickupLocation']['postalcode'] || object['pickupLocation']['postalcode'] == '') {
                  toast.notify('Debe indicar el código postal', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['lines'] || object['lines'].length == 0) {
                  toast.notify('Debe añadir algun producto', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['hourPickup'] || object['hourPickup'] == '') {
                  toast.notify('Debe indicar la hora de recogida', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['hourDelivery'] || object['hourDelivery'] == '') {
                  toast.notify('Debe indicar la hora de entrega', { position: 'top', duration: 10000 });
                  return false;
            }

            if (!object['delivery'] || object['delivery'] == '') {
                  toast.notify('Debe indicar la fecha de entrega', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['pickUp'] || object['pickUp'] == '') {
                  toast.notify('Debe indicar la fecha de recogida', { position: 'top', duration: 10000 });
                  return false;
            }
            for (let r of object['lines']) {
                  if (!r['product'] || !r['product']['id']) {
                        toast.notify('Todas las lineas deben tener un producto asignado', { position: 'top', duration: 10000 });
                        return false;
                  }
                  if (!r['amount'] || r['amount'] <= 0) {
                        toast.notify('Todas las lineas deben tener una cantidad superior a 0', { position: 'top', duration: 10000 });
                        return false;
                  }
            }
            return true;
      };
      const moreActions = (getObject, setValueField) => {
            var recoveryObject = getObject;
            return (
                  <>
                        <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={() => handlePrint(recoveryObject)}>
                              {' '}
                              Imprimir{' '}
                        </Button>
                  </>
            );
      };

      const handlePrint = (t) => {
            debugger;
            printService.order(t(), (data, error) => {});
      };

      const haveOtherTop = function (idRow) {
            debugger;
            return !idRow || idRow <= 0;
      };

      const saveClient = (object) => {
            debugger;
            var aux = [];
            aux['client'] = object;
            aux['pickupLocation'] = object.streetDefault;
            setObjectform((currentValueform) => ({ ...currentValueform, ...aux }));
      };

      return (
            <CrudForm
                  objectform={objectform}
                  estructureform={estructureform}
                  titleEdit={'Editar pedido'}
                  titleNew={'Nuevo pedido'}
                  urledit={'/order/edit/'}
                  urlCancel={'/order'}
                  service={service}
                  recoveryElement={recoveryElement}
                  valid={isValid}
                  moreActions={moreActions}
                  haveotherTop={haveOtherTop}
                  othersformTop={<ModalClient saveClient={saveClient} />}
            />
      );
}
