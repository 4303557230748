import {workService as service, laundryService, clientService, stateworkService, productService,uploadService} from "../../../services"
import {isAdmin,idLaundry,nameLaundry,getUrlImage} from '../../utils/Utils'
const moment = require("moment");

const initline = {
  id: -1,
  product:{id:null,name:""},
  amount:0.0,
  price:0.0,
  total:0.0,
  base:0.0,
  discount:0.0,
  discountPercent:0.0
}

const estructureformline =  [
       {
        type:"input",
        name: "product.name",
        label: "Producto",
        colSpan:"30%",
        readonly: true,
        col: 4
       },
       {
         type:"input",
         name: "amount",
         inputtype:"number",
         step:0.01,
         label: "Cantidad",
         readonly: true,
         col: 3
        }
  ];


const estructureformlineTicket =  [
  {
   type:"input",
   name: "name",
   label: "Producto",
   colSpan:"30%",
   readonly: true,
   col: 4
  },
  {
    type:"input",
    name: "amount",
    inputtype:"number",
    step:0.01,
    label: "Cantidad",
    readonly: true,
    col: 3
   }
];

const estructureformlineDelivery =  [
  {
   type:"input",
   name: "name",
   label: "Producto",
   colSpan:"30%",
   readonly: true,
   col: 4
  },
  {
    type:"input",
    name: "amount",
    inputtype:"number",
    step:0.01,
    label: "Cantidad",
    readonly: true,
    col: 3
   }
];

/** FUNCIONES FECHAS **/
const initValuePick = (obj) => {
  if(obj.date && obj.hour) {
    var value = (moment(Date.parse(obj.date))).format('DD/MM/YYYY') + " | " +  obj.hour;
    return  {key:value,name: value}
  } else {
    return {key:"",name: ""};
  }
};

const initValueDel = (obj) => {
  if(obj.dateDelivery && obj.hourDelivery) {
    var value = (moment(Date.parse(obj.dateDelivery))).format('DD/MM/YYYY') + " | " +  obj.hourDelivery;
    return  {key:value,name: value}
  } else {
    return {key:"",name: ""};
  }
};

const setValuePickup = (setValueField,objectform,value) => {
  debugger;
    var newobject =  setValueField(objectform,"date",value.date);
    newobject =  setValueField(objectform,"hour",value.hour);
    return newobject;
}

const setValueDelivery = (setValueField,objectform,value) => {
  debugger;
    var newobject =  setValueField(objectform,"dateDelivery",value.date);
    newobject =  setValueField(objectform,"hourDelivery",value.hour);
    return newobject;
}

const searchDayDelivery = (setOptions,objectform) => {
    if (objectform.laundry.id != null){
        laundryService.daysDelivery(objectform.laundry.id ,(data, error) => {
          if(data && data.data){
            setOptions(currentOptions => ({...currentOptions, ["dateDelivery"]: data.data }));
          }
        });
    }
}


/** ESTRUTURAS FORM **/

 export const estructureformProducts =  [
        {
          type:"table",
          name: "lines",
          label: "Lineas",
          headers:[{label:"Producto",colSpan:"60%"   },{label:"Cantidad",colSpan:"40%"   }],
          initrow:{... initline},
          fields:estructureformline,
          fieldId:"id",
          onlyread:true,
          col: 12
         }
   ];

   export const estructureformProductsTickets =  [
          {
            type:"table",
            name: "ticket.lines",
            label: "Lineas",
            headers:[{label:"Producto",colSpan:"60%"   },{label:"Cantidad",colSpan:"40%"   }],
            initrow:{... initline},
            fields:estructureformlineTicket,
            fieldId:"id",
            onlyread:true,
            col: 12
           }
     ];

  export const estructureformProductsDelivery =  [
      {
        type:"table",
        name: "deliveryNote.lines",
        label: "Lineas",
        headers:[{label:"Producto",colSpan:"60%"   },{label:"Cantidad",colSpan:"40%"   }],
        initrow:{... initline},
        fields:estructureformlineDelivery,
        fieldId:"id",
        onlyread:true,
        col: 12
       }
 ];


export const estructureform =  [
             {
              type:"autocomplete",
              name: "client.id",
              label: "Cliente",
              service:clientService.combo,
              readonly:true,
              col: 3
             },
            {
              type:"input",
              name: "orderclient.numberOrder",
              label: "Nº Pedido",
              readonly:true,
              col: 3
             },
            {
              type:"autocomplete",
              name: "date",
              label: "Fecha. Recogida",
              relationfield:'dateDelivery',
              servicerelation:laundryService.daysDelivery,
              relationSearch:searchDayDelivery,
              options:[],
              setvalue: setValuePickup,
              initValue: initValuePick,
              readonly:true,
              col: 3
             },
            {
              type:"autocomplete",
              name: "dateDelivery",
              label: "Fecha. Devolucion",
              options:[],
              setvalue: setValueDelivery,
              initValue: initValueDel,
              readonly:true,
              col: 3
             },
             {
              type:"input",
              name: "labelwash",
              label: "Etiqueta de lavado",
              readonly:true,
              col: 3
             },
             {
              type:"input",
              name: "numberkey",
              label: "Llavero recogida",
              readonly:true,
              col: 3
             },
             {
              type:"input",
              name: "locationInLocal",
              label: "Ubicación en tienda",
              readonly:true,
              col: 6
             },
         ];


export  const estructureformTicket =  [
                 {
                  type:"autocomplete",
                  name: "client.id",
                  label: "Cliente",
                  service:clientService.combo,
                  readonly:true,
                  col: 3
                 },
                {
                  type:"input",
                  name: "ticket.numberTicket",
                  label: "Nº Ticket",
                  readonly:true,
                  col: 3
                 },
                 {
                  type:"input",
                  name: "labelwash",
                  label: "Etiqueta de lavado",
                  col: 3
                 },
                 {
                  type:"input",
                  name: "locationInLocal",
                  label: "Ubicación en tienda",
                  col: 3
                 }
           ];


export  const estructureformDelivery =  [
  {
   type:"autocomplete",
   name: "client.id",
   label: "Cliente",
   service:clientService.combo,
   readonly:true,
   col: 3
  },
 {
   type:"input",
   name: "deliveryNote.numberDelivery",
   label: "Nº Albaran",
   readonly:true,
   col: 3
  },
  {
   type:"input",
   name: "labelwash",
   label: "Etiqueta de lavado",
   col: 3
  },
  {
   type:"input",
   name: "locationInLocal",
   label: "Ubicación en tienda",
   col: 3
  }
];


export const estructureformDir =  [
                     {
                       type:"location",
                       name: "location.location",
                       label: "Calle",
                       street:"location.street",
                       postalcode:"location.postalcode",
                       city: "location.city",
                       country:"location.country",
                       latitude:"location.latitude",
                       longitude:"location.longitude",
                       number:"location.number",
                       col: 4,
                       readonly:true
                      },
                     {
                       type:"input",
                       name: "location.number",
                       label: "Nº",
                       col: 2,
                       readonly:true
                      },
                     {
                       type:"input",
                       name: "location.stairs",
                       label: "Escl.",
                       col: 2,
                       readonly:true
                      },
                     {
                       type:"input",
                       name: "location.floor",
                       label: "Piso",
                       col: 2,
                       readonly:true
                      },
                     {
                       type:"input",
                       name: "location.door",
                       label: "Puerta",
                       col: 2,
                       readonly:true
                      },
                     {
                       type:"input",
                       name: "location.city",
                       label: "Ciudad",
                       readonly:true,
                       col: 4,
                      },
                     {
                       type:"input",
                       name: "location.postalcode",
                       label: "C.P",
                       readonly:true,
                       col: 4,
                      },
                     {
                       type:"input",
                       name: "location.phone",
                       label: "Tlf.",
                       col: 4,
                       readonly:true
                      },
                ];




/** Init element **/

export const initElement = {
     client:{id:null,name:""},
     laundry:{id:isAdmin()?null:idLaundry(),name:isAdmin()?"":nameLaundry()},
     state:{id:null,name:""},
     numberOrder: null,
     date: null,
     dateDelivery: null,
     hour:"",
     hourDelivery: "",
     isSuscription:null,
     labelwash:null,
     numberkey:null,
     locationInLocal:null,
     orderclient:{
       id:null,
       lines:[

       ]
     },
     ticket:{
       id:null,
       lines:[

       ]
     },
     location:{
        id:null,
        name:"",
        street:"",
        phone:"",
        number:"",
        door:"",
        floor:"",
        stairs:"",
        city:"",
        country:"España",
        postalcode:"",
        latitude:"",
        longitude:"",
     },


  };



  export const estructureStatePR =  [
               {
                type:"input",
                name: "numberkey",
                label: "Llavero recogida",
                col: 12
               },
 ];


 export const estructureStateL =  [
              {
               type:"input",
               name: "labelwash",
               label: "Etiqueta de lavado",
               col: 12
              },
        ];

export const estructureStateLT =  [
      {
        type:"input",
        name: "copy",
        label: "Copias",
        inputtype:"number",
        step:1,
        col: 12
      },
];


  export const estructureStateP =  [
         {
          type:"input",
          name: "locationInLocal",
          label: "Ubicación en tienda",
          col: 12
         },
  ];

  export const estructureIssue =  [
               {
                type:"input",
                name: "motiveIssue",
                label: "Motivo de incidencia",
                col: 12
               },
 ];

 export const estructureIssueResolve =  [
              {
               type:"input",
               name: "resolveIssue",
               label: "Solucion",
               col: 12
              },
];
