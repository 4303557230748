import BaseService from './_base.service';

const URL = "/client/printconfig";

export const configprintpublicService = {
    lastversion,
};

function lastversion(callback) {
    return BaseService.get(URL+"/lastversion/", callback)
}
