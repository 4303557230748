import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import {orderclientService, clientService, workService, suscriptionService} from "../../../services"



export default function Dashboard() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [open, setOpen] = React.useState(true);
  const sizecg = "80%"
  const [orderClient,setOrderClient] =  React.useState({
    total:0,
    day:8,
    totalDay:0,
    dayg:0,
    week:0,
    totalWeek:0,
    weekg:0,
    month:0,
    totalMonth:0,
    monthg:0
  });
  const [client,setClient] =  React.useState({
    total:0,
    day:0,
    totalDay:0,
    dayg:0,
    week:0,
    totalWeek:0,
    weekg:0,
    month:0,
    totalMonth:0,
    monthg:0
  });
  const [workToday,setWorkToday] =  React.useState({
    total:0,
    pendienterecoger:0,
    recogido:0,
    enprocesodelavado:0,
    enprocesodesecado:0,
    listoparareparto:0,
    enreparto:0,
    conincidenica:0,
    entregado:0
  });
  const [workTodayCar,setWorkTodayCar] =  React.useState({
    pendienterecoger:0,
    listoparareparto:0,
  });
  const [workTotal,setWorkTotal] =  React.useState({
    total:0,
    pendienterecoger:0,
    recogido:0,
    enprocesodelavado:0,
    enprocesodesecado:0,
    listoparareparto:0,
    enreparto:0,
    conincidenica:0,
    entregado:0
  });
  

  const [suscripciones,setSuscripciones] =  React.useState([
      {
        title:"",
        number:0,
        percent:0
      },
  ]
  );



  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const linearGraph = (title,value) => {
    return <>
        <Grid item sm={4} xs={4}>
              <Typography  style={{textAlign:"left"}}> {title}</Typography>

        </Grid>

        <Grid item sm={8} xs={8}>
            <LinearProgress
                variant="determinate"
                value={value}
                classes={{
                  root: classes.root,
                }}
              />

        </Grid>
    </>
  }

  const circularGraph = (title1,title2,value1,value2,valueg,color) => {
    return  <Grid item sm={4} xs={4}>
            <Typography  style={{textAlign:"left"}}> {title1} <b>{value1}</b></Typography>
            <Typography  style={{textAlign:"left"}}> {title2} <b>{value2}</b></Typography>

            <CircularProgress
                  size={sizecg}
                  value={valueg}
                  thickness={10}
                  variant="static"
                  color={color}
                  style={{marginTop:"2em"}}/>
      </Grid>
  }


  const searchOrder = function() {
    orderclientService.datastat((data,error) => {
      if(data && data.data){
        setOrderClient(data.data);
      }
    });
  }
  const searchClient = function() {
    clientService.datastat((data,error) => {
      if(data && data.data){
        setClient(data.data);
      }
    });
  }
  const searchWorkToday = function() {
    workService.datastattoday((data,error) => {
      if(data && data.data){
        setWorkToday(data.data);
      }
    });
  }
  const searchWork = function() {
    workService.datastat((data,error) => {
      if(data && data.data){
        setWorkTotal(data.data);
      }
    });
  }
  const searchSus= function() {
    suscriptionService.datastat((data,error) => {
      if(data && data.data){
        setSuscripciones(data.data);
      }
    });
  }


  const searchWorkTodayCar = function() {
    workService.datastattodaycar((data,error) => {
      if(data && data.data){
        setWorkTodayCar(data.data);
      }
    });
  }

  


  useEffect(() => {
    searchOrder();
    searchClient();
    searchWorkToday();
    searchWork();
    searchSus();
    searchWorkTodayCar();
  }, []);



  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title="Dashboard" classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} style={{paddingTop:"2em"}}>

                 <Grid item sm={6} xs={12}>
                    <Grid container>
                        <Grid item sm={12} xs={12} style={{borderBottom: "1px solid", marginBottom:"20px"}}>
                              <Typography component="h2" variant="h5"> Pedidos </Typography>
                        </Grid>

                        {circularGraph("Objetivo Diario:"," Pedidos Recibidos hoy:",orderClient.totalDay,orderClient.day,orderClient.dayg,"secondary")}
                        {circularGraph("Objetivo Semanal:"," Pedidos esta semana:",orderClient.totalWeek,orderClient.week,orderClient.weekg,"primary")}
                        {circularGraph("Objetivo Mensual:"," Pedidos este mes:",orderClient.totalMonth,orderClient.month,orderClient.monthg,"secondary")}

                    </Grid>


                </Grid>

                <Grid item sm={6} xs={12}>
                      <Grid container>
                          <Grid item sm={12} xs={12} style={{borderBottom: "1px solid", marginBottom:"20px"}}>
                                <Typography component="h2" variant="h5"> Ordenes de trabajo. HOY </Typography>
                          </Grid>

                          <Grid item sm={12} xs={12}  style={{marginBottom:"2em"}}>
                                <Typography  style={{textAlign:"left"}}> Recogidas: <b>{workTodayCar.pendienterecoger}</b></Typography>
                                <Typography  style={{textAlign:"left"}}> Devoluciones: <b>{workTodayCar.listoparareparto}</b></Typography>

                          </Grid>

                          {linearGraph("Pendiente de recoger(" + workToday.pendienterecoger +")",workToday.total == 0? 0 : (workToday.pendienterecoger * 100 / workToday.total))}
                          {linearGraph("Recogido(" + workToday.recogido +")",workToday.total == 0? 0 : (workToday.recogido * 100 / workToday.total))}
                          {linearGraph("Listo para reparto(" + workTotal.listoparareparto +")", workToday.total == 0? 0 : (workTotal.listoparareparto * 100 / workToday.total))}
                          {linearGraph("En repato(" + workToday.enreparto +")",workToday.total == 0? 0 : (workTotal.enreparto * 100 / workToday.total))}
                          {linearGraph("Entregado(" + workToday.entregado +")",workToday.total == 0? 0 : (workToday.entregado * 100 / workToday.total))}

                      </Grid>

              </Grid>


              <Grid item sm={6} xs={12}>
                    <Grid container>
                        <Grid item sm={12} xs={12} style={{borderBottom: "1px solid", marginBottom:"20px"}}>
                              <Typography component="h2" variant="h5"> Ordenes de trabajo. Totales </Typography>
                        </Grid>

                        {linearGraph("Pendiente de recoger (" + workTotal.pendienterecoger +")",workTotal.total == 0? 0 : (workTotal.pendienterecoger * 100 / workTotal.total))}
                        {linearGraph("Recogido (" + workTotal.recogido +")", workTotal.total == 0? 0 : (workTotal.recogido * 100 / workTotal.total) )}
                        {linearGraph("En proceso de lavado (" + workTotal.enprocesodelavado +")",workTotal.total == 0? 0 : (workTotal.enprocesodelavado * 100 / workTotal.total) )}
                        {linearGraph("Listo para reparto (" + workTotal.listoparareparto +")", workTotal.total == 0? 0 : (workTotal.listoparareparto * 100 / workTotal.total))}
                        {linearGraph("En reparto (" + workTotal.enreparto +")", workTotal.total == 0? 0 : (workTotal.enreparto * 100 / workTotal.total))}
                        {linearGraph("Con incidencia (" + workTotal.conincidenica +")",workTotal.total == 0? 0 :  (workTotal.conincidenica * 100 / workTotal.total))}

                    </Grid>

            </Grid>

            <Grid item sm={6} xs={12}>
               <Grid container>
                   <Grid item sm={12} xs={12} style={{borderBottom: "1px solid", marginBottom:"20px"}}>
                         <Typography component="h2" variant="h5"> Clientes </Typography>
                   </Grid>

                   {circularGraph("Objetivo Diario:"," Clientes nuevos hoy:",client.totalDay,orderClient.day,client.dayg,"secondary")}
                   {circularGraph("Objetivo Semanal:"," Clientes nuevos esta semana:",client.totalWeek,client.week,client.weekg,"primary")}
                   {circularGraph("Objetivo Mensual:"," Clientes nuevos este mes:",client.totalMonth,client.month,client.monthg,"secondary")}

               </Grid>


           </Grid>

           <Grid item sm={12} xs={12}>
                 <Grid container>
                     <Grid item sm={12} xs={12} style={{borderBottom: "1px solid", marginBottom:"20px"}}>
                           <Typography component="h2" variant="h5"> Suscripciones activas </Typography>
                     </Grid>

                     {(suscripciones).map((sus) => (
                       linearGraph(sus.title + " ("+ sus.number+")", sus.percent)
                     ))}


                 </Grid>

         </Grid>


          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
