import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import Button from '@material-ui/core/Button';
import {operatorService as service, laundryService, clientService, productService, streetService,printService} from "../../../services"
import {isAdmin,idLaundry,nameLaundry} from '../../utils/Utils'

const moment = require("moment");



const initElement = {
     client:{id:null,name:""},
     numberBill: null,
     login:"",
     name: "",
     password:"",
     laundry:{id:isAdmin()?null:idLaundry(),name:isAdmin()?"":nameLaundry()},
  };

export default function FormOperator() {
  const [objectform, setObjectform] = React.useState({...initElement});
  const [numberBill, setNumberBill] = React.useState("");
  const history = useHistory();
  let { id } = useParams();



  const estructureform =  [
         {
           type:"input",
           name: "login",
           label: "Login",
           col: 4
          },
         {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
           type:"input",
           name: "pass",
           label: "Contraseña",
           col: 4,
           inputtype: "password"
          },
          {
            type:"autocomplete",
            name: "laundry.id",
            label: "Lavandería",
            readonly: !isAdmin()?true:false,
            col: 4,
            service:laundryService.combo
           },
    ];


  const recoveryElement = function(objectedit,element){


    objectedit['name']=element.name?element.name:"";
    objectedit['login']=element.login?element.login:0.0;
    objectedit['laundry']={id:element.laundry && element.laundry.id?element.laundry.id:null, name: element.laundry && element.laundry.name?element.laundry.name:null};
  
    return objectedit;
  }


  const isValid = function(object){
      if(!object['login']){
        toast.notify("Debe indicar el login", {position:'top', duration: 10000})
        return false;
      }
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }

  const checkIsReadOnly = function(object){
    return !object['isDraft'];
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        titleEdit = {"Editar dependiente"}
        titleNew = {"Nuevo dependiente"}
        urledit={"/operator/edit/"}
        urlCancel={"/operator"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
