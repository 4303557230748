import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {deliverynoteService as service, clientService, laundryService, printService, billService} from "../../../services"
import {isAdmin} from '../../utils/Utils'
import PrintIcon from '@material-ui/icons/Print';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import { Grid,Button } from '@material-ui/core';
import RequestQuoteIcon from '@material-ui/icons/InsertDriveFile';
import { isDate } from 'moment';

const initFilter =  {
    number:null,
    idUser : null,
    idLaundry: null,
    idState:null,
    web:true
};


export default function Deliverynote() {
  
  const history = useHistory();
  var deliverynotesSelected = [];


  const createEstructureFilter =  [
     {
       type:"autocomplete",
       name: "idUser",
       label: "Usuario",
       service: clientService.combo,
       col: 4
      },
      {
       type:"autocomplete",
       name: "idLaundry",
       label: "Lavandería",
       service: laundryService.combo,
       col: 4
      }
  ];

  const createEstructureFilterLaundry =  [
     {
       type:"autocomplete",
       name: "idUser",
       label: "Usuario",
       service: clientService.combo,
       col: 4
     },
      {
        type:"input",
        name: "from",
        label: "Desde",
        inputtype:"date",
        col: 4
       },
       {
         type:"input",
         name: "to",
         label: "Hasta",
         inputtype:"date",
         col: 4
        },
  ];

  const handlePrint = (deliverynote) => {
    if (!deliverynote.isDraft){
      printService.deliverynote(deliverynote,(data,error)=>{});
    } else {
      toast.notify("Solo se puede imprimir un albaran ya generada", {position:'top', duration: 10000})
    }
    
  }
  
  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }

  const handlerSelected = (ids) => {
    deliverynotesSelected = ids;
  }

  const clickCreateBill = (evt) => {
    debugger;
    if(!deliverynotesSelected || deliverynotesSelected.length == 0){
      toast.notify("Debe seleccionar de que albaranes quiere crear la factura", {position:'top', duration: 10000})
    } else {
        billService.deliverynotes(deliverynotesSelected,(data,error)=>{
          if (data && data.data && data.data.id){
            history.push("/bill/edit/"+data.data.id);
            location.reload();
          }
        });
    }
  }

  const othersPanel = () => {
    return  <Grid item xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
              <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={clickCreateBill}> Crear factura </Button>
          </Grid>
  }

  const handleBill = (deliverynote) => {
      if(deliverynote.bill && deliverynote.bill.id){
        history.push("/bill/edit/"+deliverynote.bill.id);
        location.reload();
      } else {
        billService.deliverynotes([deliverynote.id],(data,error)=>{
          if (data && data.data && data.data.id){
            history.push("/bill/edit/"+data.data.id);
            location.reload();
          }
        });
      }
  }

  useEffect(() => {
    deliverynotesSelected = []

}, []);

  return (
    <CrudTable
          service={service}
          titlePage={"Albaranes"}
          titleButton={"Nuevo albarán"}
          urledit={"/deliverynote/edit/"}
          urlnew={"/deliverynote/new/"}
          filter={initFilter}
          fieldstable={["numberDelivery","date","client.name|client.email|client.phone","base","discount","iva","total"]}
          headersTable={["#", "Fecha","Cliente","Base","Descuento","Iva" ,"Total"]}
          defaultValues={{numberDelivery:"Borrador"}}
          estructureForm={isAdmin()?createEstructureFilter:createEstructureFilterLaundry}
          moreActions={[{"handle":handleBill, icon:<RequestQuoteIcon />},{"handle":handlePrint, icon:<PrintIcon />}]}
          paintCell={paintCell}
          selectable={true}
          handlerSelected={handlerSelected}
          haveother={true}
          othersPanel={othersPanel}
      />
  );
}
