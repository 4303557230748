import BaseService from './_base.service';

const URL = "/admin/alertAvailableLaundry";

export const alertAvailableLaundryService = {
    search,
    combo,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}

function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}


function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}


function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}


function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}


function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}


function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}
