import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {suscriptionService as service, productService, stateorderService, laundryService} from "../../../services"
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';

const initFilter =  {
    active: null,
    idproduct:null,
};


export default function Suscription() {

  const createEstructureFilter =  [
     {
       type:"autocomplete",
       name: "idproduct",
       label: "Producto",
       service: productService.combo,
       col: 4
      },
      {
       type:"checkbox",
       name: "active",
       label: "Activa",
       col: 4
      }
  ];



   const getDeleteButton = function(row,handlerDel)  {
     if(row['active']){
       return <Button variant="contained" color="default" style={{backgroundColor: "red", color:"white"}} onClick={() => handlerDel(row["id"])}><ClearIcon /> </Button>
     } else {
       return <Button variant="contained" color="default" style={{backgroundColor: "green", color:"white"}} onClick={() => handlerDel(row["id"])}>  <CheckIcon /> </Button>
     }
   }

  return (
    <CrudTable
          service={service}
          titlePage={"Suscripciones"}
          titleButton={"Nueva suscripcion"}
          urledit={"/suscription/edit/"}
          urlnew={"/suscription/new/"}
          filter={initFilter}
          hiddenButton={true}
          editable={false}
          fieldstable={["id","client.name|client.email|client.phone","product.name","extra.name","price","numWorkForMonth","active"]}
          headersTable={["#","Cliente","Producto","Extras", "Precio","Nº de entregas mes","Activo"]}
          getDeleteButton={getDeleteButton}
          estructureForm={createEstructureFilter}
      />
  );
}
