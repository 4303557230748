import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {holidaysService as service,laundryService} from "../../../services"
import {isAdmin,idLaundry,nameLaundry} from '../../utils/Utils'

const initElement = {
     laundry:{id:isAdmin()?null:idLaundry(),name:isAdmin()?"":nameLaundry()},
     endDate:"",
     startDate:""
  };

export default function FormHoliday() {
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
          {
           type:"autocomplete",
           name: "laundry.id",
           label: "Lavandería",
           service: laundryService.combo,
           col: 4
          },
          {
           type:"input",
           name: "startDate",
           inputtype: "date",
           label: "Inicio",
           col: 4
          },
          {
           type:"input",
           name: "endDate",
           inputtype: "date",
           label: "Fin",
           col: 4
          },
    ];

    const estructureformLaundry =  [
            {
             type:"input",
             name: "startDate",
             inputtype: "date",
             label: "Inicio",
             col: 4
            },
            {
             type:"input",
             name: "endDate",
             inputtype: "date",
             label: "Fin",
             col: 4
            },
      ];

  const recoveryElement = function(objectedit,element){


    objectedit['laundry']={id:element.laundry && element.laundry.id?element.laundry.id:null, name: element.laundry && element.laundry.name?element.laundry.name:null};
    objectedit['startDate']=element.startDate?element.startDate:"";
    objectedit['endDate']=element.endDate?element.endDate:"";

    debugger;
    return objectedit;
  }


  const isValid = function(object){
      if(!object['laundry'] || !object['laundry']['id']){
        toast.notify("Debe indicar la lavanderia", {position:'top', duration: 10000})
        return false;
      }
      if(!object['endDate'] || object['endDate'] == ""){
        toast.notify("Debe indicar el día de fin", {position:'top', duration: 10000})
        return false;
      }
      if(!object['startDate'] || object['startDate'] == ""){
        toast.notify("Debe indicar el día de inicio", {position:'top', duration: 10000})
        return false;
      }
      if(object['startDate'] >= object['endDate']){
        toast.notify("La fecha de inicio debe ser anterior a la de fin", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {isAdmin()?estructureform:estructureformLaundry}
        titleEdit = {"Editar periodo de vacaciones"}
        titleNew = {"Nuevo periodo de vacaciones"}
        urledit={"/holiday/edit/"}
        urlCancel={"/holiday"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
