import BaseService from './_base.service';

const URL = "/searchlocation/";

export const locationService = {
    search
};

function search(query,callback) {
    return BaseService.get(URL+"?q="+encodeURI(query), callback)
}
