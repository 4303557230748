import BaseService from './_base.service';

const URL = "/admin/bill";

export const billService = {
    search,
    combo,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    send,
    print,
    sendAsesor,
    rectificative,
    ticket,
    deliverynotes,
    severalTicket
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}

function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}


function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}


function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}


function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}


function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}


function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}

function send(id,callback) {
    return BaseService.get(URL + "/send/"+id, callback);
}

function print(id,name,callback) {
    return BaseService.getFile(URL + "/print/"+id,name, callback);
}

function sendAsesor(id,callback) {
    return BaseService.get(URL + "/sendasesor/"+id, callback);
}


function rectificative(id,callback) {
    return BaseService.get(URL + "/rectificative/"+id, callback);
}


function ticket(id,callback) {
    return BaseService.get(URL + "/ticket/"+id, callback);
}

function deliverynotes(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL + "/deliverynotes/several", body, callback, true)
}


function severalTicket(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL + "/ticket/several", body, callback, true)
}



