import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {productService as service, laundryService, categoryService, categorylaundryService} from "../../../services"
import {isAdmin} from '../../utils/Utils'

const initElement = {
     name:"",
     price: 0.0,
     priceSuscriber:0.0,
     priceToSuscription: 0.0,
     iva: 21.0,
     shortDescription:"",
     description:"",
     isSuscription: false,
     availableInLocal:true,
     availableInWeb:true,
     allowIncludeInSuscription:false,
     mountForMonth:null,
     category:{id:null,name:""},
     image1:null,
     image2:null,
     image3:null,
     image4:null,
     image5:null,
     image6:null,
     imageprin:null,


  };

  const initElementLaundry = {
    name:"",
    price: 0.0,
    priceSuscriber:0.0,
    priceToSuscription: 0.0,
    iva: 21.0,
    shortDescription:"",
    description:"",
    isSuscription: false,
    availableInLocal:true,
    availableInWeb:false,
    allowIncludeInSuscription:false,
    mountForMonth:null,
    category:{id:null,name:""},
    image1:null,
    image2:null,
    image3:null,
    image4:null,
    image5:null,
    image6:null,
    imageprin:null,


 };

export default function FormProduct() {
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
          {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
         {
           type:"input",
           name: "price",
           inputtype:"number",
           step:"any",
           label: "Precio",
           col: 4
          },
         {
           type:"input",
           name: "priceToSuscription",
           inputtype:"number",
           step:0.01,
           label: "Precio Suscripción",
           col: 4
          },
         {
           type:"input",
           name: "priceSuscriber",
           inputtype:"number",
           step:0.01,
           label: "Precio Abonado",
           col: 4
          },
         {
           type:"input",
           name: "iva",
           inputtype:"number",
           step:0.01,
           label: "Iva",
           col: 4
          },
          {
            type:"checkbox",
            name: "isSuscription",
            label: "Es suscripción",
            col: 2
           },
           {
             type:"checkbox",
             name: "allowIncludeInSuscription",
             label: "Permitido en suscripcióm",
             col: 2
            },
            {
              type:"checkbox",
              name: "availableInWeb",
              label: "Disponible en APP",
              col: 2
             },
             {
               type:"checkbox",
               name: "availableInLocal",
               label: "Disponible en local",
               col: 2
              },
           {
             type:"input",
             name: "mountForMonth",
             inputtype:"number",
             label: "Numero de pedidos/mes",
             col: 4
            },
            {
              type:"autocomplete",
              name: "category.id",
              label: "Categoria",
              service: categoryService.combo,
              col: 4
             },

          {
           type:"textarea",
           name: "shortDescription",
           label: "Descripción Breve",
           col: 12
          },
          {
           type:"textarea",
           name: "description",
           label: "Descripción",
           col: 12
          },
          {
           type:"image",
           name: "imageprin",
           label: "Imagen principal",
           col: 3
          },
          {
           type:"image",
           name: "image1",
           label: "Imagen 1",
           col: 3
          },
          {
           type:"image",
           name: "image2",
           label: "Imagen 2",
           col: 3
          },
          {
           type:"image",
           name: "image3",
           label: "Imagen 3",
           col: 3
          },
          {
           type:"image",
           name: "image4",
           label: "Imagen 4",
           col: 3
          },
          {
           type:"image",
           name: "image5",
           label: "Imagen 5",
           col: 3
          },
          {
           type:"image",
           name: "image6",
           label: "Imagen 6",
           col: 3
          },
    ];


  const estructureformLaundry =  [
    {
     type:"input",
     name: "name",
     label: "Nombre",
     col: 4
    },
   {
     type:"input",
     name: "price",
     inputtype:"number",
     step:0.01,
     label: "Precio",
     col: 4
    },
   {
     type:"input",
     name: "iva",
     inputtype:"number",
     step:0.01,
     label: "Iva",
     col: 4
    },
      {
        type:"autocomplete",
        name: "category.id",
        label: "Categoria",
        service: categorylaundryService.combo,
        col: 4
       },
    {
     type:"image",
     name: "imageprin",
     label: "Imagen principal",
     col: 3
    },
  ];

  const recoveryElement = function(objectedit,element){


    objectedit['name']=element.name?element.name:"";
    objectedit['price']=element.price?element.price:0.0;
    objectedit['priceToSuscription']=element.priceToSuscription?element.priceToSuscription:0.0;
    objectedit['priceSuscriber']=element.priceSuscriber?element.priceSuscriber:0.0;
    objectedit['iva']=element.iva?element.iva:21.0;
    objectedit['shortDescription']=element.shortDescription?element.shortDescription:"";
    objectedit['description']=element.description?element.description:"";
    objectedit['isSuscription']=element.isSuscription?element.isSuscription:false;
    objectedit['allowIncludeInSuscription']=element.allowIncludeInSuscription?element.allowIncludeInSuscription:false;
    objectedit['availableInWeb']=element.availableInWeb?element.availableInWeb:false;
    objectedit['availableInLocal']=element.availableInLocal?element.availableInLocal:false;
    objectedit['mountForMonth']=element.mountForMonth?element.mountForMonth:"";
    objectedit['image1']=element.image1?element.image1:null;
    objectedit['image2']=element.image2?element.image2:null;
    objectedit['image3']=element.image3?element.image3:null;
    objectedit['image4']=element.image4?element.image4:null;
    objectedit['image5']=element.image5?element.image5:null;
    objectedit['image6']=element.image6?element.image6:null;
    objectedit['image6']=element.image6?element.image6:null;
    objectedit['imageprin']=element.imageprin?element.imageprin:"";
    objectedit['category']={id:element.category && element.category.id?element.category.id:null, name: element.category && element.category.name?element.category.name:null};;

    return objectedit;
  }



  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['price'] || object['price'] <= 0){
        toast.notify("Debe indicar el precio", {position:'top', duration: 10000})
        return false;
      }
      if(!object['category'] || !object['category']['id']){
        toast.notify("Debe indicar la categoria", {position:'top', duration: 10000})
        return false;
      }
      if(isAdmin() && !object['imageprin'] ){
        toast.notify("Debe indicar la imagen principal", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }


  return (
    <CrudForm
        objectform = {isAdmin()?{...initElement}:{...initElementLaundry}}
        estructureform = {isAdmin()?estructureform:estructureformLaundry}
        titleEdit = {"Editar producto"}
        titleNew = {"Nuevo producto"}
        urledit={"/product/edit/"}
        urlCancel={isAdmin()?"/product":"/productlaundry"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
