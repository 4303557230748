import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import jwtDecode from 'jwt-decode';

const LoginRoute = ({ component: Component, ...rest }) => {

  interface TokenInfo {
    exp: number;
  }

  function isNotLoginLogin(){
    var expisold = false;
    if(localStorage.getItem("token") != null && localStorage.getItem("token") != "" && localStorage.getItem("token") != undefined && localStorage.getItem("token") != "undefined"){
      const token:string = localStorage.getItem("token")+'';
      const tokende =  jwtDecode<TokenInfo>(token);
      if (Date.now() >= tokende.exp * 1000) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  const isLoggedNotIn = isNotLoginLogin();

  return (
    <Route
      {...rest}
      render={props =>
        !isLoggedNotIn ? (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

export default LoginRoute
