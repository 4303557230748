import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {laundryService as service} from "../../../services"

const initFilter =  {
    name : "",
    email : "",
    city : "",
    postalCodeAssign : "",
};


export default function Category() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
     {
       type:"input",
       name: "email",
       label: "Email",
       col: 4
      },
     {
       type:"input",
       name: "city",
       label: "Ciudad",
       col: 4
      },
     {
       type:"input",
       name: "postalCodeAssign",
       label: "Código postal asignado",
       col: 4
      },
  ];





  return (
    <CrudTable
          service={service}
          titlePage={"Lavanderías"}
          titleButton={"Nueva lavandería"}
          urledit={"/laundry/edit/"}
          urlnew={"/laundry/new/"}
          filter={initFilter}
          fieldstable={["picture","name","email","phone","active","postalCodes.postalcode"]}
          headersTable={["#","Nombre","Email","Tlf.","Activo","C.P. Asignados"]}
          estructureForm={createEstructureFilter}
      />
  );
}
