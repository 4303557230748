import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {paymenttypeService as service} from "../../../services"

const initElement = {
     name:"",
     web:false,
     local:false
  };

export default function FormPaymentType() {
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
          {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          }
    ];


  const recoveryElement = function(objectedit,element){


    objectedit['name']=element.name?element.name:"";

    return objectedit;
  }


  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        titleEdit = {"Editar tipo de pago"}
        titleNew = {"Nuevo tipo de pago"}
        urledit={"/paymenttype/edit/"}
        urlCancel={"/paymenttype"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
