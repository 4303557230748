import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../layout/Header';
import Menu from '../layout/Menu';
import Copyright from '../layout/Copyright';
import { useStyles,drawerWidth } from "../layout/styles";
import CustomPaginationActionsTable from "../table/Table"
import Filter from "../filter/Filter"

export default function CrudFormTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(props.filter?props.filter:{});
  const fieldstable = props.fieldstable?props.fieldstable:[];
  const headersTable =  props.headersTable?props.headersTable:[];
  const servicesearch =  props.servicesearch?props.servicesearch:null;
  const serviceremove=  props.serviceremove?props.serviceremove:null;
  const servicecreate=  props.servicecreate?props.servicecreate:null;
  const serviceedit=  props.serviceedit?props.serviceedit:null;
  const estructureForm =  props.estructureForm?props.estructureForm:null;
  const fieldId =  props.fieldId?props.fieldId:"id";
  const labeltitle = props.labeltitle?props.labeltitle:"Guardar";
  const hiddenfilter = props.hiddenfilter?props.hiddenfilter:false;

  const [open, setOpen] = useState(true);
  const [rows,setRows] = useState([{}]);
  const [rowsTotal,setRowsTotal] = useState(0);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const cleanFilter = (filter) => {
    console.log(filter);
    setFilter(filter);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    if (filter[fieldId] && filter[fieldId] > 0 && serviceedit){
      serviceedit(props.idrel,filter, (data, error) => {
          if(data){
            search();
          }
      });
    } else {
      servicecreate(props.idrel,filter, (data, error) => {
          if(data){
            search();
          }
      });
    }

  }


  useEffect(() => {
    search();
  }, []);

  const search = () => {
    servicesearch(props.idrel, (data, error) => {
        if(data){
          console.log(data);
          setRows(data.data);
          setRowsTotal(data.total);
        } else {
          setRows([{}]);
          setRowsTotal(0);
        }
    });
  }


  const deleteHandler = (id) => {
    var data = {};
    rows.forEach(element => {
        if(element[fieldId] == id){
          data = element;
        }
    });
    if(data && data['id']) {
      
      serviceremove(props.idrel,data, (data, error) => {
          if(data){
            search();
          }
      });
    }
  }

  const eventSelect = (id) => {
    

    if (props.editable) {
      rows.forEach(element => {
          if (element[fieldId] == id){
            setFilter(element);
          }
      });
    }

  }


  return (
    <>
        { !hiddenfilter && <Grid container style={{marginBottom: '50px'}}>
          <Filter cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter} estructure={estructureForm}  hiddenButton={true} labelsearch={"Guardar"}  cleanInOK={true} labeltitle={labeltitle}/>
        </Grid> }
        <Grid container spacing={3} >
          <CustomPaginationActionsTable rows={rows} fields={fieldstable} fieldId={fieldId} headers={headersTable} page={page} deleteaction={props.deleteaction != null?props.deleteaction:true} deleteactionHandler={deleteHandler} selectHandler={eventSelect} hiddenpagination={true}/>
        </Grid>
    </>
  );
}
