import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {clientService as service} from "../../../services"

const initFilter =  {
    name : null,
    email: null,
    phone:null,
    isClient:true
};

export default function Client() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
     {
       type:"input",
       name: "email",
       label: "Email",
       col: 4
      },
       {
         type:"input",
         name: "phone",
         label: "Tlf.",
         col: 4
        },
  ];




  return (
    <CrudTable
          service={service}
          titlePage={"Clientes"}
          titleButton={"Nuevo cliente"}
          urledit={"/client/edit/"}
          urlnew={"/client/new/"}
          filter={initFilter}
          fieldstable={["picture","email","name","lastname","phone"]}
          headersTable={["#","Email","Nombre", "Apellido","Tlf."]}
          estructureForm={createEstructureFilter}
      />
  );
}
