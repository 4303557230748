import BasePrintService from './_baseprint.service';

const URL = "print/";

export const printService = {
    ticket,
    ticketFirst,
    work,
    bill,
    cash,
    budget,
    order,
    deliverynote,
    echo,
    version,
    configure,
    saveConfigure,
    printers,
    worklabel,
    upload
};

function echo( callback) {
    return BasePrintService.get(URL+"echo", callback, true)
}
function version( callback) {
    return BasePrintService.get(URL+"version", callback, true)
}
function configure(id, callback) {
    return BasePrintService.get(URL+"configure/"+id, callback, true)
}
function saveConfigure(id,bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BasePrintService.post(URL+"configure/"+id+"/save",body, callback, true)
}
function printers( callback) {
    return BasePrintService.get(URL+"printers", callback, true)
}
function ticket(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BasePrintService.post(URL, body, callback, true)
}
function ticketFirst(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BasePrintService.post(URL+"1", body, callback, true)
}
function work(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BasePrintService.post(URL+"work", body, callback, true)
}
function order(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BasePrintService.post(URL+"order", body, callback, true)
}
function bill(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BasePrintService.post(URL+"bill", body, callback, true)
}
function budget(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BasePrintService.post(URL+"budget", body, callback, true)
}
function cash(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BasePrintService.post(URL+"cash", body, callback, true)
}
function deliverynote(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BasePrintService.post(URL+"deliverynote", body, callback, true)
}
function worklabel(copies,bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BasePrintService.post(URL+"work/label/"+copies, body, callback, true)
}

function upload(image, callback) {
    return BasePrintService.postMultipart("upload/", image, callback, true)
}
