import BaseService from './_base.service';

const URL = "/admin/work";

export const workService = {
    search,
    combo,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    datastattoday,
    datastattodaycar,
    datastat,
    issuenoti,
    issuenotiAccept,
    issuenotiReject
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}

function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}


function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}


function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}


function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}


function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}


function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}


function datastattoday(callback) {
    return BaseService.get(URL + "/datastattoday", callback);
}

function datastattodaycar(callback) {
    return BaseService.get(URL + "/datastattodaycar", callback);
}


function datastat(callback) {
    return BaseService.get(URL + "/datastat", callback);
}


function issuenoti(token,callback) {
    return BaseService.get(URL + "/issuenoti/"+token, callback);
}

function issuenotiAccept(token,callback) {
    return BaseService.get(URL + "/issuenoti/"+token+"/accept/", callback);
}

function issuenotiReject(token,callback) {
    return BaseService.get(URL + "/issuenoti/"+token+"/reject/", callback);
}
