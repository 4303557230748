import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {orderclientService as service, clientService, stateorderService, laundryService, printService} from "../../../services"
import {isAdmin} from '../../utils/Utils'
import PrintIcon from '@material-ui/icons/Print';

const initFilter =  {
    number:null,
    idUser : null,
    idLaundry: null,
    idState:null,
    web:true
};


export default function Order() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "number",
      label: "Nº",
      col: 4
     },
     {
       type:"autocomplete",
       name: "idUser",
       label: "Usuario",
       service: clientService.combo,
       col: 4
      },
      {
       type:"autocomplete",
       name: "idLaundry",
       label: "Lavandería",
       service: laundryService.combo,
       col: 4
      },
      {
       type:"autocomplete",
       name: "idState",
       label: "Estado",
       service: stateorderService.combo,
       col: 4
      },
      {
        type:"input",
        name: "from",
        label: "Desde",
        inputtype:"date",
        col: 4
       },
       {
         type:"input",
         name: "to",
         label: "Hasta",
         inputtype:"date",
         col: 4
        },
        {
          type:"input",
          name: "fromP",
          label: "Reogida Desde",
          inputtype:"date",
          col: 4
         },
         {
           type:"input",
           name: "toP",
           label: "Reogida Hasta",
           inputtype:"date",
           col: 4
          },
          {
            type:"input",
            name: "fromD",
            label: "Devolucón Desde",
            inputtype:"date",
            col: 4
           },
           {
             type:"input",
             name: "toD",
             label: "Devolucón Hasta",
             inputtype:"date",
             col: 4
            },
  ];

  const createEstructureFilterLaundry =  [
    {
      type:"input",
      name: "number",
      label: "Nº",
      col: 4
     },
     {
       type:"autocomplete",
       name: "idUser",
       label: "Usuario",
       service: clientService.combo,
       col: 4
      },
      {
       type:"autocomplete",
       name: "idState",
       label: "Estado",
       service: stateorderService.combo,
       col: 4
      },
      {
        type:"input",
        name: "from",
        label: "Desde",
        inputtype:"date",
        col: 4
       },
       {
         type:"input",
         name: "to",
         label: "Hasta",
         inputtype:"date",
         col: 4
        },
        {
          type:"input",
          name: "fromP",
          label: "Reogida Desde",
          inputtype:"date",
          col: 4
         },
         {
           type:"input",
           name: "toP",
           label: "Reogida Hasta",
           inputtype:"date",
           col: 4
          },
          {
            type:"input",
            name: "fromD",
            label: "Devolucón Desde",
            inputtype:"date",
            col: 4
           },
           {
             type:"input",
             name: "toD",
             label: "Devolucón Hasta",
             inputtype:"date",
             col: 4
            },
  ];

  const handlePrint = (order) => {
      printService.order(order,(data,error)=>{});
  }
  

  return (
    <CrudTable
          service={service}
          titlePage={"Pedidos"}
          titleButton={"Nuevo pedido"}
          urledit={"/order/edit/"}
          urlnew={"/order/new/"}
          filter={initFilter}
          fieldstable={["numberOrder","client.name|client.email|client.phone","pickUp|hourPickup","delivery|hourDelivery","pickupLocation.streetComplete","stateOrder.name"]}
          headersTable={["#", "Cliente","Fecha de recogida","Fecha de entrega" ,"Calle","Estado"]}
          estructureForm={isAdmin()?createEstructureFilter:createEstructureFilterLaundry}
          moreActions={[{"handle":handlePrint, icon:<PrintIcon />}]}
      />
  );
}
