import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useStyles,drawerWidth } from "../../layout/styles";
import {isAdmin,idLaundry,nameLaundry} from '../../utils/Utils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Layout from '../../layout/Layout';
import Form from '../../form/Form';
import {printService as service,configprintpublicService, printUpdateService} from "../../../services"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';

const moment = require("moment");



function getWeekNumber(currentdate) {
  return moment(currentdate).isoWeek();
}



export default function PrintConfig() {
  const classes = useStyles();
  const [config1,setConfig1] = useState({id:null, printer:null,widthPrint:null,classpath:null,logo:null, font:null,enablePrint:null,barcode:null,nameclient:null});
  const [config2,setConfig2] = useState({id:null, printer:null,widthPrint:null,classpath:null,logo:null, font:null,enablePrint:null,barcode:null,nameclient:null});
  const [config3,setConfig3] = useState({id:null, printer:null,widthPrint:null,classpath:null,logo:null, font:null,enablePrint:null,barcode:null,nameclient:null});
  const [version,setVersion] = useState(-1);
  const [versionLast,setVersionLast] = useState(-1);
  const [updateAllow,setUpdateAllow] = useState(false);
  const [disableupdate,setDisableupdate] = useState(false);

  


  const estructureform =  [
    {
      type:"checkbox",
      name: "enablePrint",
      label: "Habilitada",
      col: 3
     },
     {
      type:"autocomplete",
      name: "printer",
      label: "Impresora",
      service:service.printers,
      col: 3
     },
    {
      type:"input",
      name: "widthPrint",
      label: "Ancho impresion",
      inputype:"number",
      step:0.01,
      col: 3
     },
     {
       type:"input",
       name: "font",
       label: "Tamaño Fuente",
       inputype:"number",
       step:0.01,
       col: 3
      },
      {
        type:"checkbox",
        name: "classpath",
        label: "Logo interno",
        col: 3
       },
       {
         type:"image",
         name: "logo",
         label: "Logo",
         service: service.upload,
         urlbase:"http://localhost:8765/image/",
         col: 3
        },
    ]
 

  const estructureform2 =  [
    {
      type:"checkbox",
      name: "enablePrint",
      label: "Habilitada",
      col: 3
     },
     {
      type:"autocomplete",
      name: "printer",
      label: "Impresora",
      service:service.printers,
      col: 3
     },
    {
      type:"input",
      name: "widthPrint",
      label: "Ancho impresion",
      inputype:"number",
      step:0.01,
      col: 3
     },
     {
       type:"input",
       name: "font",
       label: "Tamaño Fuente",
       inputype:"number",
       step:0.01,
       col: 3
      },
        {
          type:"checkbox",
          name: "barcode",
          label: "Con codigo de barras",
          col: 3
         },
         {
           type:"checkbox",
           name: "nameclient",
           label: "Imprimir nombre de cliente",
           col: 3
          },

         
    ]

  useEffect(() => {
     service.configure(1,(data,error) => {
        if(data.data){
          setConfig1(data.data);
        }
     });
     service.configure(2,(data,error) => {
        if(data.data){
          setConfig2(data.data);
        }
     });
     service.configure(3,(data,error) => {
        if(data.data){
          setConfig3(data.data);
        }
     });

     service.version((data,error)=> {
        setVersion(data.data);
     })

     configprintpublicService.lastversion((data,error)=> {
      setVersionLast(data.data);
   })

     printUpdateService.echo((data,error) => {
        setUpdateAllow(data && data.data);
     });

  }, []);

  const saveConfig1 = (config) => {
    save(config,1);
  }

  const saveConfig2 = (config) => {
    save(config,2);
  }

  const saveConfig3 = (config) => {
    save(config,3);
  }

  const save = (config,id) => {
    service.saveConfigure(id,config, (data,error) => {
        if(data.data){
            toast.notify("Guardado correctamente", {position:'top', duration: 10000})
        } else {
          toast.notify("Error al gaurdar", {position:'top', duration: 10000})
        }
    });
  }

  const clickUpdate = (evt) => {
     setDisableupdate(true);
     printUpdateService.update((data, error) => {
      if(data && data.data) {
          setTimeout(function(){
            setDisableupdate(false);
            window.location.reload();
            
        }, 20000);
      } else {
        setDisableupdate(false);
        toast.notify("Error al actualizar", {position:'top', duration: 10000})
      }      
     })
  }

  return (
    <Layout titleWindow={"Configurar impresora"}>
        <Grid container>
          <Grid item xs={12}>
            <h2>Configuracion impresoras tickets</h2>
          </Grid>

          <Grid item xs={6} sm={2}>
            <p><span>Version instalada </span><b>{version}</b></p>
            <p><span>Ultima version </span><b>{versionLast}</b></p>
          </Grid>
          <Grid item xs={6} sm={8} style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
             { versionLast > version && updateAllow && <Button variant='contained' color='primary' onClick={clickUpdate} disabled={disableupdate}>Actualizar a la ultima versión</Button>}
             { versionLast > version && updateAllow && disableupdate && <span style={{paddingLeft: "2em"}}>Actualziando ... (esto puedo tardar varios minutos)</span>}
          </Grid>
          <Grid item xs={12}>
            <h4>Impresora de tickets</h4>
          </Grid>
          <Grid item xs={12}>
            <Form objectform={config1} fields={estructureform}  searchFilter={saveConfig1} labelsave={"Guardar"} isHiddenCancel={true} /> 
          </Grid>
          <Grid item xs={12}>
            <h4>Impresora de etiquteas de lavado</h4>
          </Grid>
          <Grid item xs={12}>
            <Form objectform={config2} fields={estructureform2}  searchFilter={saveConfig2} labelsave={"Guardar"} isHiddenCancel={true} /> 
          </Grid>

        </Grid>

    </Layout>

  );
}
