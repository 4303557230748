import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';
import { isAdmin, idLaundry, web } from '../utils/Utils';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import { faSoap, faTshirt, faSitemap, faCalendar, faBoxOpen, faMoneyBillAlt, faClipboardList, faRedoAlt, faFileInvoice, faCashRegister, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import StorageIcon from '@material-ui/icons/Storage';
import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GroupIcon from '@material-ui/icons/Group';
import BarChartIcon from '@material-ui/icons/BarChart';
import CoPresentIcon from '@material-ui/icons/Face';
import { printService } from '../../services';
import PrintIcon from '@material-ui/icons/Print';

//import { dashList,lensList, secondaryListItems } from './listMenu';

export default function Header(props) {
      const [menuPrint, setMenuPrint] = React.useState(false);
      const [openAttrLens, setOpenAttrLens] = React.useState(false);

      const closeAll = (id) => {
            if (id != 1) {
                  setOpenProduct(false);
            } else {
                  setOpenProduct(!openProduct);
            }
            if (id != 2) {
                  setOpenMaestros(false);
            } else {
                  setOpenMaestros(!openMaestros);
            }
            if (id != 3) {
                  setOpenCaja(false);
            } else {
                  setOpenCaja(!openCaja);
            }
      };

      const [openProduct, setOpenProduct] = React.useState(false);
      const [openMaestros, setOpenMaestros] = React.useState(false);
      const [openCaja, setOpenCaja] = React.useState(false);

      React.useEffect(() => {
            printService.echo((data, error) => {
                  if (data && data.data) {
                        setMenuPrint(true);
                  } else {
                        setMenuPrint(false);
                  }
            });
      }, []);

      return (
            <Drawer
                  variant="permanent"
                  classes={{
                        paper: clsx(props.classes.drawerPaper, !props.open && props.classes.drawerPaperClose),
                  }}
                  open={props.open}
            >
                  <div className={props.classes.toolbarIcon}>
                        <IconButton onClick={props.handleDrawerClose}>
                              <ChevronLeftIcon />
                        </IconButton>
                  </div>
                  <Divider />
                  <List>
                        <div>
                              <Link to="/dashboard" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button>
                                          <ListItemIcon>
                                                <DashboardIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Panel de control" />
                                    </ListItem>
                              </Link>
                        </div>
                  </List>
                  <Divider />
                  {isAdmin() && (
                        <List>
                              <Link to="/laundry/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button className={props.classes.nested}>
                                          <ListItemIcon>
                                                <LocalLaundryServiceIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Lavanderías" />
                                    </ListItem>
                              </Link>
                        </List>
                  )}
                  {!isAdmin() && (
                        <List>
                              <Link to={'/laundry/edit/' + idLaundry()} style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button className={props.classes.nested}>
                                          <ListItemIcon>
                                                <LocalLaundryServiceIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Mis datos" />
                                    </ListItem>
                              </Link>
                        </List>
                  )}
                  <Divider />
                  {isAdmin() && (
                        <List>
                              <Link to="/alertavailable/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button className={props.classes.nested}>
                                          <ListItemIcon>
                                                <HomeIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="C.P. Solicitados" />
                                    </ListItem>
                              </Link>
                        </List>
                  )}
                  {isAdmin() && <Divider />}
                  {isAdmin() && (
                        <List>
                              <Link to="/configuration/edit/1" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                    <ListItem button className={props.classes.nested}>
                                          <ListItemIcon>
                                                <BuildIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Configuracion" />
                                    </ListItem>
                              </Link>
                        </List>
                  )}
                  {isAdmin() && <Divider />}
                  {isAdmin() && (
                        <List>
                              <div>
                                    <ListItem
                                          button
                                          onClick={() => {
                                                closeAll(2);
                                          }}
                                    >
                                          <ListItemIcon>
                                                <StorageIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Maestros" />
                                          {openMaestros ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={openMaestros} timeout="auto" unmountOnExit>
                                          <List>
                                                <Link to="/stateorder/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                                      <ListItem button className={props.classes.nested}>
                                                            <ListItemIcon>
                                                                  <ShoppingCartIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Estados pedidos" />
                                                      </ListItem>
                                                </Link>
                                          </List>
                                          <List>
                                                <Link to="/statework" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                                      <ListItem button className={props.classes.nested}>
                                                            <ListItemIcon>
                                                                  <FontAwesomeIcon icon={faSoap} size="lg" />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Estados Trabajos" />
                                                      </ListItem>
                                                </Link>
                                          </List>
                                          <List>
                                                <Link to="/paymenttype" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                                      <ListItem button className={props.classes.nested}>
                                                            <ListItemIcon>
                                                                  <FontAwesomeIcon icon={faSoap} size="lg" />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Tipos de pago" />
                                                      </ListItem>
                                                </Link>
                                          </List>
                                    </Collapse>
                              </div>
                        </List>
                  )}
                  {isAdmin() && <Divider />}
                  {isAdmin() && (
                        <List>
                              <div>
                                    <ListItem
                                          button
                                          onClick={() => {
                                                closeAll(1);
                                          }}
                                    >
                                          <ListItemIcon>
                                                <FontAwesomeIcon icon={faTshirt} size="lg" />
                                          </ListItemIcon>
                                          <ListItemText primary="Productos" />
                                          {openProduct ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={openProduct} timeout="auto" unmountOnExit>
                                          <List>
                                                <Link to="/product/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                                      <ListItem button className={props.classes.nested}>
                                                            <ListItemIcon>
                                                                  <FontAwesomeIcon icon={faTshirt} size="lg" />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Productos" />
                                                      </ListItem>
                                                </Link>
                                          </List>
                                          <List>
                                                <Link to="/category" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                                      <ListItem button className={props.classes.nested}>
                                                            <ListItemIcon>
                                                                  <FontAwesomeIcon icon={faSitemap} size="lg" />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Categorias" />
                                                      </ListItem>
                                                </Link>
                                          </List>
                                    </Collapse>
                              </div>
                        </List>
                  )}
                  {!isAdmin() && (
                        <>
                              {web() && (
                                    <>
                                          {' '}
                                          <List>
                                                <Link to="/product/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                                      <ListItem button className={props.classes.nested}>
                                                            <ListItemIcon>
                                                                  <FontAwesomeIcon icon={faTshirt} size="lg" />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Productos" />
                                                      </ListItem>
                                                </Link>
                                          </List>
                                          <Divider />
                                    </>
                              )}
                              <List>
                                    <Link to="/productlaundry/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                          <ListItem button className={props.classes.nested}>
                                                <ListItemIcon>
                                                      <FontAwesomeIcon icon={faBoxOpen} size="lg" />
                                                </ListItemIcon>
                                                <ListItemText primary="Productos TPV" />
                                          </ListItem>
                                    </Link>
                              </List>
                              <Divider />
                              <List>
                                    <Link to="/categorylaundry" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                          <ListItem button className={props.classes.nested}>
                                                <ListItemIcon>
                                                      <FontAwesomeIcon icon={faSitemap} size="lg" />
                                                </ListItemIcon>
                                                <ListItemText primary="Categorias Productos" />
                                          </ListItem>
                                    </Link>
                              </List>

                              <Divider />
                              <List>
                                    <Link to="/tpv/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                          <ListItem button className={props.classes.nested}>
                                                <ListItemIcon>
                                                      <FontAwesomeIcon icon={faMoneyBillAlt} size="lg" />
                                                </ListItemIcon>
                                                <ListItemText primary="TPV" />
                                          </ListItem>
                                    </Link>
                              </List>
                              <Divider />
                              <List>
                                    <div>
                                          <ListItem
                                                button
                                                onClick={() => {
                                                      closeAll(3);
                                                }}
                                          >
                                                <ListItemIcon>
                                                      <FontAwesomeIcon icon={faCashRegister} size="lg" />
                                                </ListItemIcon>
                                                <ListItemText primary="Caja" />
                                                {openCaja ? <ExpandLess /> : <ExpandMore />}
                                          </ListItem>
                                          <Collapse in={openCaja} timeout="auto" unmountOnExit style={{ background: '#fafafa' }}>
                                                <List>
                                                      <Link to="/tickets/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                                            <ListItem button className={props.classes.nested}>
                                                                  <ListItemIcon>
                                                                        <FontAwesomeIcon icon={faClipboardList} size="lg" />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="Tickets" />
                                                            </ListItem>
                                                      </Link>
                                                </List>
                                                <List>
                                                      <Link to="/cash/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                                            <ListItem button className={props.classes.nested}>
                                                                  <ListItemIcon>
                                                                        <FontAwesomeIcon icon={faCashRegister} size="lg" />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="Cierre caja" />
                                                            </ListItem>
                                                      </Link>
                                                </List>
                                                <List>
                                                      <Link to="/cashout/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                                            <ListItem button className={props.classes.nested}>
                                                                  <ListItemIcon>
                                                                        <FontAwesomeIcon icon={faEuroSign} size="lg" />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="Retirar efectivo" />
                                                            </ListItem>
                                                      </Link>
                                                </List>
                                          </Collapse>
                                    </div>
                              </List>
                              <Divider />

                              <List>
                                    <Link to="/bill/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                          <ListItem button className={props.classes.nested}>
                                                <ListItemIcon>
                                                      <FontAwesomeIcon icon={faFileInvoice} size="lg" />
                                                </ListItemIcon>
                                                <ListItemText primary="Facturas" />
                                          </ListItem>
                                    </Link>
                              </List>
                              <Divider />

                              <List>
                                    <Link to="/deliverynote/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                          <ListItem button className={props.classes.nested}>
                                                <ListItemIcon>
                                                      <FontAwesomeIcon icon={faFileInvoice} size="lg" />
                                                </ListItemIcon>
                                                <ListItemText primary="Albaranes" />
                                          </ListItem>
                                    </Link>
                              </List>
                              <Divider />

                              <List>
                                    <Link to="/budget/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                          <ListItem button className={props.classes.nested}>
                                                <ListItemIcon>
                                                      <FontAwesomeIcon icon={faFileInvoice} size="lg" />
                                                </ListItemIcon>
                                                <ListItemText primary="Presupuesto" />
                                          </ListItem>
                                    </Link>
                              </List>
                              <Divider />
                        </>
                  )}

                  <Divider />
                  <List>
                        <Link to="/holiday/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <FontAwesomeIcon icon={faCalendar} size="lg" />
                                    </ListItemIcon>
                                    <ListItemText primary="Dias no laborables" />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  <List>
                        <Link to="/order/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <ShoppingCartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pedidos Onl./Tlf./Emp." />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  <List>
                        <Link to="/work/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <FontAwesomeIcon icon={faSoap} size="lg" />
                                    </ListItemIcon>
                                    <ListItemText primary="Ordenes de trabajo" />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  <List>
                        <Link to="/suscription/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <FontAwesomeIcon icon={faRedoAlt} size="lg" />
                                    </ListItemIcon>
                                    <ListItemText primary="Suscripciones" />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  <List>
                        <Link to="/administrator/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <GroupIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={isAdmin() ? 'Administradores' : 'Usuarios'} />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  <List>
                        <Link to="/client/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <AccountBoxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Clientes" />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  <List>
                        <Link to="/operator/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                              <ListItem button className={props.classes.nested}>
                                    <ListItemIcon>
                                          <CoPresentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Dependiente" />
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  {!isAdmin() && (
                        <>
                              <List>
                                    <Link to="/statistics/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                          <ListItem button className={props.classes.nested}>
                                                <ListItemIcon>
                                                      <BarChartIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Estadísticas" />
                                          </ListItem>
                                    </Link>
                              </List>
                              <Divider />
                        </>
                  )}
                  {menuPrint && (
                        <>
                              <List>
                                    <Link to="/printconfig/" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                          <ListItem button className={props.classes.nested}>
                                                <ListItemIcon>
                                                      <PrintIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Conf. impresoras" />
                                          </ListItem>
                                    </Link>
                              </List>
                              <Divider />
                        </>
                  )}
                  {isAdmin() && (
                        <>
                              <List>
                                    <Link to="/printservice/edit/1" style={{ color: 'inherit', textDecoration: 'none' }} replace>
                                          <ListItem button className={props.classes.nested}>
                                                <ListItemIcon>
                                                      <PrintIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Servicio Impresion" />
                                          </ListItem>
                                    </Link>
                              </List>
                              <Divider />
                        </>
                  )}
            </Drawer>
      );
}
