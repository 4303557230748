import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {stateorderService as service} from "../../../services"

const initFilter =  {
    name : "",
};


export default function StateOrder() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
  ];




  return (
    <CrudTable
          service={service}
          titlePage={"Estados de pedidos"}
          titleButton={"Nuevo estadol"}
          urledit={"/stateorder/edit/"}
          urlnew={"/stateorder/new/"}
          filter={initFilter}
          fieldstable={["id","name"]}
          headersTable={["#","Nombre"]}
          estructureForm={createEstructureFilter}
      />
  );
}
