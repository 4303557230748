import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {userService as service, laundryService, categoryService} from "../../../services"
import {isAdmin,idLaundry,nameLaundry,setNumDaysAndTicketDefault, web} from '../../utils/Utils'

const initElement = {
     email:"",
     pass: "",
     name: "",
     lastname: "",
     isClient:false,
     rol: isAdmin()?{code:null,name:""}:{code:"LAUNDRY",name:"LAUNDRY"},
     enable: true,
     laundries:isAdmin()?[]:[{id:idLaundry()}],
     picture:null
  };


export default function FormAdministrator() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams();
  const [idRow, setIdrow] = useState(id);


  const estructureform =  isAdmin() ? [
          {
           type:"input",
           name: "email",
           label: "Email",
           col: 4
          },
          {
           type:"input",
           name: "pass",
           label: "Contraseña",
           col: 4,
           inputtype: "password"
          },
          {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 4
          },
          {
           type:"input",
           name: "lastname",
           label: "Apellido",
           col: 4
          },
          {
           type:"autocomplete",
           name: "rol.code",
           label: "Rol",
           col: 4,
           options:[{key:"ADMIN",name:"ADMIN"},{key:"LAUNDRY",name:"LAUNDRY"}]
          },
          {
           type:"autocomplete",
           name: "laundries",
           label: "Lavandería",
           multiple:true,
           col: 4,
           service:laundryService.combo
          },
          {
           type:"image",
           name: "picture",
           label: "Imagen",
           col: 3
          }
    ] : 
    [
      {
       type:"input",
       name: "email",
       label: "Email",
       col: 4
      },
      {
       type:"input",
       name: "pass",
       label: "Contraseña",
       col: 4,
       inputtype: "password"
      },
      {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
      {
       type:"input",
       name: "lastname",
       label: "Apellido",
       col: 4
      },
      {
       type:"image",
       name: "picture",
       label: "Imagen",
       col: 3
      }
];

  const recoveryElement = function(objectedit,element){


    objectedit['email']=element.email?element.email:"";
    objectedit['name']=element.name?element.name:"";
    objectedit['lastname']=element.lastname?element.lastname:"";
    objectedit['rol']={code:element.rol && element.rol.code ?element.rol.code:null, name: element.rol && element.rol.name?element.rol.name:null};
    objectedit['laundries']=element.laundries ? element.laundries:[];
    objectedit['picture']=element.picture?element.picture:"";

    return objectedit;
  }




  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      if(!object['email']){
        toast.notify("Debe indicar el email", {position:'top', duration: 10000})
        return false;
      }
      if(!object['rol'] && !object['rol']['code']){
        toast.notify("Debe indicar el rol", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        titleEdit = {"Editar Usuario"}
        titleNew = {"Nuevo Usuario"}
        urledit={"/administrator/edit/"}
        urlCancel={"/administrator"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
