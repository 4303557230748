import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {alertAvailableLaundryService as service, clientService} from "../../../services"

const initElement = {
     postalcode:"",
     client: {id:null,name:""}
  };

export default function FormAlertavailable() {
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
          {
           type:"input",
           name: "postalcode",
           label: "Código postal",
           col: 6
          },
          {
            type:"autocomplete",
            name: "client.id",
            label: "Cliente",
            service: clientService.combo,
            col: 6
           },
    ];


  const recoveryElement = function(objectedit,element){


    objectedit['postalcode']=element.postalcode?element.postalcode:"";
    objectedit['client']={id:element.client && element.client.id?element.client.id:null, name: element.client && element.client.name?element.client.name:null};

    return objectedit;
  }


  const isValid = function(object){
      if(!object['postalcode']){
        toast.notify("Debe indicar el código postal", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }


  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {"Editar alerta código postal"}
        titleNew = {"Nueva alerta código postal"}
        urledit={"/alertavailable/edit/"}
        urlCancel={"/alertavailable"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
