import BasePrintService from './_baseprintupdate.service';

const URL = "update/";

export const printUpdateService = {
    echo,
    update
};

function echo( callback) {
    return BasePrintService.get(URL+"echo", callback, true)
}
function update( callback) {
    return BasePrintService.get(URL, callback, true)
}