import React from 'react';
import { useParams,useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import logo from '../../assets/img/logoletras.png'
import { isAdmin, operatorName, operator, haveMore,nameLaundry , idLaundry} from '../utils/Utils';
import CoPresentIcon from '@material-ui/icons/Face';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Form from '../form/Form';
import { operatorService, laundryService, userService } from '../../services';
import toast from 'toasted-notes'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStyles2 = makeStyles({
  bodymodal: {
    margin: "auto",
    width: "800px",
    maxWidth: "calc(100vh - 40px)",
    background: "white",
    padding: "2em",
    marginTop: "100px",
    textAlign: "center"
  },
  inputformtime: {
    width: '100%',
    "& label": {
      transform: 'translate(0, 1.5px) scale(0.75)'
    }
  },
  inputform: {
    width: '300px',
    maxWidth:'50%',
    fontSize: '0.9em',
    marginRight: "1em",
    "& label": {
      transform: 'translate(0, 1.5px) scale(0.75)',
      color: 'white !important',
    },
    "& input": {
      color: 'white !important',
    },
    "& span":{
      color: 'white !important',
    },
    "& .MuiInput-underline:before":{
      borderBottomColor: "white !important"
    }

  },
});


const initElement = {
  login:"",
  password:""
};


export default function Header(props) {
  const classes2= useStyles2();
  const history = useHistory();
  const hiddenalert = true;
  const [openOperator,setOpenOperator] = React.useState(false);
  const [operatorNameSelect,setOperatorName] = React.useState(operatorName());
  const [laundries, setLaundries] = React.useState([]);
  const [laundrySelected, setLaundrySelected] = React.useState<any>({id:-1,name:""});



  const estructureform =  [
    {
      type:"input",
      name: "login",
      label: "Login",
      col: 12
     },
     {
      type:"input",
      name: "pass",
      label: "Contraseña",
      col: 12,
      inputtype: "password"
     },
];


  const closeSession = function(){
    localStorage.clear();
    history.push('/');
  }

  const handleCloseModal = (event, reason) => {
    if(reason && (reason == "escapeKeyDown" || reason == "backdropClick")){

    } else {
          setOpenOperator(false);
    }
  }


  const create = (object,cleanSerie) => {
    operatorService.login(object, (data, error) => {
        if(data && data.data && data.data.id){
          localStorage.setItem('operator', JSON.stringify(data.data));
          setOperatorName(operatorName())
          handleCloseModal(null,null)
        } else {
          toast.notify("Login o contraseña incorrecta", {position:'top', duration: 10000})
        }
    });
  }


  React.useEffect(() => {
    if(!isAdmin() && operator().id == -1 && !haveMore()){
      setOpenOperator(true)
    };
    if(!isAdmin() && operator().id == -1 && window.location.href.indexOf("tpv") > 0){
      setOpenOperator(true)
    };


    if(!isAdmin() && haveMore()){
      laundryService.my((data, error)=> {
          if(data && data.data){
            setLaundries(data.data);
          }
      });
    }

    if(!isAdmin()){
      setLaundrySelected({id:idLaundry()?idLaundry():-1,name:nameLaundry()})
    }

  }, []);

    const getLabelOption = function (option){
      if(option && option.name){
        if (typeof option.name === 'string'){
          return option.name
        } else {
          return ""
        }
      } else {
        return "";
      }
  }

  return (
    <AppBar position="absolute" className={clsx(props.classes.appBar)}>
        <Toolbar className={props.classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.open ? props.handleDrawerClose: props.handleDrawerOpen}
            className={clsx(props.classes.menuButton)}>
            <MenuIcon />
          </IconButton>
          <img src={logo} className={props.classes.logoheader} />
          <Typography component="h1" variant="h6" color="inherit" noWrap className={props.classes.title}>
            {props.title}
          </Typography>
          {!hiddenalert &&<IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>}
          {!isAdmin() && haveMore() ? <>

                <Autocomplete
                      id={"laundryselected"}
                      options={laundries}
                      disableClearable
                      getOptionLabel={getLabelOption}
                      value={laundrySelected}
                      className={classes2.inputform}
                      onChange={(event,value) => {
                        setLaundrySelected({id:value?.id ? +value?.id: -1, name: value?.name ? value?.name : ""});
                        userService.changelaundry(value.id, (data,error) => {
                          if (data) {
                            if (data.user.isClient) {
                               
                            } else {
                              localStorage.setItem('token', data.token);
                              localStorage.setItem('user', JSON.stringify(data.user));
                              localStorage.setItem('web', data.user.web?data.user.web:false);
                              localStorage.setItem('numberintpv', data.user.numberintpv?data.user.numberintpv:false);
                              localStorage.setItem('havemore', data.user.havemore?data.user.havemore:false);
                              localStorage.setItem('idWasher', data.user.idWasher?data.user.idWasher:-1);
                              var base64 = encodeURIComponent(data.user.washerName);
                              localStorage.setItem('washerName', base64);
                              localStorage.removeItem("operator");
                              if(window.location.href.indexOf("laundry/edit")>0){
                                window.location.href = "/laundry/edit"+data.user.idWasher?data.user.idWasher:-1
                              } else {
                                window.location.reload();
                              }
                            }
                         } else {

                         }
                        });
                      }}
                      renderInput={(params) => <TextField {...params} label={"Selecciona la lavandería"}  inputProps={{ ...params.inputProps }} />}
                    />
            </> 
            : !isAdmin() ? <h3 style={{marginRight: "1em"}}>Trabjando en {nameLaundry()}</h3>: <></>}
          {!isAdmin() && <><b>Dependiente:</b><span style={{paddingLeft: "5px"}}> {operatorNameSelect}</span>
          <IconButton style={{backgroundColor: "white", marginLeft: "1em", height: "30px", width: "30px" }} onClick={() => setOpenOperator(true)}>
            <Badge color="primary">
              <CoPresentIcon />
            </Badge>
          </IconButton></>}
          <IconButton style={{backgroundColor: "white", marginLeft: "1em", height: "30px", width: "30px" }} onClick={closeSession}>
            <Badge color="primary">
              <PowerSettingsNewIcon />
            </Badge>
          </IconButton>
        </Toolbar>
        <Modal
            open={openOperator}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">

            <div className={classes2.bodymodal}>
                <div>
                  <h2>Indica tus credenciales como dependendiente en {nameLaundry()}</h2>
                </div>
                <div>
                <Form objectform={{...initElement}} fields={estructureform}  searchFilter={create}  labelsave={"Entrar"} isHiddenCancel={true} haveMoreActions={false} moreActions={null} forcesave={false} onlyRead={false} />
                </div>
            </div>
          </Modal>
      </AppBar>
  );
}
