import BaseService from './_base.service';

const URL = "/admin/laundry";

export const laundryService = {
    search,
    combo,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    searchcp,
    removecp,
    assigncp,
    searchproduct,
    removeproduct,
    assignproduct,
    searchtimetable,
    removetimetable,
    assigntimetable,
    daysPickup,
    daysDelivery,
    searchcpcombo,
    my
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}

function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}


function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}


function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}


function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}


function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}


function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}

function searchcp(id,callback){
  return BaseService.get(URL+"/"+id+"/asign/postalcode", callback)
}

function searchcpcombo(id,callback){
  return BaseService.get(URL+"/"+id+"/asign/postalcode/combo", callback)
}

function removecp(id,postalcode,callback){
    return BaseService.post(URL+"/"+id+"/remove/postalcode",postalcode, callback)
}

function assigncp(id,postalcode,callback){
    return BaseService.post(URL+"/"+id+"/asign/postalcode",postalcode, callback)
}

function searchproduct(id,callback){
  return BaseService.get(URL+"/"+id+"/asign/product", callback)
}

function removeproduct(id,product,callback){
    if(product["product"]) {
        return BaseService.post(URL+"/"+id+"/remove/product",product["product"], callback)
    } else {
        return BaseService.post(URL+"/"+id+"/remove/product",product, callback)
    }
}

function assignproduct(id,product,callback){
  if(product["product"]) {
      return BaseService.post(URL+"/"+id+"/asign/product",product["product"], callback)
  } else {
      return BaseService.post(URL+"/"+id+"/asign/product",product, callback)
  }
}

function searchtimetable(id,callback){
  return BaseService.get(URL+"/"+id+"/asign/timetable", callback)
}

function removetimetable(id,postalcode,callback){
    return BaseService.post(URL+"/"+id+"/remove/timetable",postalcode, callback)
}

function assigntimetable(id,postalcode,callback){
    return BaseService.post(URL+"/"+id+"/asign/timetable",postalcode, callback)
}


function daysPickup(id,callback){
  return BaseService.get("/client/laundry/days/pickup/"+id, callback)
}

function daysDelivery(id,callback){
  return BaseService.get("/client/laundry/days/delivery/"+id, callback)
}

function my(callback){
    return BaseService.get(URL+"/my", callback);
}