import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {cashoutService as service} from "../../../services"
import {isAdmin,idLaundry,nameLaundry} from '../../utils/Utils'

const moment = require("moment");



const initElement = {
     laundry:{id:isAdmin()?null:idLaundry(),name:isAdmin()?"":nameLaundry()},
     date:moment(new Date()).utc().format("YYYY-MM-DDTHH:mm") + ":00Z",
     name: "",
     effective:0.0,
  };



export default function FormCash() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams();



  const estructureform =  [

        {
          type:"input",
          name: "date",
          label: "Desde",
          inputtype: "datetime-local",
          col: 4
         },
         {
           type:"input",
           name: "name",
           label: "Motivo",
           col: 4,
          },
           {
             type:"input",
             name: "effective",
             inputtype:"number",
             step:0.01,
             label: "Cantidad",
             col: 4
            },

    ];



  const recoveryElement = function(objectedit,element){

    objectedit['date']=element.date?element.date:null;
    objectedit['name']=element.name?element.name:null;
    objectedit['effective']=element.effective?element.effective:0.0;

    return objectedit;
  }



  const isValid = function(object){
      if(!object['date'] || object['date'] == ""){
        toast.notify("Debe indicar la fecha", {position:'top', duration: 10000})
        return false;
      }
      if(!object['name'] || object['name'].length == 0){
        toast.notify("Debe indicar el motivo", {position:'top', duration: 10000})
        return false;
      }
      if(!object['effective'] || object['effective'].length == 0){
        toast.notify("Debe indicar la cantidad", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }


  return (
    <CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        titleEdit = {"Editar retirada de efectivo"}
        titleNew = {"Nueva retirada de efectivo"}
        urledit={"/cashout/edit/"}
        urlCancel={"/cashout"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
