import React, { useState, useEffect }  from 'react';
import { useParams,useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles,drawerWidth } from "../layout/styles";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import {clientService, paymenttypeService} from "../../services"
import Form from "../form/Form"
import {isAdmin} from '../utils/Utils'
import 'toasted-notes/src/styles.css';


const initClient = {
    email:"",
    pass: "",
    name: "",
    lastname: "",
    isClient:true,
    rol: isAdmin()?{code:null,name:""}:{code: "CLIENT", name: "CLIENT"},
    enable: true,
    laundry:{id:null,name:""},
    picture:null,
    web:false,
    percentDiscount:null,
    paymentType:{id:null,name:""},
    streetDefault:{
     id:null,
     name:"",
     street:"",
     phone:"",
     number:"",
     door:"",
     floor:"",
     stairs:"",
     city:"",
     country:"España",
     postalcode:"",
     latitude:"",
     longitude:"",
    }
 };

 const useStyles2 = makeStyles({
   bodymodal: {
     margin: "auto",
     width: "800px",
     maxWidth: "calc(100vh - 40px)",
     background: "white",
     padding: "2em",
     marginTop: "100px",
     textAlign: "center"
   },
   inputformtime: {
     width: '100%',
     "& label": {
       transform: 'translate(0, 1.5px) scale(0.75)'
     }
   },
 });


export default function ModalClient(props) {
 let { id } = useParams();
 const [idRow, setIdrow] = useState(id);
 const classes = useStyles();
 const classes2= useStyles2();
 const history = useHistory();
 const [client,setClient] = useState({key:"-1",name:""});
 const [clients,setClients] = useState([{key:"-1",name:""}]);
 const [clientform,setClientform] = useState({...initClient});
 const [openClient, setOpenClient] = useState(false);
 const [editable,setEditable] = useState(props.editable != null?props.editable:true)
 
 const estructureformClient =  [
    {
     type:"input",
     name: "email",
     label: "Email",
     col: 6
    },
    {
     type:"input",
     name: "nif",
     label: "NIF/CIF/DNI",
     col: 6
    },
    {
     type:"input",
     name: "name",
     label: "Nombre",
     col: 6
    },
    {
     type:"input",
     name: "lastname",
     label: "Apellido",
     col: 6
    },
    {
     type:"input",
     name: "phone",
     label: "Teléfono",
     col: 6
    },
    {
    type:"input",
    name: "percentDiscount",
    label: "Descuento por defecto",
    inputtype: "number",
    step:0.01,
    col: 6
    },
    {
      type:"autocomplete",
      name: "paymentType.id",
      label: "Forma de pago",
      service:paymenttypeService.combo,
      col: 6
     },
    {
     type:"title",
     name: "streettitle",
     label: "Dirección",
     col: 12
    },
    {
      type:"input",
      name: "streetDefault.street",
      label: "Calle",
      col: 8
     },
    {
      type:"input",
      name: "streetDefault.number",
      label: "Nº",
      col: 2
     },
    {
      type:"input",
      name: "streetDefault.stairs",
      label: "Escl.",
      col: 2
     },
    {
      type:"input",
      name: "streetDefault.floor",
      label: "Piso",
      col: 2
     },
    {
      type:"input",
      name: "streetDefault.door",
      label: "Puerta",
      col: 2
     },
    {
      type:"input",
      name: "streetDefault.city",
      label: "Ciudad",
      col: 4
     },
    {
      type:"input",
      name: "streetDefault.postalcode",
      label: "C.P",
      col: 4
     }
];


const handleCloseClient = () => {
    setOpenClient(false);
    setClientform({...initClient});
  }

const saveFormClient = (object) => {
    handleCloseClient();
    clientService.create(object, (data, error) => {
    if(data){
        props.saveClient(data.data);
    }
});

}


return (
    <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
        {editable && <Button variant="contained" color="secondary" style={{marginLeft:'10px'}} onClick={() => setOpenClient(true)}> Nuevo cliente </Button>}

      <Modal
          open={openClient}
          onClose={handleCloseClient}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div className={classes2.bodymodal}>
            <div>
              <h2>Nuevo cliente</h2>
            </div>
            <div>
              <Form objectform={clientform} fields={estructureformClient}  searchFilter={saveFormClient} cleanFilter={handleCloseClient} labelsave={"Guardar"} labelcancel={"Cancelar"} />
            </div>
        </div>
        </Modal>
    </Grid>
  );
}

