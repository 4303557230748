import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from '../layout/Header';
import Menu from '../layout/Menu';
import Copyright from '../layout/Copyright';
import { useStyles,drawerWidth } from "../layout/styles";
import Form from "../form/Form"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Layout from '../layout/Layout';


export default function CrudForm(props) {
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const titleEdit = props.titleEdit? props.titleEdit : "Editar Elemento";
  const titleNew = props.titleNew? props.titleNew : "Nuevo Elemento";
  const service =  props.service?props.service:null;
  const recoveryElement =  props.recoveryElement?props.recoveryElement:null;
  const valid =  props.valid?props.valid:null;
  const urlCancel =  props.urlCancel?props.urlCancel:"/dashboard";
  const urledit =  props.urledit?props.urledit:"";
  const buttonSave =  props.buttonSave?props.buttonSave:"Guardar";
  const buttonCancel =  props.buttonCancel?props.buttonCancel:"Cancel";
  const haveotherTop = props.haveotherTop ? props.haveotherTop : null;
  const othersformTop  = props.othersformTop? props.othersformTop : null;

  const [{idRow,objectform,titleWindow,reload,estructureform,haveother,othersform,onlyRead},setState]= useState({
    idRow:id ? Number(id):(props.id?props.id:0),
    objectform: props.objectform? props.objectform : {},
    titleWindow:(id && Number(id)) || props.id ? titleEdit:titleNew,
    reload: false,
    estructureform: props.estructureform? props.estructureform : {},
    haveother:props.haveother? props.haveother : false,
    othersform:props.othersform? props.othersform : null,
    onlyRead:props.onlyRead ?props.onlyRead:false
  });




  useEffect(() => {

    if(idRow && idRow > 0){
      searchOne();
    };

    setState(currentState => ({...currentState, ["titleWindow"]:idRow && idRow > 0? titleEdit:titleNew}));


  }, [idRow]);

  const searchOne = function(){
    service.one(idRow,(data, error) => {

      if(data && data.data){
        var element = data.data;
        let objectedit = JSON.parse(JSON.stringify(element));
        if (recoveryElement) {
          objectedit = recoveryElement(objectedit,element)

          if(props.checkIsReadOnly) {
            var update2 = {
              objectform: objectedit,
              reload: true,
              onlyRead: props.checkIsReadOnly(objectedit)
           }

           setState(currentState => ({...currentState, ...update2}));
            } else {
              var update = {
                objectform: objectedit,
                reload: true
              }

              setState(currentState => ({...currentState, ...update}));
          }
          if(props.initState){
            props.initState(objectedit);
          }
        } else {
          var update3 = {
            objectform: element,
            reload: true
         }

         setState(currentState => ({...currentState, ...update3}));
        }

     }
    });
  }

  const cancelForm = (object) => {
      history.push(urlCancel);
  }

  const saveForm = (object) => {
       if(valid == null || valid(object)) {
         if(props.beforeSave) {
           object = props.beforeSave(object);
         }
         var objToSave = cleanElementsBeforeSave(object);
         if (idRow && idRow > 0){
           objToSave['id'] = id;
           update(objToSave,false);
         } else {
           create(objToSave,false);
         }
       }

  }

  const create = (object,cleanSerie) => {
    service.create(object, (data, error) => {
        if(data){
          setState(currentState => ({...currentState, ["idRow"]:Number(data.data.id)}))
          toast.notify("Guardado correctamente", {position:'top', duration: 10000})
          history.push(urledit+data.data.id);
        }
    });
  }

  const update = (object,cleanSerie) => {
    setState(currentState => ({...currentState, ["reload"]:false}))
    service.edit(id,object, (data, error) => {
        if(data){
          var objectedit = recoveryElement(object,data.data)
          var update = {
            objectform: objectedit,
            reload: true
         }

         setState(currentState => ({...currentState, ...update}));
        }
    });
  }

  const cleanElementsBeforeSave = function(object){
    let newObj = JSON.parse(JSON.stringify(object));
    return newObj;
  }


  useEffect(() => {

    setState(currentState => ({...currentState, ["estructureform"]:props.estructureform}))

  }, [props.estructureform]);


  useEffect(() => {

    var update = {
      objectform: props.objectform,
      reload: true
   }

   setState(currentState => ({...currentState, ...update}));

 }, [props.objectform]);


 useEffect(() => {
    
  if(!id && props.initTask) {
    setState(currentState => ({...currentState, ["reload"]:false}));
    props.initTask();
  }

  if(props.initeffect){
    props.initeffect();
  }

  return () => {
    debugger;
    setState(
      {
        idRow:0,
        objectform:  {},
        titleWindow:titleNew,
        reload: false,
        estructureform:  {},
        haveother: false,
        othersform: null,
        onlyRead:false
      }
    )
  }
}, []);

  useEffect(() => {
    
    var update = {
      reload: props.reload
   }

   setState(currentState => ({...currentState, ...update}));
   
  }, [props.reload]);

  return (
    <Layout titleWindow={titleWindow}>
          <>{haveotherTop && haveotherTop() && othersformTop}
              <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                  <Form objectform={objectform} fields={estructureform}  searchFilter={saveForm} cleanFilter={cancelForm} labelsave={buttonSave} labelcancel={buttonCancel} reload={reload} haveMoreActions={props.moreActions?props.moreActions:false} moreActions={props.moreActions?props.moreActions:null} forcesave={props.forcesave != null? props.forcesave : false} onlyRead={onlyRead} />
              </Grid>
              {haveother && idRow > 0 && othersform}
          </>
    </Layout>
  );
}
