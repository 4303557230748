import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {configurationService as service} from "../../../services"

const initElement = {
     postalcode:"",
     client: {id:null,name:""}
  };

export default function FormConfiguration() {
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
          {
           type:"input",
           name: "minversionios",
           inputtype:"number",
           label: "Min. Version IOS",
           col: 6
          },
          {
           type:"input",
           name: "minversionandroid",
           inputtype:"number",
           label: "Min. Version Android",
           col: 6
          },
    ];


  const recoveryElement = function(objectedit,element){


    objectedit['minversionandroid']=element.minversionandroid?element.minversionandroid:"";
    objectedit['minversionios']=element.minversionios?element.minversionios:"";

    return objectedit;
  }


  const isValid = function(object){
      if(!object['minversionios']){
        toast.notify("Debe indicar la version minima de IOS", {position:'top', duration: 10000})
        return false;
      }
      if(!object['minversionandroid']){
        toast.notify("Debe indicar la version minima de Android", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }


  return (
    <CrudForm
        objectform = {initElement}
        estructureform = {estructureform}
        titleEdit = {"Editar Configuración"}
        titleNew = {"Nueva Configuración"}
        urledit={"/configuration/edit/"}
        urlCancel={"/configuration/edit/1"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
