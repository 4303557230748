import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import { useParams,useHistory } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import Button from '@material-ui/core/Button';
import {workService as service, laundryService, clientService, stateworkService, productService,uploadService, printService} from "../../../services"
import Webcam from "react-webcam";
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import {isAdmin,idLaundry,nameLaundry,getUrlImage} from '../../utils/Utils'
import { isNonNullExpression } from 'typescript';
const moment = require("moment");

const useStyles = makeStyles({
  bodymodal: {
    margin: "auto",
    width: "500px",
    maxWidth: "calc(100vh - 40px)",
    background: "white",
    marginTop: "50px",
    textAlign: "center"
  },
  titlemodal:{
    background:"#3c3c44",
    padding: "0.5em 0em",
    color: "white",

  }
});



const initline = {
  id: -1,
  product:{id:null,name:""},
  amount:0.0,
  price:0.0,
  total:0.0,
  base:0.0,
  discount:0.0,
  discountPercent:0.0
}


const initElement = {
     client:{id:null,name:""},
     laundry:{id:isAdmin()?null:idLaundry(),name:isAdmin()?"":nameLaundry()},
     state:{id:null,name:""},
     numberOrder: null,
     date: null,
     dateDelivery: null,
     hour:"",
     hourDelivery: "",
     isSuscription:null,
     labelwash:null,
     numberkey:null,
     locationInLocal:null,
     orderclient:{
       id:null,
       lines:[

       ]
     },
     ticket:{
       id:null,
       lines:[

       ]
     },
     location:{
        id:null,
        name:"",
        street:"",
        phone:"",
        number:"",
        door:"",
        floor:"",
        stairs:"",
        city:"",
        country:"España",
        postalcode:"",
        latitude:"",
        longitude:"",
     },


  };

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

export default function FormWork() {
  const classes = useStyles();
  let { id,ticket } = useParams();

  const [capture, setCapture] = useState(false);
  const [objectform, setObjectform] = useState({...initElement});
  const [viewvideo, setViewvideo] = useState(false);
  const [openCapture, setOpenCapture] = useState(false);
  const [openModalCapture, setOpenModalCapture] = React.useState(false);
  const [openview, setOpenview] = React.useState(false);
  const [urlvideo,setUrlvideo] = React.useState("");
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [forcesave, setForcesave] = React.useState(false);
  const [isTicket, setIsTicket] = React.useState(id > 0 && ticket == 1);
  const [isDeliverynote, setIsDeliverynote] = React.useState(id > 0 && ticket == 2);
  const [urlvideoToSave,setUrlVideoToSave] = React.useState(null);
  let updateField = null;
  let recoveryObject = null;



  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState(null);

  const handleStartCaptureClick = React.useCallback(() => {
     setCapturing(true);

      if(webcamRef != null && webcamRef.current != null && webcamRef.current.stream != null) {
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
         mimeType: "video/webm"
       });

       mediaRecorderRef.current.addEventListener(
         "dataavailable",
         handleDataAvailable
       );

       mediaRecorderRef.current.start();
    }



   }, [webcamRef, setCapturing, mediaRecorderRef]);

   const handleDataAvailable = React.useCallback(
          ({ data }) => {
            if (data.size > 0) {
              setRecordedChunks(data);
              debugger;
              if (mediaRecorderRef.current.state == "inactive"){
                upload(data);
              }
            }
          }, [setRecordedChunks]);



  const upload = (recordedChunks) => {
    if (recordedChunks.size) {
      console.log("Entra en subir");

      uploadService.uploadWithName(recordedChunks,"blob.webm", (data,erro) =>{
         setOpenCapture(false);
         setOpenModalCapture(false);
         setCapturing(false);
         //objectform['videopath'] = data.data
         //setObjectform(objectform);

         if(data && data.data){
          setUrlVideoToSave(data.data)
          setUrlvideo(getUrlImage(data.data))
          setViewvideo(true);
          setForcesave(true);
          setForcesave(false);
         }
         
         setCapture(false);
      });

      setRecordedChunks(null);
    }
  }

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);


  const setValuePickup = (setValueField,objectform,value) => {
    debugger;
      var newobject =  setValueField(objectform,"date",value.date);
      newobject =  setValueField(objectform,"hour",value.hour);
      return newobject;
  }

  const setValueDelivery = (setValueField,objectform,value) => {
    debugger;
      var newobject =  setValueField(objectform,"dateDelivery",value.date);
      newobject =  setValueField(objectform,"hourDelivery",value.hour);
      return newobject;
  }

  const searchDayDelivery = (setOptions,objectform) => {
      if (objectform.laundry.id != null){
          laundryService.daysDelivery(objectform.laundry.id ,(data, error) => {
            if(data && data.data){
              setOptions(currentOptions => ({...currentOptions, ["dateDelivery"]: data.data }));
            }
          });
      }
  }

  const updateRows = (setValueField,objectform,rows,setValueform) => {
      var totalLines = 0.0;

      for (let row of rows) {
          if(row["total"]) {
              totalLines = totalLines + (+row["total"]);
          }
      }

      var disper = +objectform["discountPercent"];
      var dis = (totalLines * (disper / 100.0)).toFixed(2);
      var total = totalLines - +dis;
      var base = (total / 1.21).toFixed(2);


      setValueform(currentValueform => ({...currentValueform, ["total"]: total }))
      setValueform(currentValueform => ({...currentValueform, ["discount"]: dis }))
      setValueform(currentValueform => ({...currentValueform, ["base"]: base }))
      var newobject =  setValueField(objectform,"total",total);
      newobject =  setValueField(newobject,"discount",dis);
      newobject =  setValueField(newobject,"base",base);
      return newobject;
  }



  const initValuePick = (obj) => {
    if(obj.date && obj.hour) {
      var value = (moment(Date.parse(obj.date))).format('DD/MM/YYYY') + " | " +  obj.hour;
      return  {key:value,name: value}
    } else {
      return {key:"",name: ""};
    }
  };

  const initValueDel = (obj) => {
    if(obj.dateDelivery && obj.hourDelivery) {
      var value = (moment(Date.parse(obj.dateDelivery))).format('DD/MM/YYYY') + " | " +  obj.hourDelivery;
      return  {key:value,name: value}
    } else {
      return {key:"",name: ""};
    }
  };

  const estructureformline =  [
         {
          type:"input",
          name: "product.name",
          label: "Producto",
          colSpan:"30%",
          readonly: true,
          col: 4
         },
         {
           type:"input",
           name: "amount",
           inputtype:"number",
           step:0.01,
           label: "Cantidad",
           readonly: true,
           col: 3
          }
    ];

    const estructureformlineTicket =  [
      {
       type:"input",
       name: "name",
       label: "Producto",
       colSpan:"30%",
       readonly: true,
       col: 4
      },
      {
        type:"input",
        name: "amount",
        inputtype:"number",
        step:0.01,
        label: "Cantidad",
        readonly: true,
        col: 3
       }
 ];

  const estructureform =  [
         {
           type:"title",
           name: "titledata",
           label: "Datos pedido",
           col: 12
          },
          {
           type:"autocomplete",
           name: "client.id",
           label: "Cliente",
           service:clientService.combo,
           readonly:true,
           col: 4
          },
          {
           type:"autocomplete",
           name: "laundry.id",
           label: "Lavandería",
           service:laundryService.combo,
           relationfield:'date',
           servicerelation:laundryService.daysPickup,
           readonly:true,
           col: 4
          },
         {
           type:"input",
           name: "orderclient.numberOrder",
           label: "Nº Pedido",
           readonly:true,
           col: 4
          },
         {
           type:"autocomplete",
           name: "date",
           label: "Fecha. Recogida",
           relationfield:'dateDelivery',
           servicerelation:laundryService.daysDelivery,
           relationSearch:searchDayDelivery,
           options:[],
           setvalue: setValuePickup,
           initValue: initValuePick,
           col: 4
          },
         {
           type:"autocomplete",
           name: "dateDelivery",
           label: "Fecha. Devolucion",
           options:[],
           setvalue: setValueDelivery,
           initValue: initValueDel,
           col: 4
          },
         {
           type:"autocomplete",
           name: "state.id",
           label: "Estado",
           service:stateworkService.combo,
           col: 4
          },
          {
           type:"input",
           name: "labelwash",
           label: "Etiqueta de lavado",
           col: 4
          },
          {
           type:"input",
           name: "numberkey",
           label: "Llavero recogida",
           col: 4
          },
          {
           type:"input",
           name: "locationInLocal",
           label: "Ubicación en tienda",
           col: 4
          },
         {
           type:"title",
           name: "titlestreet",
           label: "Dirección",
           col: 12
          },
         {
           type:"input",
           name: "location.street",
           label: "Calle",
           col: 4
          },
         {
           type:"input",
           name: "location.number",
           label: "Nº",
           col: 2
          },
         {
           type:"input",
           name: "location.stairs",
           label: "Escl.",
           col: 2
          },
         {
           type:"input",
           name: "location.floor",
           label: "Piso",
           col: 2
          },
         {
           type:"input",
           name: "location.door",
           label: "Puerta",
           col: 2
          },
         {
           type:"input",
           name: "location.city",
           label: "Ciudad",
           col: 4
          },
         {
           type:"input",
           name: "location.postalcode",
           label: "C.P",
           col: 4
          },
         {
           type:"input",
           name: "location.phone",
           label: "Tlf.",
           col: 4
          },
         {
           type:"title",
           name: "titlelineas",
           label: "Productos",
           col: 12
          },
         {
           type:"table",
           name: "orderclient.lines",
           label: "Lineas",
           headers:[{label:"Producto",colSpan:"60%"   },{label:"Cantidad",colSpan:"40%"   }],
           initrow:{... initline},
           fields:estructureformline,
           fieldId:"id",
           setvalue: updateRows,
           onlyread:true,
           col: 12
          }
    ];


      const estructureformTicket =  [
             {
               type:"title",
               name: "titledata",
               label: "Datos pedido",
               col: 12
              },
              {
               type:"autocomplete",
               name: "client.id",
               label: "Cliente",
               service:clientService.combo,
               readonly:true,
               col: 4
              },
              {
               type:"autocomplete",
               name: "laundry.id",
               label: "Lavandería",
               service:laundryService.combo,
               relationfield:'date',
               servicerelation:laundryService.daysPickup,
               readonly:true,
               col: 4
              },
             {
               type:"input",
               name: "ticket.numberTicket",
               label: "Nº Ticket",
               readonly:true,
               col: 4
              },
             {
               type:"autocomplete",
               name: "state.id",
               label: "Estado",
               service:stateworkService.combolocal,
               col: 4
              },
              {
               type:"input",
               name: "labelwash",
               label: "Etiqueta de lavado",
               col: 4
              },
              {
               type:"input",
               name: "numberkey",
               label: "Llavero recogida",
               col: 4
              },
              {
               type:"input",
               name: "locationInLocal",
               label: "Ubicación en tienda",
               col: 4
              },
             {
               type:"title",
               name: "titlelineas",
               label: "Productos",
               col: 12
              },
             {
               type:"table",
               name: "ticket.lines",
               label: "Lineas",
               headers:[{label:"Producto",colSpan:"60%"   },{label:"Cantidad",colSpan:"40%"   }],
               initrow:{... initline},
               fields:estructureformlineTicket,
               fieldId:"id",
               setvalue: updateRows,
               onlyread:true,
               col: 12
              }
        ];


        const estructureformDelivery =  [
          {
            type:"title",
            name: "titledata",
            label: "Datos pedido",
            col: 12
           },
           {
            type:"autocomplete",
            name: "client.id",
            label: "Cliente",
            service:clientService.combo,
            readonly:true,
            col: 4
           },
           {
            type:"autocomplete",
            name: "laundry.id",
            label: "Lavandería",
            service:laundryService.combo,
            relationfield:'date',
            servicerelation:laundryService.daysPickup,
            readonly:true,
            col: 4
           },
          {
            type:"input",
            name: "deliveryNote.numberDelivery",
            label: "Nº Albaran",
            readonly:true,
            col: 4
           },
          {
            type:"autocomplete",
            name: "state.id",
            label: "Estado",
            service:stateworkService.combolocal,
            col: 4
           },
           {
            type:"input",
            name: "labelwash",
            label: "Etiqueta de lavado",
            col: 4
           },
           {
            type:"input",
            name: "numberkey",
            label: "Llavero recogida",
            col: 4
           },
           {
            type:"input",
            name: "locationInLocal",
            label: "Ubicación en tienda",
            col: 4
           },
          {
            type:"title",
            name: "titlelineas",
            label: "Productos",
            col: 12
           },
          {
            type:"table",
            name: "deliveryNote.lines",
            label: "Lineas",
            headers:[{label:"Producto",colSpan:"60%"   },{label:"Cantidad",colSpan:"40%"   }],
            initrow:{... initline},
            fields:estructureformlineTicket,
            fieldId:"id",
            setvalue: updateRows,
            onlyread:true,
            col: 12
           }
     ];


  const recoveryElement = function(objectedit,element){
    objectedit['date']=element.date?element.date:"";
    objectedit['hour']=element.hour?element.hour:"";
    objectedit['dateDelivery']=element.dateDelivery?element.dateDelivery:"";
    objectedit['hourDelivery']=element.hourDelivery?element.hour:"";
    objectedit['client']={id:element.client && element.client.id?element.client.id:null, name: element.client && element.client.name?element.client.name:null};
    objectedit['laundry']={id:element.laundry && element.laundry.id?element.laundry.id:null, name: element.laundry && element.laundry.name?element.laundry.name:null};
    objectedit['state']={id:element.state && element.state.id?element.state.id:null, name: element.state && element.state.name?element.state.name:null};
    objectedit['orderclient']=element.orderclient?element.orderclient:{id:null,lines:[]};
    objectedit['ticket']=element.ticket?element.ticket:{id:null,lines:[]};
    objectedit['deliveryNote']=element.deliveryNote?element.deliveryNote:{id:null,lines:[]};

    objectedit['labelwash']=element.labelwash?element.labelwash:"";
    objectedit['numberkey']=element.numberkey?element.numberkey:"";
    objectedit['videopath']=element.videopath?element.videopath:"";


    objectedit['location']=element.location?element.location:{
       id:null,
       name:"",
       street:"",
       phone:"",
       number:"",
       door:"",
       floor:"",
       stairs:"",
       city:"",
       country:"España",
       postalcode:"",
       latitude:"",
       longitude:"",
    };



    return objectedit;
  }

  const initState = function(element) {

    const promise = Promise.resolve(element);
    promise.then((element) => {
        setViewvideo(element.videopath != null && element.videopath.length > 0);
        setCapture(element.videopath == null || element.videopath.length == 0);


        setUrlvideo(getUrlImage(element.videopath))
    });

    
  }



  const isValid = function(object){
      if(!object['client'] || !object['client']['id']){
        toast.notify("Debe indicar el cliente", {position:'top', duration: 10000})
        return false;
      }
      if(!object['laundry'] || !object['laundry']['id']){
        toast.notify("Debe indicar la lavandería", {position:'top', duration: 10000})
        return false;
      }
      if(!object['state'] || !object['state']['id']){
        toast.notify("Debe indicar el estado", {position:'top', duration: 10000})
        return false;
      }
      if((!object['ticket'] || !object['ticket']['id']) && (!object['location'] || !object['location']['street']  || object['location']['street'] == "")){
        toast.notify("Debe indicar la calle", {position:'top', duration: 10000})
        return false;
      }
      if((!object['ticket'] || !object['ticket']['id']) && (!object['location'] || !object['location']['postalcode']  || object['location']['postalcode'] == "")){
        toast.notify("Debe indicar el código postal", {position:'top', duration: 10000})
        return false;
      }
      if((!object['ticket'] || !object['ticket']['id']) && (!object['hour'] || object['hour'] == "")){
        toast.notify("Debe indicar la hora de recogida", {position:'top', duration: 10000})
        return false;
      }
      if((!object['ticket'] || !object['ticket']['id']) && (!object['hourDelivery'] || object['hourDelivery'] == "")){
        toast.notify("Debe indicar la hora de entrega", {position:'top', duration: 10000})
        return false;
      }

      if((!object['ticket'] || !object['ticket']['id']) && (!object['dateDelivery'] || object['dateDelivery'] == "")){
        toast.notify("Debe indicar la fecha de entrega", {position:'top', duration: 10000})
        return false;
      }
      if((!object['ticket'] || !object['ticket']['id']) && (!object['date'] || object['date'] == "")){
        toast.notify("Debe indicar la fecha de recogida", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }

  const openRecord = (event) => {
      setOpenCapture(true);
      setOpenModalCapture(true);
  }
  const moreActions = (getObject,setValueField) => {
    recoveryObject = getObject;
    updateField = setValueField;
    return <>
          {viewvideo && <Button variant="outlined" color="primary" style={{marginLeft:'30px'}} onClick={() => setOpenview(true)}> Ver recogida </Button>}
          {capture && <Button variant="outlined" color="primary" style={{marginLeft:'30px'}} onClick={openRecord}> Grabar recogida </Button>}
          <Button variant="outlined" color="primary" style={{marginLeft:'30px'}} onClick={()=>handlePrint(recoveryObject)}> Imprimir ticket </Button>
      </>
  }


  const handlePrint = (t) => {    
    printService.work(t(),(data,error)=>{});
  }

  const handleClose = () => {
     setOpenCapture(false);
     setOpenModalCapture(false);
     setCapturing(false);
   };


   const handleCloseview = () => {
      setOpenview(false);
    };



 const beforeSave = (object) => {
    object['videopath'] = urlvideoToSave;
    return object;
 }



  return (
    <>
      <CrudForm
        objectform = {objectform}
        estructureform = {isTicket?estructureformTicket:isDeliverynote?estructureformDelivery:estructureform}
        titleEdit = {"Editar orden de trabajo"}
        titleNew = {"Nueva orden de trabajo"}
        urledit={"/work/edit/"}
        urlCancel={"/work"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        beforeSave={beforeSave}
        haveMoreActions={true}
        forcesave={forcesave}
        moreActions={moreActions}
        initState={initState}
      />
      <Modal
          open={openModalCapture}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
              <div className={classes.bodymodal}>
                  <div className={classes.titlemodal}>
                      <h3 style={{fontSize:"2em"}}>Grabar recogida </h3>
                  </div>
                <div  style={{  padding: "1.2em"}}>
                        { openCapture && <Webcam
                          audio={false}
                          height={400}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          width={"100%"}
                          videoConstraints={videoConstraints}
                        /> }

                        { !openCapture && <div style={{height:"400px", textAlign:"center", border: "1px solid #ccc", borderRadius: "6px",  paddingTop: "9em"}}> <VideocamOffIcon  style={{fontSize:"10em"}}/> </div>}
                </div>
                <div  style={{  padding: "1.2em"}}>
                   {!capturing ? (<Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleStartCaptureClick}> Empezar</Button>) : (<Button variant="contained" color="primary" onClick={handleStopCaptureClick} style={{marginRight:'30px'}}> Parar </Button>) }

                  <Button variant="contained" color="secondary" onClick={handleClose}> Cerrar </Button>
                </div>
            </div>
        </Modal>
        <Modal
            open={openview}
            onClose={handleCloseview}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
              <div className={classes.bodymodal}>
                  <div className={classes.titlemodal}>
                    <h3 style={{fontSize:"2em"}}>Video de recogida recogida </h3>
                  </div>
                  <div style={{  padding: "1.2em"}}>
                    <div style={{marginBottom:'30px'}}>
                      <video width="100%" height="auto" controls>
                        <source src={urlvideo} type="video/webm" />
                        </video>
                    </div>
                    <div>
                      <Button variant="contained" color="secondary" onClick={handleCloseview}> Cerrar </Button>
                    </div>
                </div>
              </div>
          </Modal>



    </>

  );
}
