import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles,drawerWidth } from "../../layout/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import CrudTable from "../../crud/CrudTable"
import {productlaundryService as service, categorylaundryService as categoryService, productService} from "../../../services"
import {isAdmin, web} from '../../utils/Utils'
import Form from "../../form/Form"
import { Link } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import TextField from '@material-ui/core/TextField';

const initFilter =  {
    name : "",
    local:true
};


const useStyles2 = makeStyles({
  bodymodal: {
    margin: "auto",
    width: "500px",
    maxWidth: "calc(100vh - 40px)",
    background: "white",
    padding: "5em",
    marginTop: "100px",
    textAlign: "center"
  }
});


export default function ProductLaundry() {
  const classes = useStyles();
  const classes2 = useStyles2();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(initFilter);
  const [open, setOpen] = useState(true);
  const [rows,setRows] = useState([{}]);
  const [rowsTotal,setRowsTotal] = useState(0);
  const [discountExport,setDiscountExport] = useState(0);
  const [categories,setCategories] = useState([{}]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalExport, setOpenModalExport] = useState(false);
  const [pDelete, setPDelete] = useState(-1);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const createEstructureForm =  [
     {
       type:"input",
       name: "price",
       inputtype:"number",
       step:0.01,
       label: "Precio",
       col: 12
      },
     {
       type:"input",
       name: "salesprice",
       inputtype:"number",
       step:0.01,
       label: "Precio rebajado",
       col: 12
      },
  ];



  const search = (page,size) => {
    setPage(page);
    setRowsPerPage(size);
    service.pagemyFilter(page,size,filter, (data, error) => {
        if(data){
          console.log(data);
          setRows(data.data);
          setRowsTotal(data.total);
        } else {
          setRows([{}]);
          setRowsTotal(0);
        }
    });
  }

  const searchCategories = () => {
    categoryService.search(0,(data, error) => {
        if(data){
          setCategories(data.data);
        } else {
          setCategories([{}]);
        }
    });
  }


  const getUrlImage = function(namefile){
    if(namefile) {
      if (namefile.startsWith("http")) {
        return namefile;
      } else {
        var nameImage =namefile;
        nameImage = nameImage.startsWith("/")?nameImage:"/"+nameImage;
        return process.env.REACT_APP_HOST+'/image'+nameImage;
      }
    } else{
      return '';
    }
  }

  const saveForm = (object) => {
      service.edit(object['id'],object,(data, error) => {
          if(data){

          } else {

          }
      })
  }

  const clickCat = (cat) => {
      if (cat && cat['id'] && cat['id'] > 0) {
        filter['category'] = cat['id'];
      } else {
        filter['category'] = null;
      }

      search(0,200);

  }

  const paintCat = (cat) => {
    if (cat) {
      return <div style={{cursor: "pointer", textAlign:'right', marginBottom: '10px', padding:"1em", display: "inline-block", width:"200px"}} onClick={() => clickCat(cat)}>
              <div style={{border:"1px solid #ccc", borderRadius:"4px", padding:"1em"}}>
                 <div style={{textAlign:'right', marginBottom: '10px', background:'url("'+getUrlImage(cat['imageprin'])+'")', height:"100px", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                 </div>
                  <div style={{textAlign:'center', marginBottom: '10px'}}>
                    {cat['name']}
                </div>
              </div>
          </div>
    } else {
      return <div style={{textAlign:'right', marginBottom: '10px', display: "inline-block", width:"200px"}}>

      </div>
    }

  }

  const paintProduct = (product) => {
    if (product && product['product']) {
      return <div style={{textAlign:'right', marginBottom: '10px', padding:"1em", width: "300px", maxWidth: "50%"}}>
          <Grid container style={{border:"1px solid #ccc", borderRadius:"4px", padding:"1em"}}>
             <Grid item sm={12} style={{textAlign:'right', marginBottom: '10px', height:"200px", maxHeight:"200px", overflow:"hidden"}}>
                <img src={getUrlImage(product['product']['imageprin'])} style={{  width:'100%',  height:'auto',  maxWidth: '100%'}} />
             </Grid>
              <Grid item sm={12} style={{textAlign:'center', marginBottom: '10px'}}>
                  <b>{product['product']['name']}</b>
             </Grid>
              <Grid item sm={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Form objectform={product} fields={createEstructureForm}  searchFilter={saveForm} labelsave={"Guardar"} isHiddenCancel={true} reload={false} />
             </Grid>
             {!product['product']['isBase'] && 
                <Grid item sm={12} style={{textAlign:'right', marginBottom: '10px'}}>
                    <Button component={Link} to={"/product/edit/"+product['product']['id']} variant="contained" color="secondary" style={{marginLeft:'30px', display: "inline-block"}}> Editar </Button>
                    <Button onClick={(evt) => {setPDelete(product['product']['id']); setOpenModal(true);}} variant="contained" color="default" style={{marginLeft:'30px', display: "inline-block"}}> Borrar </Button>
                </Grid>
             }
             {product['product']['isBase'] && !web() && 
                <Grid item sm={12} style={{textAlign:'right', marginBottom: '10px'}}>
                    <Button onClick={(evt) => {setPDelete(product['product']['id']); setOpenModal(true);}} variant="contained" color="default" style={{marginLeft:'30px', display: "inline-block"}}> Borrar </Button>
                </Grid>
             }
          </Grid>
      </div>
    } else {
      return <Grid item sm={3} style={{textAlign:'right', marginBottom: '10px'}}>

      </Grid>
    }

  }



  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCloseExport = () => {
    setOpenModalExport(false);
  };


  const handlerDelConfirm = function(){
    setOpenModal(false);
    deleteHandler();
  }

  const deleteHandler = () => {
    setOpenModal(false);
    productService.removeLogic(pDelete, (data, error) => {
        if(data){
          console.log(data);
            search(page,rowsPerPage);
        }
    });
  }
  
  useEffect(() => {
    search(0,200);
    searchCategories();
  }, []);

  const exportCatalog = (evt) => {
    service.exportCatalog((data,erro) =>{
      toast.notify("Se ha descargado el catalogo", {position:'top', duration: 10000})
    });
  }

  const exportCatalogPercent = (evt) => {
    if(discountExport >= 0 && discountExport < 100){
      setOpenModalExport(false);
      service.exportCatalogPercent(discountExport,(data,erro) =>{
        setDiscountExport(0);
        toast.notify("Se ha descargado el catalogo", {position:'top', duration: 10000})
      });
    } else {
      toast.notify("El porcentaje de descuento debe ser mayor que 0 y menor que 100", {position:'top', duration: 10000})
    }
    
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={"Porductos TPV"} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
           <Grid item sm={12} style={{textAlign:'right', marginBottom: '10px'}}>
              <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={() => setOpenModalExport(true)}> Generar catalogo con descuento</Button>
              <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={exportCatalog}> Generar catalogo </Button>
              <Button component={Link} to={"/product/new"} variant="contained" color="secondary" style={{marginLeft:'30px'}}> Nuevo producto </Button>
            </Grid>
            <Grid item sm={12} style={{textAlign:'left', marginBottom: '10px'}}>
               <h2> Categorias </h2>
            </Grid>
          </Grid>
          <div style={{"display":"block",width:"100%", overflow: "auto hidden", height: "200px", whiteSpace: "nowrap"}}>
            {paintCat({id:-1,name:"Todos", imageprin:"1626417802929.jpeg"})}

            {(categories).map((cat) => (
                paintCat(cat)
            ))}
          </div>
          <Grid container>
            <Grid item sm={12} style={{textAlign:'left', marginBottom: '10px'}}>
               <h2> Productos </h2>
            </Grid>
          </Grid>
          <div style={{display: "flex",maxWidth: "100%", width: "100%", flexWrap: "wrap", flexDirection: "row"}}>
            {(rows).map((row) => (
                paintProduct(row)
            ))}
          </div>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div className={classes2.bodymodal}>
            <div style={{marginBottom:'30px'}}>
                ¿Desear borrar el producto?
            </div>
            <div>
              <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleClose}> No</Button>
              <Button variant="contained" color="primary" onClick={handlerDelConfirm}> Sí </Button>
            </div>
        </div>
        </Modal>
      <Modal
          open={openModalExport}
          onClose={handleCloseExport}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div className={classes2.bodymodal}>
            <div style={{marginBottom:'30px'}}>
                ¿Desear exportar el catalogo con un % de descuento?
            </div>
            <div style={{marginBottom:'30px'}}>
                 <TextField id={"discountPercent"} name={"discountPercent"} label={"% de descuento"} value={discountExport}  onChange={(evt) => setDiscountExport(+evt.target.value)} type={"numer"}  inputProps={{min: "0", max: "100"}}  InputLabelProps={{shrink:true}} />
  
            </div>
            
            <div>
              <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleCloseExport}> No</Button>
              <Button variant="contained" color="primary" onClick={exportCatalogPercent}> Sí </Button>
            </div>
        </div>
        </Modal>
      </main>
    </div>
  );
}
