import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import Button from "@material-ui/core/Button"
import CrudFormTable from "../../crud/CrudFormTable"
import Grid from '@material-ui/core/Grid';
import { useStyles,drawerWidth } from "../../layout/styles";
import CustomPaginationActionsTable from "../../table/Table"
import Filter from "../../filter/Filter"
import Form from "../../form/Form"
import {laundryService as service, productService} from "../../../services"
import {isAdmin,idLaundry,nameLaundry} from '../../utils/Utils'



export default function Tab() {
  let { id } = useParams();
  const classes = useStyles();
  const [idRow, setIdrow] = useState(id);
  const [tab, setTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const estructureformCP =  [
          {
           type:"input",
           name: "postalcode",
           label: "Código Postal",
           col: 4
          }
        ];

    const initElementCP = {
         postalcode:""
      };

    const initElementProduct = {
         product:{id:-1,name:""}
      };

      const estructureformProduct =  [
              {
               type:"autocomplete",
               name: "product.id",
               label: "Producto",
               service: productService.combo,
               col: 4
              }
            ];


    const initElementTimeTable = {
         day:null,
         hourStart: "",
         hourEnd: "",
         allowPickeUp: false,
         allowDelivery: false,
         postalcode: "",
         hold:8
      };

    const estructureformTimeTable =  [
            {
             type:"autocomplete",
             name: "day",
             label: "Día",
             options:[{key:1,name:"Lunes"},{key:2,name:"Martes"},{key:3,name:"Miercoles"},{key:4,name:"Jueves"},{key:5,name:"Viernes"},{key:6,name:"Sabado"},{key:7,name:"Domingo"}],
             col: 4
           },
            {
             type:"input",
             name: "hourStart",
             label: "H. Inicio",
             inputtype:"time",
             col: 4
           },
           {
            type:"input",
            name: "hourEnd",
            label: "H. Fin",
            inputtype:"time",
            col: 4
           },
           {
            type:"checkbox",
            name: "allowPickeUp",
            label: "Recogida",
            col: 2
           },
           {
            type:"checkbox",
            name: "allowDelivery",
            label: "Entrega",
            col: 2
          },
           {
            type:"autocomplete",
            name: "postalcode",
            label: "Código postal",
            col: 4,
            servicefunc: (field,setOptions) => {
              service.searchcpcombo(id,(data, error) => {
                if(data && data.data){
                  setOptions(currentOptions => ({...currentOptions, [field.name]: data.data }));
                }
              });
            }
           },
            {
             type:"input",
             name: "hold",
             label: "Huecos",
             col: 4
            },

          ];



  return (
     <>
        { idRow && idRow > 0 && <><Grid item sm={12} xs={12} style={{textAlign:'left'}}>
          <Button variant="outlined" color="primary" className={tab == 0 ? classes.tabselected: classes.tabnotselected } onClick={(event) => handleChangeTab(event,0)}>  C.P Asignados </Button>
          <Button variant="outlined" color="primary" className={tab == 1 ? classes.tabselected: classes.tabnotselected } onClick={(event) => handleChangeTab(event,1)}>  Productos </Button>
          <Button variant="outlined" color="primary" className={tab == 2 ? classes.tabselected: classes.tabnotselected } onClick={(event) => handleChangeTab(event,2)}> Horario </Button>
        </Grid>
        <Grid item sm={12} xs={12} style={{textAlign:'left'}}>
            <div  hidden={tab !== 0}><CrudFormTable
                  servicesearch={service.searchcp}
                  serviceremove={service.removecp}
                  servicecreate={service.assigncp}
                  hiddenfilter={!isAdmin()}
                  deleteaction={isAdmin()}
                  filter={initElementCP}
                  idrel={idRow}
                  fieldstable={["postalcode"]}
                  headersTable={["C.P. Asignados"]}
                  estructureForm={estructureformCP}
                  labeltitle={"Asignar nuevo C.P."} /> </div>
            <div  hidden={tab !== 1}><CrudFormTable
                  servicesearch={service.searchproduct}
                  serviceremove={service.removeproduct}
                  servicecreate={service.assignproduct}
                  filter={initElementProduct}
                  idrel={idRow}
                  fieldstable={["imageprin","name","shortDescription","price","priceToSuscription","isSuscription","allowIncludeInSuscription","mountForMonth","category.name"]}
                  headersTable={["#","Nombre","Descripcion", "Precio", "Precio Suscripción","Es suscripción","Permitir Añadir a Sus.","Veces por mes","Categoría"]}
                  estructureForm={estructureformProduct}
                  labeltitle={"Asignar Producto"} />  </div>
            <div  hidden={tab !== 2}><CrudFormTable
                  servicesearch={service.searchtimetable}
                  serviceremove={service.removetimetable}
                  servicecreate={service.assigntimetable}
                  filter={initElementTimeTable}
                  idrel={idRow}
                  fieldstable={["day","hourStart","hourEnd","allowPickeUp","allowDelivery","postalcode","hold"]}
                  headersTable={["Día Sem.","H. Inicio","H. Fin", "Recogida", "Entrega", "C.P","Huecos"]}
                  estructureForm={estructureformTimeTable}
                  labeltitle={"Asignar Horario"} />  </div>
        </Grid>
    </> }
    </>


  );
}
