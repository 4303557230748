import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CrudForm from '../../crud/CrudForm';
import Button from '@material-ui/core/Button';
import { billService as service, laundryService, clientService, productlaundryService as productService, streetService, printService, paymenttypeService } from '../../../services';
import { isAdmin, idLaundry, nameLaundry } from '../../utils/Utils';
import ModalClient from '../../client/ModalClient';

const moment = require('moment');

const initline = {
      id: -1,
      product: { id: null, name: '' },
      amount: 0.0,
      price: 0.0,
      total: 0.0,
      base: 0.0,
      discount: 0.0,
      discountPercent: 0.0,
};

const initElement = {
      client: { id: null, name: '' },
      laundry: { id: isAdmin() ? null : idLaundry(), name: isAdmin() ? '' : nameLaundry() },
      numberBill: null,
      nameClient: '',
      emailClient: '',
      serie: '',
      nif: '',
      total: 0.0,
      base: 0.0,
      iva: 0.0,
      discountPercent: 0.0,
      discount: 0.0,
      isDraft: true,
      lines: [],
      location: {
            id: null,
            name: '',
            street: '',
            phone: '',
            number: '',
            door: '',
            floor: '',
            stairs: '',
            city: '',
            country: 'España',
            postalcode: '',
            latitude: '',
            longitude: '',
      },
};

export default function FormBill() {
      const [objectform, setObjectform] = React.useState({ ...initElement });
      const [numberBill, setNumberBill] = React.useState('');
      const [reload, setReload] = useState(false);
      const history = useHistory();
      let { id } = useParams();
      const [focesave, setFocesave] = useState(false);

      const setValueClient = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            if (value) {
                  var newobject = objectform;

                  clientService.one(value.key, (data, error) => {
                        var user = data.data;

                        newobject = setValueField(newobject, 'client', user);
                        newobject = setValueField(newobject, 'nameClient', user.name);
                        newobject = setValueField(newobject, 'emailClient', user.email);
                        newobject = setValueField(newobject, 'nif', user.nif);
                        if (user.paymentType && user.paymentType.id) {
                              newobject = setValueField(newobject, 'paymentType', user.paymentType);
                              setValueform((currentValueform) => ({ ...currentValueform, ['paymentType.id']: { key: user.paymentType.id, name: user.paymentType.name } }));
                        }
                        if (user.percentDiscount) {
                              newobject = setValueField(newobject, 'discountPercent', user.percentDiscount);
                              setValueform((currentValueform) => ({ ...currentValueform, ['discountPercent']: user.percentDiscount }));
                        }

                        setValueform((currentValueform) => ({ ...currentValueform, ['nameClient']: user.name + ' ' + (user.lastname ? user.lastname : '') }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['nif']: user.nif ? user.nif : '' }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['emailClient']: user.email ? user.email : '' }));

                        setValueform((currentValueform) => ({ ...currentValueform, ['location.street']: user.streetDefault.street }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['location.postalcode']: user.streetDefault.postalcode }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['location.country']: user.streetDefault.country }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['location.latitude']: user.streetDefault.latitude }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['location.longitude']: user.streetDefault.longitude }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['location.city']: user.streetDefault.city }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['location.number']: user.streetDefault.number }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['location.floor']: user.streetDefault.floor }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['location.stairs']: user.streetDefault.stairs }));
                        setValueform((currentValueform) => ({ ...currentValueform, ['location.door']: user.streetDefault.door }));

                        newobject = setValueField(objectform, 'location.street', user.streetDefault.street);
                        newobject = setValueField(newobject, 'location.postalcode', user.streetDefault.postalcode);
                        newobject = setValueField(newobject, 'location.country', user.streetDefault.country);
                        newobject = setValueField(newobject, 'location.latitude', user.streetDefault.latitude);
                        newobject = setValueField(newobject, 'location.longitude', user.streetDefault.longitude);
                        newobject = setValueField(newobject, 'location.city', user.streetDefault.city);
                        newobject = setValueField(newobject, 'location.number', user.streetDefault.number);
                        newobject = setValueField(newobject, 'location.floor', user.streetDefault.floor);
                        newobject = setValueField(newobject, 'location.stairs', user.streetDefault.stairs);
                        newobject = setValueField(newobject, 'location.door', user.streetDefault.door);
                  });

                  return newobject;
            } else {
                  return objectform;
            }
      };

      const setValueRow = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            if (aux && aux['other']) {
                  var newobject = setValueField(objectform, 'amount', 1.0);
                  newobject = setValueField(newobject, 'name', aux.name);
                  newobject = setValueField(newobject, 'price', +aux.other);
                  newobject = setValueField(newobject, 'discountPercent', 0.0);
                  newobject = setValueField(newobject, 'discount', 0.0);
                  newobject = setValueField(newobject, 'base', (aux.other / 1.21).toFixed(2));
                  newobject = setValueField(newobject, 'total', aux.other);

                  var rv = valueform[id];
                  rv['name'] = aux.name;
                  rv['amount'] = newobject['amount'];
                  rv['price'] = newobject['price'];
                  rv['discountPercent'] = newobject['discountPercent'];
                  rv['discount'] = newobject['discount'];
                  rv['base'] = newobject['base'];
                  rv['total'] = newobject['total'];
                  setValueform((currentValueform) => ({ ...currentValueform, [id]: rv }));

                  return newobject;
            } else {
                  return objectform;
            }
      };

      const setValueAmout = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            var rv = valueform[id];

            var ammount = +value;
            var price = rv['price'];
            var discper = rv['discountPercent'];

            var dis = price * (discper / 100.0) * ammount;
            var total = price * ammount - dis;
            var base = total / 1.21;

            var newobject = setValueField(objectform, 'discount', dis);
            newobject = setValueField(newobject, 'total', total);

            rv['discount'] = newobject['discount'].toFixed(2);
            rv['total'] = newobject['total'].toFixed(2);
            setValueform((currentValueform) => ({ ...currentValueform, [id]: rv }));

            return newobject;
      };

      const setValuePrice = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            var rv = valueform[id];

            var ammount = rv['amount'];
            var price = +value;
            var discper = rv['discountPercent'];

            var dis = price * (discper / 100.0) * ammount;
            var total = price * ammount - dis;
            var base = total / 1.21;

            var newobject = setValueField(objectform, 'discount', dis);
            newobject = setValueField(newobject, 'total', total);

            rv['discount'] = newobject['discount'].toFixed(2);
            rv['total'] = newobject['total'].toFixed(2);
            setValueform((currentValueform) => ({ ...currentValueform, [id]: rv }));

            return newobject;
      };

      const setValueDiscount = (setValueField, objectform, value, valueform, setValueform, id, aux) => {
            var rv = valueform[id];

            var ammount = rv['amount'];
            var price = rv['price'];
            var discper = +value;

            var dis = price * (discper / 100.0) * ammount;
            var total = price * ammount - dis;
            var base = total / 1.21;

            var newobject = setValueField(objectform, 'discount', dis);
            newobject = setValueField(newobject, 'total', total);

            rv['discount'] = newobject['discount'].toFixed(2);
            rv['total'] = newobject['total'].toFixed(2);
            setValueform((currentValueform) => ({ ...currentValueform, [id]: rv }));

            return newobject;
      };

      const updateRows = (setValueField, objectform, rows, setValueform) => {
            var totalLines = 0.0;

            for (let row of rows) {
                  if (row['total']) {
                        totalLines = totalLines + +row['total'];
                  }
            }

            var base = totalLines / 1.21;
            var disper = +objectform['discountPercent'];
            var dis = (base * (disper / 100.0)).toFixed(2);
            base = base - base * (disper / 100.0);
            var total = (base * 1.21).toFixed(2);
            var iva = (base * 0.21).toFixed(2);
            var baseS = base.toFixed(2);

            setValueform((currentValueform) => ({ ...currentValueform, ['total']: total }));
            setValueform((currentValueform) => ({ ...currentValueform, ['discount']: dis }));
            setValueform((currentValueform) => ({ ...currentValueform, ['base']: baseS }));
            setValueform((currentValueform) => ({ ...currentValueform, ['iva']: iva }));
            var newobject = setValueField(objectform, 'total', total);
            newobject = setValueField(newobject, 'discount', dis);
            newobject = setValueField(newobject, 'base', baseS);
            newobject = setValueField(newobject, 'iva', iva);
            return newobject;
      };

      const updateDiscountTotal = (setValueField, objectform, value, valueform, setValueform) => {
            var totalLines = 0.0;

            for (let row of objectform['lines']) {
                  if (row['total']) {
                        totalLines = totalLines + +row['total'];
                  }
            }

            var base = totalLines / 1.21;
            var disper = +objectform['discountPercent'];
            var dis = (base * (disper / 100.0)).toFixed(2);
            base = base - base * (disper / 100.0);
            var total = (base * 1.21).toFixed(2);
            var iva = (base * 0.21).toFixed(2);
            var baseS = base.toFixed(2);

            setValueform((currentValueform) => ({ ...currentValueform, ['total']: total }));
            setValueform((currentValueform) => ({ ...currentValueform, ['discount']: dis }));
            setValueform((currentValueform) => ({ ...currentValueform, ['base']: baseS }));
            setValueform((currentValueform) => ({ ...currentValueform, ['iva']: iva }));
            var newobject = setValueField(objectform, 'total', total);
            newobject = setValueField(newobject, 'discount', dis);
            newobject = setValueField(newobject, 'base', baseS);
            newobject = setValueField(newobject, 'iva', iva);
            return newobject;
      };

      const estructureformline = [
            {
                  type: 'input',
                  name: 'name',
                  label: 'Linea',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'product',
                  label: 'Producto',
                  service: productService.comboWithCategory,
                  colSpan: '30%',
                  setvalue: setValueRow,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'amount',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Cantidad',
                  setvalue: setValueAmout,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'price',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Precio (Con IVA)',
                  setvalue: setValuePrice,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'discountPercent',
                  inputtype: 'number',
                  step: 0.01,
                  label: '% Decuento',
                  setvalue: setValueDiscount,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'discount',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Decuento',
                  readonly: true,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'total',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Total (Con IVA)',
                  readonly: true,
                  col: 3,
            },
      ];

      const estructureform = [
            {
                  type: 'title',
                  name: 'titledata',
                  label: 'Datos Factura',
                  col: 12,
            },
            {
                  type: 'autocomplete',
                  name: 'client.id',
                  label: 'Cliente',
                  service: clientService.combo,
                  setvalue: setValueClient,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'serie',
                  label: 'Serie',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'numberBill',
                  label: 'Nº Factura',
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'date',
                  label: 'Fecha',
                  inputtype: 'date',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'nif',
                  label: 'CIF/NIF',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'nameClient',
                  label: 'Nombre',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'emailClient',
                  label: 'Email',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'paymentType.id',
                  label: 'Forma de pago',
                  service: paymenttypeService.combo,
                  col: 4,
            },
            {
                  type: 'title',
                  name: 'titlestreet',
                  label: 'Dirección',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'location.street',
                  label: 'Calle',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'location.number',
                  label: 'Nº',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'location.stairs',
                  label: 'Escl.',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'location.floor',
                  label: 'Piso',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'location.door',
                  label: 'Puerta',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'location.city',
                  label: 'Ciudad',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'location.postalcode',
                  label: 'C.P',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'location.phone',
                  label: 'Tlf.',
                  col: 4,
            },
            {
                  type: 'title',
                  name: 'titlelineas',
                  label: 'Productos',
                  col: 12,
            },
            {
                  type: 'table',
                  name: 'lines',
                  label: 'Lineas',
                  headers: [
                        { label: 'Nombre', colSpan: '20%' },
                        { label: 'Producto', colSpan: '20%' },
                        { label: 'Cantidad', colSpan: '5%' },
                        { label: 'Precio (Con IVA)', colSpan: '10%' },
                        { label: '% Desc', colSpan: '5%' },
                        { label: 'Descuento', colSpan: '10%' },
                        { label: 'Total (con IVA)', colSpan: '10%' },
                  ],
                  initrow: { ...initline },
                  fields: estructureformline,
                  fieldId: 'id',
                  setvalue: updateRows,
                  col: 12,
            },
            {
                  type: 'title',
                  name: 'titletotal',
                  label: 'Total',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'discountPercent',
                  inputtype: 'number',
                  step: 0.01,
                  label: '% Decuento',
                  setvalue: updateDiscountTotal,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'discount',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Descuento (sobre el total sin IVA)',
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'base',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Base Imponible',
                  readonly: true,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'iva',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Iva',
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'total',
                  inputtype: 'number',
                  step: 0.01,
                  label: 'Total',
                  readonly: true,
                  col: 3,
            },
      ];

      const recoveryElement = function (objectedit, element) {
            objectedit['name'] = element.name ? element.name : '';
            objectedit['price'] = element.price ? element.price : 0.0;
            objectedit['priceToSuscription'] = element.priceToSuscription ? element.priceToSuscription : 0.0;
            objectedit['iva'] = element.iva ? element.iva.toFixed(2) : 0.0;
            objectedit['total'] = element.total ? element.total.toFixed(2) : 0.0;
            objectedit['base'] = element.base ? element.base.toFixed(2) : 0.0;
            objectedit['nif'] = element.nif ? element.nif : '';
            objectedit['nameClient'] = element.nameClient ? element.nameClient : '';
            objectedit['emailClient'] = element.emailClient ? element.emailClient : '';

            objectedit['client'] = { id: element.client && element.client.id ? element.client.id : null, name: element.client && element.client.name ? element.client.name : null };
            objectedit['laundry'] = { id: element.laundry && element.laundry.id ? element.laundry.id : null, name: element.laundry && element.laundry.name ? element.laundry.name : null };
            objectedit['stateOrder'] = { id: element.stateOrder && element.stateOrder.id ? element.stateOrder.id : null, name: element.stateOrder && element.stateOrder.name ? element.stateOrder.name : null };
            objectedit['paymentType'] = { id: element.paymentType && element.paymentType.id ? element.paymentType.id : null, name: element.paymentType && element.paymentType.name ? element.paymentType.name : null };
            objectedit['lines'] = element.lines ? element.lines : [];
            objectedit['location'] = element.location
                  ? element.location
                  : {
                          id: null,
                          name: '',
                          street: '',
                          phone: '',
                          number: '',
                          door: '',
                          floor: '',
                          stairs: '',
                          city: '',
                          country: 'España',
                          postalcode: '',
                          latitude: '',
                          longitude: '',
                    };
            objectedit['numberBill'] = element.numberBill ? element.numberBill : null;
            objectedit['serie'] = element.serie ? element.serie : null;

            return objectedit;
      };

      const send = () => {
            service.send(id, (data, erro) => {
                  toast.notify('Se ha enviado al cliente corrcetamente', { position: 'top', duration: 10000 });
            });
      };

      const sendAsesor = () => {
            service.sendAsesor(id, (data, erro) => {
                  toast.notify('Se ha enviado al asesor corrcetamente', { position: 'top', duration: 10000 });
            });
      };
      const print = () => {
            service.print(id, 'Factura' + id + '.pdf', (data, erro) => {
                  toast.notify('Se ha descargado la factura', { position: 'top', duration: 10000 });
            });
      };

      const rectificative = () => {
            service.rectificative(id, (data, erro) => {
                  if (data && data.data && data.data.id) {
                        history.push('/bill/edit/' + data.data.id);
                        location.reload();
                  } else {
                        toast.notify('Ha ocurrido un error al generar la factura rectificativa', { position: 'top', duration: 10000 });
                  }
            });
      };

      const handlePrintTicker = (bill) => {
            printService.bill(bill, (data, error) => {});
      };

      const saveAndGenerate = (bill) => {
            bill.isDraft = false;
            setFocesave(true);
      };

      const moreActions = (getObject) => {
            return (
                  <>
                        {id && getObject().numberBill && getObject().numberBill != '' && !getObject().isDraft && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={send}>
                                    {' '}
                                    Enviar al cliente
                              </Button>
                        )}
                        {id && getObject().numberBill && getObject().numberBill != '' && !getObject().isDraft && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={sendAsesor}>
                                    {' '}
                                    Enviar al asesor
                              </Button>
                        )}
                        {id && getObject().numberBill && getObject().numberBill != '' && !getObject().isDraft && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={print}>
                                    {' '}
                                    Descargar Factura{' '}
                              </Button>
                        )}
                        {id && getObject().numberBill && getObject().numberBill != '' && !getObject().isDraft && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={() => handlePrintTicker(getObject())}>
                                    {' '}
                                    Imprimir F.simpli.
                              </Button>
                        )}
                        {id && getObject().numberBill && getObject().numberBill != '' && !getObject().isDraft && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={rectificative}>
                                    {' '}
                                    Generar rectificativa{' '}
                              </Button>
                        )}
                        {id && getObject().isDraft && <span>No se puede enviar al cliente, ni al asesor, ni imprimir hasta que se genere, para generarlo debes desmarcar el check de "Es borrador" </span>}
                        {getObject().isDraft && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={() => saveAndGenerate(getObject())}>
                                    {' '}
                                    Guardar y generar
                              </Button>
                        )}
                  </>
            );
      };

      const isValid = function (object) {
            if (!object['nameClient']) {
                  toast.notify('Debe indicar el cliente nombre del cliente', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['location'] || !object['location']['street'] || object['location']['street'] == '') {
                  toast.notify('Debe indicar la calle', { position: 'top', duration: 10000 });
                  return false;
            }
            if (!object['lines'] || object['lines'].length == 0) {
                  toast.notify('Debe añadir algun producto', { position: 'top', duration: 10000 });
                  return false;
            }
            /*for (let r of object['lines'] ){
        if(!r['amount'] || r['amount']== 0){
          toast.notify("Todas las lineas deben tener una cantidad distinta de 0", {position:'top', duration: 10000})
          return false;
        }
      }*/
            return true;
      };

      const checkIsReadOnly = function (object) {
            return !object['isDraft'];
      };

      const haveOtherTop = function () {
            return !id || id <= 0;
      };

      const saveClient = (object) => {
            debugger;
            var aux = [];
            aux['client'] = object;
            aux['nameClient'] = object.name;
            aux['emailClient'] = object.email;
            aux['nif'] = object.nif;
            aux['location'] = object.streetDefault;
            setObjectform((currentValueform) => ({ ...currentValueform, ...aux }));
      };

      return (
            <CrudForm
                  objectform={objectform}
                  estructureform={estructureform}
                  titleEdit={'Editar factura'}
                  titleNew={'Nueva factura'}
                  urledit={'/bill/edit/'}
                  urlCancel={'/bill'}
                  service={service}
                  recoveryElement={recoveryElement}
                  valid={isValid}
                  moreActions={moreActions}
                  checkIsReadOnly={checkIsReadOnly}
                  haveotherTop={haveOtherTop}
                  reload={reload}
                  othersformTop={<ModalClient saveClient={saveClient} />}
                  forcesave={focesave}
            />
      );
}
