import React, { useState, useEffect }  from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {cashoutService as service} from "../../../services"
import {isAdmin} from '../../utils/Utils'

const initFilter =  {
    from:null,
    to : null,
    idLaundry: null
};


export default function Cash() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "from",
      label: "Desde",
      inputtype:"date",
      col: 4
     },
     {
       type:"input",
       name: "to",
       label: "Hasta",
       inputtype:"date",
       col: 4
      },
  ];



  return (
    <CrudTable
          service={service}
          titlePage={"Retirada de efectivo"}
          titleButton={"Retirar dinero"}
          urledit={"/cashout/edit/"}
          urlnew={"/cashout/new/"}
          filter={initFilter}
          fieldstable={["date","name","effective"]}
          headersTable={["Día", "Motivo","Cantidad"]}
          estructureForm={createEstructureFilter}
      />
  );
}
