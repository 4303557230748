import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {productService as service, laundryService, categoryService} from "../../../services"
import {isAdmin} from '../../utils/Utils'

const initFilter =  {
    name : "",
    web:isAdmin()?null:true
};


export default function Product() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
     {
       type:"input",
       name: "fromPrice",
       inputtype:"number",
       step:0.01,
       label: "Precio Desde",
       col: 4
      },
     {
       type:"input",
       name: "toPrice",
       inputtype:"number",
       step:0.01,
       label: "Precio hasta",
       col: 4
      },
      {
        type:"autocomplete",
        name: "category",
        label: "Categoria",
        service: categoryService.combo,
        col: 4
       },
       {
         type:"autocomplete",
         name: "laundry",
         label: "Lavandería",
         service: laundryService.combo,
         col: 4
        },
        {
          type:"checkbox",
          name: "isSuscription",
          label: "Es suscripción",
          col: 4
         },
         {
           type:"checkbox",
           name: "allowSuscription",
           label: "Permitido en suscripcióm",
           col: 4
          },
  ];



  return (
    <CrudTable
          service={service}
          titlePage={"Productos"}
          titleButton={"Nuevo producto"}
          urledit={"/product/edit/"}
          urlnew={"/product/new/"}
          hiddenButton={!isAdmin()}
          editable={isAdmin()}
          filter={initFilter}
          deleteaction={isAdmin()}
          fieldstable={["imageprin","name","shortDescription","price","priceSuscriber","priceToSuscription","isSuscription","allowIncludeInSuscription","mountForMonth","category.name"]}
          headersTable={["#","Nombre","Descripcion", "Precio","Precio para Abonado", "Precio Suscripción","Es suscripción","Permitir Añadir a Sus.","Veces por mes","Categoría"]}
          estructureForm={createEstructureFilter}
      />
  );
}
