import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {alertAvailableLaundryService as service} from "../../../services"

const initFilter =  {
    postalcode : "",
};


export default function AlertAvailable() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "postalcode",
       label: "Código Postal",
       col: 4
      },
  ];




  return (
    <CrudTable
          service={service}
          titlePage={"Códigos postales solicitados"}
          titleButton={"Nuevo código postal"}
          urledit={"/alertavailable/edit/"}
          urlnew={"/alertavailable/new/"}
          filter={initFilter}
          fieldstable={["postalcode","client.name", "client.email"]}
          headersTable={["Código Postal","Nombre","Email"]}
          estructureForm={createEstructureFilter}
      />
  );
}
