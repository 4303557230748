import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from '../layout/Header';
import Menu from '../layout/Menu';
import Copyright from '../layout/Copyright';
import { useStyles,drawerWidth } from "../layout/styles";
import Form from "../form/Form"
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';


export default function Layout(props) {
  const classes = useStyles();


  const [titleWindow, setTitleWindow] = useState(props.titleWindow);


  const [open, setOpen] =useState(!localStorage.getItem("openmenu") || localStorage.getItem("openmenu") == "true");


  const handleDrawerOpen = () => {
    localStorage.setItem("openmenu","true");
    setOpen(true);
  };
  const handleDrawerClose = () => {
    localStorage.setItem("openmenu","false");
    setOpen(false);
  };



  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={titleWindow} classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <Menu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.bodycontainer} style={{alignContent: "flex-start"}}>
              {props.children}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
