import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {categoryService as service} from "../../../services"

const initElement = {
     name:"",
     shortDescription:"",
     imageprin:null,
  };

export default function FormCategory() {
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
          {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 6
          },
          {
           type:"textarea",
           name: "shortDescription",
           label: "Descripción",
           col: 6
          },
          {
           type:"image",
           name: "imageprin",
           label: "Imagen",
           col: 6
          },
    ];


  const recoveryElement = function(objectedit,element){


    objectedit['name']=element.name?element.name:"";
    objectedit['shortDescription']=element.shortDescription?element.shortDescription:"";
    objectedit['imageprin']=element.imageprin?element.imageprin:null;

    return objectedit;
  }


  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        titleEdit = {"Editar categoria"}
        titleNew = {"Nueva categoria"}
        urledit={"/category/edit/"}
        urlCancel={"/category"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
