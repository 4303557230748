import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {configprintService as service, laundryService, categoryService} from "../../../services"
import {isAdmin} from '../../utils/Utils'

const initElement = {
     path:"",
     version: 0,
  };


export default function FormPrintConfig() {
  const [objectform, setObjectform] = React.useState({...initElement});


  const estructureform =  [
          {
           type:"input",
           name: "version",
           label: "Versión",
           inputtype:"number",
           step: 1,
           col: 4
          },
         {
           type:"file",
           name: "path",
           label: "JAR",
           col: 4
          },
    ];



  const recoveryElement = function(objectedit,element){


    objectedit['path']=element.path?element.path:"";
    objectedit['version']=element.version?element.version:0;

    return objectedit;
  }



  const isValid = function(object){
      if(!object['version']){
        toast.notify("Debe indicar la version", {position:'top', duration: 10000})
        return false;
      }
      if(!object['path'] ){
        toast.notify("Debe subir el jar", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        titleEdit = {"Subir JAR Print Service"}
        titleNew = {"Subir JAR Print Service"}
        urledit={"/product/edit/"}
        urlCancel={"/printservice/edit/1"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
