import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../crud/CrudForm"
import {clientService as service, paymenttypeService} from "../../../services"
import {isAdmin,idLaundry,nameLaundry} from '../../utils/Utils'
import Streets from './Streets'

const initElement = {
     email:"",
     name: "",
     lastname: "",
     enable: true,
     laundry:{id:null,name:""},
     picture:null,
     web:false,
     percentDiscount:null,
     paymentType:{id:null,name:""},
     streetDefault:{
      id:null,
      name:"",
      street:"",
      phone:"",
      number:"",
      door:"",
      floor:"",
      stairs:"",
      city:"",
      country:"España",
      postalcode:"",
      latitude:"",
      longitude:"",
     }

  };


export default function FormClient() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams();
  const [idRow, setIdrow] = useState(id);


  const estructureform =  [
          {
          type:"input",
          name: "nif",
          label: "DNI/CIF",
          col: 4
          },
          {
          type:"input",
          name: "name",
          label: "Nombre",
          col: 4
          },
          {
          type:"input",
          name: "lastname",
          label: "Apellidos",
          col: 4
          },
          {
          type:"input",
          name: "phone",
          label: "Teléfono",
          col: 4
          },
          {
          type:"input",
          name: "email",
          label: "Email",
          col: 4
          },
          {
          type:"input",
          name: "percentDiscount",
          label: "Descuento por defecto",
          inputtype: "number",
          step:0.01,
          col: 4
          },
          {
            type:"autocomplete",
            name: "paymentType.id",
            label: "Forma de pago",
            service:paymenttypeService.combo,
            col: 4
           },
           {
            type:"title",
            name: "streettitle",
            label: "Dirección",
            col: 12
           },
           {
             type:"input",
             name: "streetDefault.street",
             label: "Calle",
             col: 8
            },
           {
             type:"input",
             name: "streetDefault.number",
             label: "Nº",
             col: 2
            },
           {
             type:"input",
             name: "streetDefault.stairs",
             label: "Escl.",
             col: 2
            },
           {
             type:"input",
             name: "streetDefault.floor",
             label: "Piso",
             col: 2
            },
           {
             type:"input",
             name: "streetDefault.door",
             label: "Puerta",
             col: 2
            },
           {
             type:"input",
             name: "streetDefault.city",
             label: "Ciudad",
             col: 4
            },
           {
             type:"input",
             name: "streetDefault.postalcode",
             label: "C.P",
             col: 4
            }
    ];


  const recoveryElement = function(objectedit,element){


    objectedit['nif']=element.nif?element.nif:"";
    objectedit['email']=element.email?element.email:"";
    objectedit['name']=element.name?element.name:"";
    objectedit['lastname']=element.lastname?element.lastname:"";
    objectedit['laundry']={id:element.laundry && element.laundry.id ?element.laundry.id:null, name: element.laundry && element.laundry.name?element.laundry.name:null};
    objectedit['paymentType']={id:element.paymentType && element.paymentType.id?element.paymentType.id:null, name: element.paymentType && element.paymentType.name?element.paymentType.name:null};
    objectedit['percentDiscount']=element.percentDiscount?element.percentDiscount:null;
    objectedit['picture']=element.picture?element.picture:"";

    return objectedit;
  }




  const isValid = function(object){
      if(!object['name']){
        toast.notify("Debe indicar el nombre", {position:'top', duration: 10000})
        return false;
      }

      return true;
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        titleEdit = {"Editar Cliente"}
        titleNew = {"Nuevo Cliente"}
        urledit={"/client/edit/"}
        urlCancel={"/client"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        haveother = {true}
      />

  );
}
