import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import { useStyles,drawerWidth } from "../../layout/styles";
import {isAdmin,idLaundry,nameLaundry} from '../../utils/Utils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Layout from '../../layout/Layout';

const moment = require("moment");

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import {cashService as service} from "../../../services"

function getWeekNumber(currentdate) {
  return moment(currentdate).isoWeek();
}


const initElementFilter = {
     laundry:{id:isAdmin()?null:idLaundry(),name:isAdmin()?"":nameLaundry()},
     date:moment(new Date()).format("YYYY-MM-DD") + "T00:00:00Z",
     hourFrom: "00:00",
     hourTo: "23:59",

  };

export default function Statistics() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [sday, setSday] = React.useState(moment(new Date()).format("DD/MM/YYYY"));
  const [w1Day, setW1Day] = React.useState(getMonday(new Date()));
  const [w7Day, setW7Day] = React.useState(getSunday(new Date()));
  const [wday, setWday] = React.useState(moment(w1Day).format("DD/MM/YYYY") + " - " + moment(w7Day).format("DD/MM/YYYY"))  ;
  const [filter,setFilter] = React.useState({...initElementFilter});
  const [open, setOpen] = React.useState(true);
  const [gday,setGday] = React.useState([
      {
        name: "Total",
        total: 0,
      },
      {
        name: "Web",
        total: 0,
      },
      {
        name: "Local Efc.",
        total: 0,
      },
      {
        name: "Local Tar.",
        total: 0,
      },
  ]);
  const [gweek,setGweek] = React.useState([
    {
      name: moment(w1Day).format("DD/MM/YYYY"),
      total: 0,
      web:0,
      efec:0,
      tar:0
    },
    {
      name: moment(w1Day).add('days',1).format("DD/MM/YYYY"),
      total: 0,
      web:0,
      efec:0,
      tar:0
    },
    {
      name: moment(w1Day).add('days',2).format("DD/MM/YYYY"),
      total: 0,
      web:0,
      efec:0,
      tar:0
    },
    {
      name: moment(w1Day).add('days',3).format("DD/MM/YYYY"),
      total: 0,
      web:0,
      efec:0,
      tar:0
    },
    {
      name: moment(w1Day).add('days',4).format("DD/MM/YYYY"),
      total: 0,
      web:0,
      efec:0,
      tar:0
    },
    {
      name: moment(w1Day).add('days',5).format("DD/MM/YYYY"),
      total: 0,
      web:0,
      efec:0,
      tar:0
    },
    {
      name: moment(w1Day).add('days',6).format("DD/MM/YYYY"),
      total: 0,
      web:0,
      efec:0,
      tar:0
    },
]);
const [dday,setDday] = React.useState({
  total:0,
  web:0,
  efec:0,
  card:0
})
const [dweek,setDweek] = React.useState({
  total:0,
  web:0,
  efec:0,
  card:0
})

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:0); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  function getSunday(d) {
    d = new Date(d);
    var day = d.getDay(),
    diff = d.getDate() + (6-day); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }



  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };



  const searchData = (filter) => {
      service.cashdata(filter,(data, error) => {
        if(data && data.data){

    
          const dg = [
              {
                name: "Total",
                total: data.data.card + data.data.web + data.data.effective,
              },
              {
                name: "Web",
                total: data.data.web,
              },
              {
                name: "Local Efc.",
                total: data.data.effective,
              },
              {
                name: "Local Tar.",
                total: data.data.card,
              },
          ]

          setGday(dg);

          var dnewday = {...dday}
          dnewday.total = ((data.data.card ? data.data.card: 0)  + (data.data.web ? data.data.web: 0) + (data.data.effective ? data.data.effective : 0));
          dnewday.web = (data.data.web ? data.data.web: 0);
          dnewday.efec = (data.data.effective ? data.data.effective: 0);
          dnewday.card = (data.data.card ? data.data.card: 0);
          setDday(dnewday);
        }
    });
  }

  const searchWeek = (week,day1) => {

    service.cashdataweek(week,(data, error) => {
      if(data && data.data){

        var total = 0;
        var web = 0;
        var efect = 0;
        var tarj = 0;
        var week: any[] = [];

        var pos = 0;
        data.data.forEach(element => {
          const day = {
            name: moment(day1).add('days',pos).format("DD/MM/YYYY"),
            total: element.effective + element.card + element.web,
            web:element.web,
            efec:element.effective,
            tar:element.card
          };
          
          total = total + day.total;
          web = web + day.web;
          efect = efect + day.efec;
          tarj = tarj + day.tar;

          week.push(day);
          pos++;
        });

        setGweek(week);

        var dnewday = {...dday}
        dnewday.total = total;
        dnewday.web = web;
        dnewday.efec = efect;
        dnewday.card = tarj;

        setDweek(dnewday);
      }
  });
}

  useEffect(() => {
      searchData(filter);
      searchWeek(getWeekNumber(w1Day),w1Day);
  }, []);


  const prev = () => {
      var d = moment(Date.parse(filter['date'])).add('days',-1);
     setSday(d.format("DD/MM/YYYY"))
     filter['date'] = d.format("YYYY-MM-DD")+"T00:00:00Z";
     setFilter(filter);
     searchData(filter);
  }

  const next = () => {
      var d = moment(Date.parse(filter['date'])).add('days',1);
     setSday(d.format("DD/MM/YYYY"))
     filter['date'] = d.format("YYYY-MM-DD")+"T00:00:00Z";
     setFilter(filter);
     searchData(filter);
  }

  const prevW = () => {
    var monday = moment(w1Day).add('days',-7).toDate();
    var sunday = moment(w7Day).add('days',-7).toDate();
    setW1Day(monday);
    setW7Day(sunday);
    setWday(moment(monday).format("DD/MM/YYYY") + " - " + moment(sunday).format("DD/MM/YYYY"));

    searchWeek(getWeekNumber(monday),monday);
}

const nextW = () => {
  var monday = moment(w1Day).add('days',7).toDate();
  var sunday = moment(w7Day).add('days',7).toDate();
  setW1Day(monday);
  setW7Day(sunday);
  setWday(moment(monday).format("DD/MM/YYYY") + " - " + moment(sunday).format("DD/MM/YYYY"));

  searchWeek(getWeekNumber(monday),monday);
}


  return (
    <Layout titleWindow={"Estadísticas"}>
        <>
        
        <Grid container spacing={3} style={{paddingTop:"2em"}}>
                <Grid item sm={12} xs={12} style={{textAlign:"center"}}>
                    <Typography component="h2" variant="h3"> Ventas diarias </Typography>
                </Grid>
                <Grid item sm={12} xs={12} style={{textAlign:"center"}}>
                      <ArrowBackIcon style={{cursor:"pointer"}} onClick={prev}/>
                      <span style={{background: "#3c3c44", borderRadius: "6px", display: "inline-block", padding: "0.5em 1em", margin: "0.5em", color: "white"}}>{sday}</span>
                      <ArrowBackIcon style={{transform:"rotate(180deg)", cursor:"pointer"}} onClick={next} />
                </Grid>
                 <Grid item sm={6} xs={12}>
                    <Grid container>
                        <Grid item xs={8} style={{marginBottom:"20px"}}>
                              <Typography component="h2" variant="h5"> Total: </Typography>
                        </Grid>
                        <Grid item xs={4} style={{marginBottom:"20px"}}>
                              <Typography component="p"> {dday.total} €</Typography>
                        </Grid>
                    </Grid>
                     <Grid container>
                         <Grid item xs={8} style={{ marginBottom:"20px"}}>
                               <Typography component="h2" variant="h5"> Web: </Typography>
                         </Grid>
                         <Grid item xs={4} style={{marginBottom:"20px"}}>
                               <Typography component="p"> {dday.web} € </Typography>
                         </Grid>
                     </Grid>
                      <Grid container>
                          <Grid item xs={8} style={{marginBottom:"20px"}}>
                                <Typography component="h2" variant="h5"> Local en Efectivo: </Typography>
                          </Grid>
                          <Grid item xs={4} style={{ marginBottom:"20px"}}>
                                <Typography component="p"> {dday.efec} € </Typography>
                          </Grid>
                      </Grid>
                       <Grid container>
                           <Grid item xs={8} style={{marginBottom:"20px"}}>
                                 <Typography component="h2" variant="h5"> Local en tarjeta: </Typography>
                           </Grid>
                           <Grid item xs={4} style={{marginBottom:"20px"}}>
                                 <Typography component="p"> {dday.card} € </Typography>
                           </Grid>
                       </Grid>
                </Grid>
                <Grid item sm={6} xs={12} >
                   <Grid container>
                       <Grid item xs={12}>
                          <ResponsiveContainer width={"100%"} height={400}>
                              <BarChart
                                  data={gday}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" />
                                  <YAxis />
                                  <Tooltip />
                                  <Bar dataKey="total" fill="#8884d8" />
                                </BarChart>
                          </ResponsiveContainer>
                       </Grid>
                   </Grid>
               </Grid>


          </Grid>
          <Grid container spacing={3} style={{paddingTop:"2em"}}>
                <Grid item sm={12} xs={12} style={{textAlign:"center"}}>
                    <Typography component="h2" variant="h3"> Ventas semanales </Typography>
                </Grid>
                <Grid item sm={12} xs={12} style={{textAlign:"center"}}>
                      <ArrowBackIcon style={{cursor:"pointer"}} onClick={prevW}/>
                      <span style={{background: "#3c3c44", borderRadius: "6px", display: "inline-block", padding: "0.5em 1em", margin: "0.5em", color: "white"}}>{wday}</span>
                      <ArrowBackIcon style={{transform:"rotate(180deg)", cursor:"pointer"}} onClick={nextW} />
                </Grid>
                 <Grid item sm={6} xs={12}>
                    <Grid container>
                        <Grid item xs={8} style={{marginBottom:"20px", color:"#8884d8"}}>
                              <Typography component="h2" variant="h5"> Total: </Typography>
                        </Grid>
                        <Grid item xs={4} style={{marginBottom:"20px"}}>
                              <Typography component="p"> {dweek.total} €</Typography>
                        </Grid>
                    </Grid>
                     <Grid container>
                         <Grid item xs={8} style={{ marginBottom:"20px", color:"#82ca9d"}}>
                               <Typography component="h2" variant="h5"> Web: </Typography>
                         </Grid>
                         <Grid item xs={4} style={{marginBottom:"20px"}}>
                               <Typography component="p"> {dweek.web} € </Typography>
                         </Grid>
                     </Grid>
                      <Grid container>
                          <Grid item xs={8} style={{marginBottom:"20px", color:"rgb(255, 198, 88)"}}>
                                <Typography component="h2" variant="h5"> Local en Efectivo: </Typography>
                          </Grid>
                          <Grid item xs={4} style={{ marginBottom:"20px"}}>
                                <Typography component="p"> {dweek.efec} € </Typography>
                          </Grid>
                      </Grid>
                       <Grid container>
                           <Grid item xs={8} style={{marginBottom:"20px", color: "rgb(208, 237, 87)"}}>
                                 <Typography component="h2" variant="h5"> Local en tarjeta: </Typography>
                           </Grid>
                           <Grid item xs={4} style={{marginBottom:"20px"}}>
                                 <Typography component="p"> {dweek.card} € </Typography>
                           </Grid>
                       </Grid>

                       <Grid container>
                           <Grid item xs={3} style={{color:"#8884d8"}}>
                                 <span style={{display:"inline-block", width:"10px", height:"10px", background:"#8884d8"}}></span> Total
                           </Grid>
                           <Grid item xs={3} style={{color:"#82ca9d"}}>
                                 <span style={{display:"inline-block", width:"10px", height:"10px", background:"#82ca9d"}}></span> Web
                           </Grid>
                           <Grid item xs={3} style={{color:"rgb(255, 198, 88)"}}>
                                 <span style={{display:"inline-block", width:"10px", height:"10px", background:"rgb(255, 198, 88)"}}></span> Efectivo
                           </Grid>
                           <Grid item xs={3} style={{color:"rgb(208, 237, 87)"}}>
                                 <span style={{display:"inline-block", width:"10px", height:"10px", background:"rgb(208, 237, 87"}}></span> Tarjeta
                           </Grid>
                       </Grid>
                </Grid>
                <Grid item sm={6} xs={12} >
                   <Grid container>
                       <Grid item xs={12}>
                          <ResponsiveContainer width={"100%"} height={400}>
                              <BarChart
                                  data={gweek}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" />
                                  <YAxis />
                                  <Tooltip />
                                  <Bar dataKey="total" fill="#8884d8" />
                                  <Bar dataKey="web" fill="#82ca9d" />
                                  <Bar dataKey="efec" fill="rgb(255, 198, 88)" />
                                  <Bar dataKey="tar" fill="rgb(208, 237, 87)" />
                                </BarChart>
                          </ResponsiveContainer>
                       </Grid>
                   </Grid>
               </Grid>


          </Grid>
        </>

    </Layout>

  );
}
