import React, { useState, useEffect }  from 'react';
import { useParams,useHistory } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {cashService as service, printService} from "../../../services"
import {isAdmin} from '../../utils/Utils'
import PrintIcon from '@material-ui/icons/Print';
import Form from '../../form/Form';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import RequestQuoteIcon from '@material-ui/icons/InsertDriveFile';
import toast from 'toasted-notes'
const moment = require("moment");

const initFilter =  {
    number:null,
    idUser : null,
    idLaundry: null,
    idState:null,
    web:true
};


const initFilterExport =  {
  from:null,
  to:null
};


const useStyles1 = makeStyles({
  table: {
    minWidth: 500,
  },
  tablecontainer: {
    boxShadow: "none",
  },
  bodymodal: {
    margin: "auto",
    width: "500px",
    maxWidth: "calc(100vh - 40px)",
    background: "white",
    padding: "5em",
    marginTop: "100px",
    textAlign: "center"
  }
});


export default function Cash() {
  const [openExport, setOpenExport] = React.useState(false);
  const [filter, setFilter] = React.useState(initFilterExport);
  const classes = useStyles1();

  const createEstructureFilter =  [
    {
      type:"input",
      name: "from",
      label: "Desde",
      inputtype:"date",
      col: 4
     },
     {
       type:"input",
       name: "to",
       label: "Hasta",
       inputtype:"date",
       col: 4
      },
  ];


  const handlePrint = (cash) => {
    debugger;
    printService.cash(cash,(data,error)=>{});
  }

  const paintCell = {
    effectiveInit: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    effective: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    effectiveRetire: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    card: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    effectiveClose: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    effectiveDiff: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }

  const createEstructureFilterExport =  [
    {
      type:"input",
      name: "from",
      label: "Desde",
      inputtype:"date",
      col: 6
     },
     {
       type:"input",
       name: "to",
       label: "Hasta",
       inputtype:"date",
       col: 6
      },
 ]

  const searchFilterExport = (filter) => {
    console.log(filter);
    setFilter(filter);
    handleCloseExport();
    print(filter);
  }

  const handleCloseExport = () => {
    setOpenExport(!openExport);
  };

  const print = (filter) =>{
    service.print("CierresDeCaja"+moment(new Date()).format("yyyyMMddHHmmss")+".xls",filter,  (data,erro) =>{
      
    });
  }



  return (
    <><CrudTable
          service={service}
          titlePage={"Cierres de caja"}
          titleButton={"Nuevo cierre"}
          urledit={"/cash/edit/"}
          urlnew={"/cash/new/"}
          filter={initFilter}
          fieldstable={["date","hourFrom","hourTo","effectiveInit","effective","effectiveRetire","card","effectiveClose","effectiveDiff", "effectivePending"]}
          headersTable={["Día", "Desde","Hasta","Al abrir","Com. Efc","Retirada Efc.","Comp. Tar/Bizum/..." ,"Al cerrer","Diferencia", "Pedidos de hoy no cobrados"]}
          estructureForm={createEstructureFilter}
          moreActions={[{"handle":handlePrint, icon:<PrintIcon />}]}
          paintCell={paintCell}
          haveother={true}
          othersPanel= {
            <Grid container spacing={3}>
                <Grid item xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Button variant="contained" color="default" style={{marginLeft:'30px'}} onClick={() => setOpenExport(true)}> Exportar </Button>
              </Grid>
             
            </Grid>
          }
      />
      <Modal
                open={openExport}
                onClose={handleCloseExport}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div className={classes.bodymodal}>
                  <div style={{marginBottom:'30px'}}>
                      ¿Quieres exportar un listado de cierres de caja?
                  </div>
                  <Form objectform={filter} fields={createEstructureFilterExport}  searchFilter={searchFilterExport} cleanFilter={handleCloseExport} labelsave={"Exportar"} labelcancel={"Cancelar"} cleanInOK={true} isHiddenSave={false} isHiddenCancel={false}/>
              </div>
              </Modal>

    </>
  );
}
