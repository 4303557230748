import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {operatorService as service, clientService, laundryService, printService} from "../../../services"
import {isAdmin} from '../../utils/Utils'
import PrintIcon from '@material-ui/icons/Print';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';

const initFilter =  {
    number:null,
    idUser : null,
    idLaundry: null,
    idState:null,
    web:true
};


export default function Operator() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "login",
      label: "Login",
      col: 4
     },
     {
       type:"input",
       name: "name",
       label: "Nombre",
       col: 4
      },
  ];

  



  return (
    <CrudTable
          service={service}
          titlePage={"Dependientes"}
          titleButton={"Nuevo dependiente"}
          urledit={"/operator/edit/"}
          urlnew={"/operator/new/"}
          filter={initFilter}
          fieldstable={["login","name"]}
          headersTable={["Login", "Nombre"]}
          estructureForm={createEstructureFilter}
      />
  );
}
