import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CrudTable from "../../crud/CrudTable"
import {tpvService as service,clientService, billService} from "../../../services"
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Form from '../../form/Form';
import Modal from '@material-ui/core/Modal';
import RefreshIcon from '@material-ui/icons/Refresh';
import RequestQuoteIcon from '@material-ui/icons/InsertDriveFile';
import toast from 'toasted-notes'

const moment = require("moment");

const initFilter =  {
    number:null,
    idUser : null,
    isPayment: null,
    isPickup:null,
    numberkey:null,
    labelwash:null,
    from:null,
    to:null
};

const initFilterExport =  {
  fromPayment:null,
  toPayment:null
};


const useStyles1 = makeStyles({
  table: {
    minWidth: 500,
  },
  tablecontainer: {
    boxShadow: "none",
  },
  bodymodal: {
    margin: "auto",
    width: "500px",
    maxWidth: "calc(100vh - 40px)",
    background: "white",
    padding: "5em",
    marginTop: "100px",
    textAlign: "center"
  }
});

export default function Tickets() {
  const history = useHistory();
  const [openExport, setOpenExport] = React.useState(false);
  const [openRect, setOpenRect] = React.useState(false);
  const classes = useStyles1();
  const [filter, setFilter] = React.useState(initFilterExport);
  const [ticketRect, setTicketRect] = React.useState({});
  var ticketsSelected = [];

  const handleCloseExport = () => {
    setOpenExport(!openExport);
  };

  const handleCloseRect = () => {
    setOpenRect(!openRect);
  };
  const createEstructureFilter =  [
    {
      type:"input",
      name: "number",
      label: "Nº ",
      col: 4
     },
     {
       type:"input",
       name: "numberkey",
       label: "Llavero de recogida",
       col: 4
      },
     {
       type:"input",
       name: "labelwash",
       label: "Etiqueta de lavado",
       col: 4
      },
    {
      type:"autocomplete",
      name: "idUser",
      label: "Cliente",
      service: clientService.combo,
      col: 4
     },
     {
       type:"checkbox",
       name: "isPayment",
       label: "Pagado",
       col: 4
      },
      {
        type:"checkbox",
        name: "isPickup",
        label: "Recogido",
        col: 4
       },
       {
         type:"input",
         name: "from",
         label: "Desde",
         inputtype:"date",
         col: 4
        },
        {
          type:"input",
          name: "to",
          label: "Hasta",
          inputtype:"date",
          col: 4
         },
    ]

    const createEstructureFilterExport =  [
         {
           type:"input",
           name: "fromPayment",
           label: "Desde",
           inputtype:"date",
           col: 6
          },
          {
            type:"input",
            name: "toPayment",
            label: "Hasta",
            inputtype:"date",
            col: 6
           },
      ]
  
      const searchFilterExport = (filter) => {
        console.log(filter);
        setFilter(filter);
        handleCloseExport();
        print(filter);
      }

  const print = (filter) =>{
    service.print("Tickets"+moment(new Date()).format("yyyyMMddHHmmss")+".xls",filter,  (data,erro) =>{
      
    });
  }


  const handleRecti = (ticket) => {
    if(ticket.isPyament) {
      setTicketRect(ticket);
      setOpenRect(true);
    } else {
      toast.notify("Para generar un abono el ticket debe estar pagado", {position:'top', duration: 10000})
    }
  }

  const handleBill = (ticket) => {
    if(ticket.isPyament) {
      billService.ticket(ticket['id'], (data,erro) =>{
        if (data && data.data && data.data.id){
          history.push("/bill/edit/"+data.data.id);
          location.reload();
        } else {
          toast.notify("Ha ocurrido un error al generar la factura", {position:'top', duration: 10000})
        }
      });
    } else {
      toast.notify("Para generar una factura el ticket debe estar pagado", {position:'top', duration: 10000})
    }
  }


  const rectify = () =>{
    handleCloseRect();
    service.rectify(ticketRect['id'], (data,error) => {
      debugger;
        if(data.data) {
            history.push("/tpv/"+data.data.id);
        }
    });
  }


  const paintCell = {
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    numberTicketPickup:(value,object) => {
      if(value){
        return <>{value}</>
      } else {
        if(object['numberTicket']){
          return <></>
        } else {
          return <>Borrador</>
        }
      }
    }

  }

  const handlerSelected = (ids) => {
    debugger;
    ticketsSelected = ids;
  }

  const clickCreateBill = (evt) => {
    debugger;
    if(!ticketsSelected || ticketsSelected.length == 0){
      toast.notify("Debe seleccionar de que tickets quiere crear la factura", {position:'top', duration: 10000})
    } else {
        billService.severalTicket(ticketsSelected,(data,error)=>{
          if (data && data.data && data.data.id){
            history.push("/bill/edit/"+data.data.id);
            location.reload();
          }
        });
    }
  }

    useEffect(() => {
      ticketsSelected = []

    }, []);


  return (
    <><CrudTable
          service={service}
          titlePage={"Tickets"}
          titleButton={"Nuevo ticket"}
          urledit={"/tpv/"}
          urlnew={"/tpv"}
          filter={initFilter}
          deleteaction={true}
          fieldstable={["numberTicket","numberTicketPickup","date","client.name|client.email|client.phone","total","isPyament"]}
          headersTable={["#","Albaran","Fecha","Cliente","Total","Pagado"]}
          defaultValues={{numberTicket:"Borrador",numberTicketPickup:"Borrador",date:null,name:null,total:null}}
          estructureForm={createEstructureFilter}
          haveother={true}
          moreActions={[{"handle":handleBill, icon:<RequestQuoteIcon />},{"handle":handleRecti, icon:<RefreshIcon />}]}
          paintCell={paintCell}
          selectable={true}
          handlerSelected={handlerSelected}
          othersPanel= {
            <Grid container spacing={3}>
                <Grid item xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={clickCreateBill}> Crear factura </Button>
                  <Button variant="contained" color="default" style={{marginLeft:'30px'}} onClick={() => setOpenExport(true)}> Exportar </Button>
              </Grid>
             
            </Grid>
          }/>
          
        <Modal
                open={openExport}
                onClose={handleCloseExport}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div className={classes.bodymodal}>
                  <div style={{marginBottom:'30px'}}>
                      ¿Quieres exportar un listado de tickets?
                  </div>
                  <Form objectform={filter} fields={createEstructureFilterExport}  searchFilter={searchFilterExport} cleanFilter={handleCloseExport} labelsave={"Exportar"} labelcancel={"Cancelar"} cleanInOK={true} isHiddenSave={false} isHiddenCancel={false}/>
              </div>
              </Modal>
        <Modal
            open={openRect}
            onClose={handleCloseRect}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <div className={classes.bodymodal}>
              <div style={{marginBottom:'30px'}}>
                  ¿Quieres hacer un abono del ticket?
              </div>
              <div>
                <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleCloseRect}> No</Button>
                <Button variant="contained" color="primary" onClick={rectify}> Sí </Button>
              </div>
          </div>
          </Modal>
      </>
  );
}
