import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import { useParams,useHistory } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useStyles,drawerWidth } from "../../layout/styles";
import 'toasted-notes/src/styles.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import CrudForm from "../../crud/CrudForm"
import Filter from "../../filter/Filter"
import Form from "../../form/Form"
import Button from '@material-ui/core/Button';
import {workService as service, stateworkService, uploadService, printService} from "../../../services"
import Webcam from "react-webcam";
import Modal from '@material-ui/core/Modal';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import {isAdmin,idLaundry,nameLaundry,getUrlImage} from '../../utils/Utils'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import {estructureformProducts, estructureformTicket, estructureform, initElement, estructureformDir, estructureStatePR, estructureStateL, estructureStateLT, estructureStateP, estructureformProductsTickets, estructureIssue, estructureIssueResolve, estructureformDelivery, estructureformProductsDelivery } from './headerswizard'

const useStyles2 = makeStyles({
  bodymodal: {
    margin: "auto",
    width: "500px",
    maxWidth: "calc(100vh - 40px)",
    background: "white",
    marginTop: "50px",
    textAlign: "center"
  },
  titlemodal:{
    background:"#3c3c44",
    padding: "0.5em 0em",
    color: "white",

  },
  imgState:{
      display: "flex",
      width:"80px",
      height:"auto",
      "@media (max-width: 1200px)": {
         width: "60px"
      }
  },
  spanState:{
     display: "flex",
  },
  panelState:{
    display: "flex",
    textAlign: "center",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  panelIssue:{
    textAlign: "center",
    background: "rgba(255,0,0,0.2)",
    color: "darkred",
    border: "1px solid darkred",
    borderRadius: "6px",
    padding: "0.5em",
    margin: "0 5px"
  },
  panelIssueAccept:{
    textAlign: "center",
    background: "rgba(0,255,0,0.2)",
    color: "darkgreen",
    border: "1px solid darkgreen",
    borderRadius: "6px",
    padding: "0.5em",
    margin: "0 5px"
  }
});


const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

export default function WizardWork() {
  const classes = useStyles2();
  const classesg = useStyles();
  const history = useHistory();
  let { id,ticket } = useParams();
  const [open, setOpen] =useState(true);


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const [objectform, setObjectform] = React.useState(initElement);
  const [capture, setCapture] = React.useState(false);
  const [viewvideo, setViewvideo] = React.useState(false);
  const [openCapture, setOpenCapture] = React.useState(false);
  const [openview, setOpenview] = React.useState(false);
  const [urlvideo,setUrlvideo] = React.useState("");
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [forcesave, setForcesave] = React.useState(false);
  const [isTicket, setIsTicket] = React.useState(id > 0 && ticket == 1);
  const [isDeliverynote, setIsDeliverynote] = React.useState(id > 0 && ticket == 2);
  const [idRow, setIdrow] = useState(id);
  const [stateswork,setStatework] = useState([]);
  const [nextState,setNextState] = useState({});
  const [prevState,setPrevState] = useState({});
  const [finishVideo,setFinishVideo] = useState(false);




  const [openPR, setOpenPR] = React.useState(false);
  const [openL, setOpenL] = React.useState(false);
  const [openLT, setOpenLT] = React.useState(false);
  const [openP, setOpenP] = React.useState(false);
  const [openV, setOpenV] = React.useState(false);
  const [openIssue, setOpenIssue] = React.useState(false);
  const [openIssueResolve,setOpenIssueResolve] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const [printEtiqueta, setPrintEtiqueta] = React.useState(false);



  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState(null);

  const handleStartCaptureClick = React.useCallback(() => {
     setCapturing(true);

      if(webcamRef != null && webcamRef.current != null && webcamRef.current.stream != null) {
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
         mimeType: "video/webm"
       });

       mediaRecorderRef.current.addEventListener(
         "dataavailable",
         handleDataAvailable
       );

       mediaRecorderRef.current.start();
    }



   }, [webcamRef, setCapturing, mediaRecorderRef]);

   const handleDataAvailable = React.useCallback(({ data }) => {
            if (data.size > 0) {
              setRecordedChunks(data);

              if (mediaRecorderRef.current.state == "inactive"){
                setFinishVideo(true);
              }
            }
          }, [setRecordedChunks]);



  const upload = (recordedChunks) => {
    if (recordedChunks.size) {
      console.log("Entra en subir");

      uploadService.uploadWithName(recordedChunks,"blob.webm", (data,erro) =>{
         setOpenCapture(false);
         setOpenV(false);
         setCapturing(false);

         if(data && data.data) {
          objectform['videopath'] = data.data
          objectform['state'] = {id:nextState.key, name:nextState.name};
          setObjectform(currentValueform => ({...currentValueform, ...objectform }));
          setUrlvideo(getUrlImage(data.data))
          saveWork();
          setViewvideo(true);
          setCapture(false);
          setForcesave(true);
         }
         
      });

      setRecordedChunks(null);
      setFinishVideo(false);
    }
  }

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);










  const recoveryElement = function(objectedit,element){

    objectedit['date']=element.date?element.date:"";
    objectedit['hour']=element.hour?element.hour:"";
    objectedit['dateDelivery']=element.dateDelivery?element.dateDelivery:"";
    objectedit['hourDelivery']=element.hourDelivery?element.hour:"";
    objectedit['client']={id:element.client && element.client.id?element.client.id:null, name: element.client && element.client.name?element.client.name:null};
    objectedit['laundry']={id:element.laundry && element.laundry.id?element.laundry.id:null, name: element.laundry && element.laundry.name?element.laundry.name:null};
    objectedit['state']={id:element.state && (element.state.id || element.state.id == 0)?element.state.id:null, name: element.state && element.state.name?element.state.name:null};
    objectedit['orderclient']=element.orderclient?element.orderclient:{id:null,lines:[]};
    objectedit['ticket']=element.ticket?element.ticket:{id:null,lines:[]};
    objectedit['deliveryNote']=element.deliveryNote?element.deliveryNote:{id:null,lines:[]};

    objectedit['labelwash']=element.labelwash?element.labelwash:"";
    objectedit['numberkey']=element.numberkey?element.numberkey:"";
    objectedit['videopath']=element.videopath?element.videopath:"";


    objectedit['location']=element.location?element.location:{
       id:null,
       name:"",
       street:"",
       phone:"",
       number:"",
       door:"",
       floor:"",
       stairs:"",
       city:"",
       country:"España",
       postalcode:"",
       latitude:"",
       longitude:"",
    };


    setViewvideo(element.videopath != null && element.videopath.length > 0);
    setCapture(element.videopath == null || element.videopath.length == 0);

    setUrlvideo(getUrlImage(element.videopath))

    setObjectform(objectedit);

    return objectedit;
  }



  const isValid = function(object){
      if(!object['client'] || !object['client']['id']){
        toast.notify("Debe indicar el cliente", {position:'top', duration: 10000})
        return false;
      }
      if(!object['laundry'] || !object['laundry']['id']){
        toast.notify("Debe indicar la lavandería", {position:'top', duration: 10000})
        return false;
      }
      if(!object['state'] || !object['state']['id']){
        toast.notify("Debe indicar el estado", {position:'top', duration: 10000})
        return false;
      }
      if((!object['ticket'] || !object['ticket']['id']) && (!object['location'] || !object['location']['street']  || object['location']['street'] == "")){
        toast.notify("Debe indicar la calle", {position:'top', duration: 10000})
        return false;
      }
      if((!object['ticket'] || !object['ticket']['id']) && (!object['location'] || !object['location']['postalcode']  || object['location']['postalcode'] == "")){
        toast.notify("Debe indicar el código postal", {position:'top', duration: 10000})
        return false;
      }
      if((!object['ticket'] || !object['ticket']['id']) && (!object['hour'] || object['hour'] == "")){
        toast.notify("Debe indicar la hora de recogida", {position:'top', duration: 10000})
        return false;
      }
      if((!object['ticket'] || !object['ticket']['id']) && (!object['hourDelivery'] || object['hourDelivery'] == "")){
        toast.notify("Debe indicar la hora de entrega", {position:'top', duration: 10000})
        return false;
      }

      if((!object['ticket'] || !object['ticket']['id']) && (!object['dateDelivery'] || object['dateDelivery'] == "")){
        toast.notify("Debe indicar la fecha de entrega", {position:'top', duration: 10000})
        return false;
      }
      if((!object['ticket'] || !object['ticket']['id']) && (!object['date'] || object['date'] == "")){
        toast.notify("Debe indicar la fecha de recogida", {position:'top', duration: 10000})
        return false;
      }
      return true;
  }

  const openRecord = (event) => {

      setOpenCapture(true);
      setOpenV(true);
  }
  const moreActions = () => {
    return <>
          {viewvideo && <Button variant="outlined" color="primary" style={{marginLeft:'30px'}} onClick={() => setOpenview(true)}> Ver recogida </Button>}
          {capture && <Button variant="outlined" color="primary" style={{marginLeft:'30px'}} onClick={openRecord}> Grabar recogida </Button>}
          <Button variant="outlined" color="primary" style={{marginLeft:'30px'}}> Imprimir ticket </Button>
      </>
  }

  const handleClose = () => {
     setOpenCapture(false);
     setOpenV(false);
     setCapturing(false);
   };


   const handleCloseview = () => {
      setOpenview(false);
    };



 const beforeSave = (object) => {
    object['videopath'] = objectform['videopath'];
    return object;
 }


 useEffect(() => {

     if(idRow && idRow > 0){
       searchOne();
     }

 }, [idRow]);


  useEffect(() => {
    if(finishVideo){

      upload(recordedChunks)
    }
  }, [finishVideo]);


  useEffect(() => {

    printService.configure(2, (data,error) => {
        debugger;
        setPrintEtiqueta(data && data.data && data.data.enablePrint);
    });

}, []);
 const searchOne = function(){
   service.one(idRow,(data, error) => {

     if(data && data.data){
       var element = data.data;
       let objectedit = JSON.parse(JSON.stringify(element));
       objectedit = recoveryElement(objectedit,element)
       setObjectform(objectedit);
       if (isTicket){
         searchStateTicket(objectedit);
       } else {
         searchStateWork(objectedit);
       }
    }
   });
 }



  const searchStateTicket = function(objectform){
    stateworkService.combolocal((data, error) => {
      if(data && data.data){
        setStatework(data.data)

        findNextState(objectform,data.data);
        findPrevState(objectform,data.data);
     }
    });
  }

   const searchStateWork = function(objectform){
     stateworkService.combo((data, error) => {
       if(data && data.data){
         setStatework(data.data)

         findNextState(objectform,data.data);
         findPrevState(objectform,data.data);
      }
     });
   }



   const goToBack = (object) => {
       history.push("/work");
   }

   const goToEdit = (object) => {
     history.push("/work/edit/"+idRow+"/"+(!isTicket && !isDeliverynote?"0":isTicket?"1":"2"));
   }

   const goToPrint = (object) => {
      printWork(objectform);
   }


    const goToTicket = (object) => {
        history.push("/tpv/"+objectform.ticket.id);
    }

   const findNextState = (objectform,stateswork) => {

     console.log("Entra en next")
     console.log(objectform)
      if (objectform?.state?.id != 10){
          var isthenext = false;
          var found = false;
          if(stateswork.length > 0) {
              stateswork.sort((a, b) => a.key > b.key ? 1 : -1).map((field) => {
                if(objectform?.state?.id == field.key) {
                console.log("Encontrado")
                   isthenext = true;
                } else if (isthenext && !found){
                console.log("Siguiente")
                  setNextState(field);
                  found = true;
                }
            });
          }
      } else {
        setNextState({});
      }
   }

   const findPrevState = (objectform,stateswork) => {

     console.log("Entra en next")
     console.log(objectform)
      if (objectform?.state?.id != 0){
          var isthenext = false;
          var found = false;
          if(stateswork.length > 0) {
              stateswork.sort((a, b) => a.key > b.key ? -1 : 1).map((field) => {
                if(objectform?.state?.id == field.key) {
                console.log("Encontrado")
                   isthenext = true;
                } else if (isthenext && !found){
                console.log("Siguiente")
                  setPrevState(field);
                  found = true;
                }
            });
          }
      } else {
        setPrevState({});
      }
   }


   const goToNext = (object) => {
      if (nextState.key == 1){ //Pendiente de recoger
        setOpenPR(true);
      } else if (nextState.key == 2){ //Recogido
        openRecord(true);
      } else if (nextState.key == 3){ //En proceso de lavado
        if(printEtiqueta){
          setOpenLT(true);
        } else {
           setOpenL(true);
        }
      } else if (nextState.key == 5){ //Listo para el reparto
        setOpenP(true);
      } else if (nextState.key == 6){ //En reparto
        setOpenConfirm(true);
      } else if (nextState.key == 9){ //Preparado para entrega
        setOpenP(true);
      } else if (nextState.key == 10){ //Entregado
        setOpenConfirm(true);
      }
   }


  const goToPrev = (object) => {
      objectform['state'] = {id:prevState.key, name:prevState.name};
      setObjectform(objectform);
      setObjectform(currentValueform => ({...currentValueform, ["state"]:{id:prevState.key, name:prevState.name}  }));
      saveWork();
  }

  const saveWork = () => {
    service.edit(idRow,objectform, (data, error) => {
        if(data){
            findPrevState(objectform,stateswork);
            findNextState(objectform,stateswork);
            if(isTicket && data.data.state.id == 10){
              goToTicket();
            }
        }
    });
  }


  const saveFormPR = (object) => {
      if(!object['numberkey'] || object['numberkey'] == ""){
        toast.notify("Debe indicar el nº de llavero", {position:'top', duration: 10000})
        return false;
      } else {
        setOpenPR(false);
        objectform["numberkey"] = object['numberkey'];
        objectform['state'] = {id:nextState.key, name:nextState.name};
        setObjectform(currentValueform => ({...currentValueform, ...objectform }));
        saveWork();
      }

  }

  const saveFormIssue = (object) => {
      if(!object['motiveIssue'] || object['motiveIssue'] == ""){
        toast.notify("Debe indicar el motivo", {position:'top', duration: 10000})
        return false;
      } else {
        setOpenIssue(false);
        objectform["motiveIssue"] = object['motiveIssue'];
        objectform['haveIssue'] = true;
        setObjectform(currentValueform => ({...currentValueform, ...objectform }));
        saveWork();
      }

  }

  const saveFormIssueResolve = (object) => {
      if(!object['resolveIssue'] || object['resolveIssue'] == ""){
        toast.notify("Debe indicar la solucion", {position:'top', duration: 10000})
        return false;
      } else {
        setOpenIssueResolve(false);
        objectform["resolveIssue"] = object['resolveIssue'];
        objectform['resolverAdmin'] = true;
        setObjectform(currentValueform => ({...currentValueform, ...objectform }));
        saveWork();
      }

  }




  const saveFormL = (object) => {
      if(!object['labelwash'] || object['labelwash'] == ""){
        toast.notify("Debe indicar la etiqueta de lavado", {position:'top', duration: 10000})
        return false;
      } else {
        setOpenL(false);
        objectform["labelwash"] = object['labelwash'];
        objectform['state'] = {id:nextState.key, name:nextState.name};
        setObjectform(currentValueform => ({...currentValueform, ...objectform }));
        saveWork();
      }

  }
  const saveFormLT = (object) => {
    if(object['copy'] && +object['copy'] > 0){
      printLabel(objectform,object['copy']);
    } 

    setOpenLT(false);
    objectform["labelwash"] = object['ticket'] && object['ticket']['numberTicket'] ?  object['ticket']['numberTicket'] : object['orderclient'] && object['orderclient']['numberOrder'] ? object['orderclient']['numberOrder'] : object['deliveryNote']['numberDelivery'];
    objectform['state'] = {id:nextState.key, name:nextState.name};
    setObjectform(currentValueform => ({...currentValueform, ...objectform }));
    saveWork();

}

  const saveFormP = (object) => {
      if(!object['locationInLocal'] || object['locationInLocal'] == ""){
        toast.notify("Debe indicar donde queda almacenado", {position:'top', duration: 10000})
        return false;
      } else {
        setOpenP(false);
        objectform["locationInLocal"] = object['locationInLocal'];
        objectform['state'] = {id:nextState.key, name:nextState.name};
        setObjectform(currentValueform => ({...currentValueform, ...objectform }));
        saveWork();
      }

  }

  const saveFormConf = () => {
      setOpenConfirm(false);
      objectform['state'] = {id:nextState.key, name:nextState.name};
      setObjectform(currentValueform => ({...currentValueform, ...objectform }));
      saveWork();

  }

  const printWork= (t) => {
    printService.work(t,(data,error)=>{});
  }

  const printLabel= (t,copies) => {
    printService.worklabel(copies,t, (data,error) => {
    
    });
  }



  return (
    <>
      <div className={classesg.root}>
        <CssBaseline />
        <Header title={"Orden de trabajo"} classes={classesg} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
        <Menu classes={classesg} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
        <main className={classesg.content}>
          <div className={classesg.appBarSpacer} />
          <Container maxWidth="lg"  className={classesg.container} >
             <Grid container style={{marginBottom:"1em"}}>
            {objectform?.haveIssue &&
                  <Grid item xs={6}>
                        <p className={classes.panelIssue}> <ReportProblemIcon style={{marginBottom: "-7px"}}/> {objectform.motiveIssue}</p>
                  </Grid>
            }
            {objectform?.resolverAdmin &&
                  <Grid item xs={6}>
                        <p className={classes.panelIssueAccept}> <DoneIcon style={{marginBottom: "-7px"}}/> {objectform.resolveIssue}</p>
                  </Grid>
            }
            {objectform?.haveIssueFromTicket &&
                <Grid item xs={6}>
                      <p className={classes.panelIssueAccept}> <DoneIcon style={{marginBottom: "-7px"}}/> Se le indico al cliente en la recogida</p>
                </Grid>
            }
            {objectform?.resolverIsuseByClient &&
                <Grid item xs={6}>
                      <p className={classes.panelIssueAccept}> <DoneIcon style={{marginBottom: "-7px"}}/> El cliente acepta continuar con el trabajo</p>
                </Grid>
            }
            {objectform?.notPermitedIsuseByClient &&
                  <Grid item xs={6}>
                        <p className={classes.panelIssue}> <ClearIcon style={{marginBottom: "-7px"}}/> El cliente a rechazo continuar con el trabajo</p>
                  </Grid>
            }

          </Grid>
            <Grid container style={{marginBottom:"1em"}}>
                  {stateswork.sort((a, b) => a.key > b.key ? 1 : -1).map((field) => (
                    field.key != 7 && field.key != 0 ? <Grid item xs={2} className={classes.panelState} style={{opacity:(objectform?.state?.id >= field.key?1:0.1)}}>
                          <img src={"/img/"+field.key+".png"} className={classes.imgState} /><span className={classes.spanState}>{field.name}</span>
                    </Grid> :<></>
                  ))}
            </Grid>
            <Grid container>
              {!isTicket && !isDeliverynote && <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                    <Form objectform={objectform} fields={estructureform} isHiddenSave={true} isHiddenCancel={true}/>
                 </Grid>
              }
              {isTicket && <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                    <Form objectform={objectform} fields={estructureformTicket} isHiddenSave={true} isHiddenCancel={true}/>
                 </Grid>
              }
              {isDeliverynote && <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                    <Form objectform={objectform} fields={estructureformDelivery} isHiddenSave={true} isHiddenCancel={true}/>
                 </Grid>
              }


                {!isTicket && !isDeliverynote && <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                      <Filter labeltitle="Datos de Direccion" estructure={estructureformDir}  hiddenButton={true} filter={objectform} notopeninit={true} onlyRead={true}/>
                </Grid> }
                <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                      <Filter labeltitle="Artículos/Servicios/Productos" estructure={isTicket?estructureformProductsTickets:!isDeliverynote?estructureformProducts:estructureformProductsDelivery}  hiddenButton={true} filter={objectform} notopeninit={false} onlyRead={true}/>
                </Grid>
                <Grid container>
                  <Grid item sm={12} style={{textAlign:'right', marginTop: '10px'}}>
                    {prevState.key >= 0 && <Button variant="contained" color="secondary" style={{marginLeft:'10px'}} onClick={goToPrev}> Volver a {prevState.name} </Button>}
                    {nextState.key && <Button variant="contained" color="primary" style={{marginLeft:'10px'}} onClick={goToNext}> Pasar a {nextState.name} </Button>}
                  </Grid>
                  <Grid item sm={12} style={{textAlign:'right', marginTop: '10px'}}>
                    {(objectform?.haveIssue == null || !objectform.haveIssue) && <Button variant="outlined" color="primary" style={{marginLeft:'10px'}} onClick={() => setOpenIssue(true)}> Indicar incidencia </Button>}
                    {(objectform?.haveIssue != null && objectform.haveIssue) && <Button variant="outlined" color="primary" style={{marginLeft:'10px'}} onClick={() => setOpenIssueResolve(true)}> Solucionar incidencia </Button>}
                    {isTicket && <Button variant="outlined" color="primary" style={{marginLeft:'10px'}} onClick={goToTicket}> Ver ticket </Button>}
                    {(objectform?.state?.id >= 2 && objectform.videopath && objectform.videopath != "" ) && <Button variant="outlined" color="primary" style={{marginLeft:'10px'}} onClick={() => setOpenview(true)}> Ver recogida </Button> }
                    <Button variant="outlined" color="primary" style={{marginLeft:'10px'}} onClick={goToBack}> Volver al listado </Button>
                    <Button variant="outlined" color="primary" style={{marginLeft:'10px'}} onClick={goToEdit}> Editar datos </Button>
                    {(objectform?.state?.id >= 3 && printEtiqueta) && <Button variant="outlined" color="primary" style={{marginLeft:'10px'}} onClick={() => printLabel(objectform,1)}> Imprimir Etiqueta lavado </Button> }
                    <Button variant="outlined" color="primary" style={{marginLeft:'10px'}} onClick={goToPrint}> Imprimir Ticket </Button>
                  </Grid>
                </Grid>

            </Grid>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>

      <Modal
          open={openV}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
              <div className={classes.bodymodal}>
                  <div className={classes.titlemodal}>
                      <h3 style={{fontSize:"2em"}}>Grabar recogida </h3>
                      <p>Para pasar a  estado recogido se debe grabar la ropa</p>
                  </div>
                <div  style={{  padding: "1.2em"}}>
                        { openCapture && <Webcam
                          audio={false}
                          height={400}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          width={"100%"}
                          videoConstraints={videoConstraints}
                        /> }

                        { !openCapture && <div style={{height:"400px", textAlign:"center", border: "1px solid #ccc", borderRadius: "6px",  paddingTop: "9em"}}> <VideocamOffIcon  style={{fontSize:"10em"}}/> </div>}
                </div>
                <div  style={{  padding: "1.2em"}}>
                   {!capturing ? (<Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleStartCaptureClick}> Empezar</Button>) : (<Button variant="contained" color="primary" onClick={handleStopCaptureClick} style={{marginRight:'30px'}}> Parar </Button>) }

                  <Button variant="contained" color="secondary" onClick={handleClose}> Cerrar </Button>
                </div>
            </div>
        </Modal>

        <Modal
            open={openview}
            onClose={handleCloseview}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
              <div className={classes.bodymodal}>
                  <div className={classes.titlemodal}>
                    <h3 style={{fontSize:"2em"}}>Video de recogida recogida </h3>
                  </div>
                  <div style={{  padding: "1.2em"}}>
                    <div style={{marginBottom:'30px'}}>
                      <video width="100%" height="auto" controls>
                        <source src={urlvideo} type="video/webm" />
                        </video>
                    </div>
                    <div>
                      <Button variant="contained" color="secondary" onClick={handleCloseview}> Cerrar </Button>
                    </div>
                </div>
              </div>
          </Modal>



          <Modal
              open={openPR}
              onClose={() => setOpenPR(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
                <div className={classes.bodymodal}>
                    <div className={classes.titlemodal}>
                      <h3 style={{fontSize:"2em"}}>Pasar a Pendiente de recoger</h3>
                      <p>Para ello es necesario indicar el nº de llavero</p>
                    </div>
                    <div style={{  padding: "1.2em"}}>

                        <Grid container spacing={3}>
                            <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                                <Form objectform={objectform} fields={estructureStatePR} isHiddenSave={false} isHiddenCancel={false} searchFilter={saveFormPR} cleanFilter={() => setOpenPR(false)} labelsave={"Ok"} labelcancel={"Cancelar"} />
                            </Grid>
                        </Grid>
                   </div>
                </div>
            </Modal>


            <Modal
                open={openL}
                onClose={() => setOpenL(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                  <div className={classes.bodymodal}>
                      <div className={classes.titlemodal}>
                        <h3 style={{fontSize:"2em"}}>Pasar a en Proceso de lavado</h3>
                        <p>Para ello es necesario indicar la etiqueta de lavado</p>
                      </div>
                      <div style={{  padding: "1.2em"}}>
                          <Grid container spacing={3}>
                              <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                                  <Form objectform={objectform} fields={estructureStateL} isHiddenSave={false} isHiddenCancel={false} searchFilter={saveFormL} cleanFilter={() => setOpenL(false)} labelsave={"Ok"} labelcancel={"Cancelar"} />
                              </Grid>
                          </Grid>
                     </div>
                  </div>
              </Modal>

              <Modal
                open={openLT}
                onClose={() => setOpenLT(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                  <div className={classes.bodymodal}>
                      <div className={classes.titlemodal}>
                        <h3 style={{fontSize:"2em"}}>Cuantas copias de la etiqueta quiere imprimir</h3>
                      </div>
                      <div style={{  padding: "1.2em"}}>
                          <Grid container spacing={3}>
                              <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                                  <Form objectform={objectform} fields={estructureStateLT} isHiddenSave={false} isHiddenCancel={false} searchFilter={saveFormLT} cleanFilter={() => setOpenLT(false)} labelsave={"Ok"} labelcancel={"Cancelar"} />
                              </Grid>
                          </Grid>
                     </div>
                  </div>
              </Modal>

              <Modal
                  open={openP}
                  onClose={() => setOpenP(false)}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description">
                    <div className={classes.bodymodal}>
                        <div className={classes.titlemodal}>
                          <h3 style={{fontSize:"2em"}}>Pasar a listo para el reparto / Preparado para entrega</h3>
                          <p>Para ello es necesario indicar la ubicacion en tienda</p>
                        </div>
                        <div style={{  padding: "1.2em"}}>
                            <Grid container spacing={3}>
                                <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                                    <Form objectform={objectform} fields={estructureStateP} isHiddenSave={false} isHiddenCancel={false} searchFilter={saveFormP} cleanFilter={() => setOpenP(false)} labelsave={"Ok"} labelcancel={"Cancelar"} />
                                </Grid>
                            </Grid>
                       </div>
                    </div>
                </Modal>

                <Modal
                    open={openIssue}
                    onClose={() => setOpenIssue(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description">
                      <div className={classes.bodymodal}>
                          <div className={classes.titlemodal}>
                            <h3 style={{fontSize:"2em"}}>¿Ha ocurrido una incidencia?</h3>
                            <p>Debes indicar cual es el motivo</p>
                          </div>
                          <div style={{  padding: "1.2em"}}>
                              <Grid container spacing={3}>
                                  <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                                      <Form objectform={objectform} fields={estructureIssue} isHiddenSave={false} isHiddenCancel={false} searchFilter={saveFormIssue} cleanFilter={() => setOpenIssue(false)} labelsave={"Ok"} labelcancel={"Cancelar"} />
                                  </Grid>
                              </Grid>
                         </div>
                      </div>
                  </Modal>


                  <Modal
                      open={openIssueResolve}
                      onClose={() => setOpenIssueResolve(false)}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description">
                        <div className={classes.bodymodal}>
                            <div className={classes.titlemodal}>
                              <h3 style={{fontSize:"2em"}}>¿Se ha solucionado la incidencia?</h3>
                              <p>Debes indicar la solucion</p>
                            </div>
                            <div style={{  padding: "1.2em"}}>
                                <Grid container spacing={3}>
                                    <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
                                        <Form objectform={objectform} fields={estructureIssueResolve} isHiddenSave={false} isHiddenCancel={false} searchFilter={saveFormIssueResolve} cleanFilter={() => setOpenIssueResolve(false)} labelsave={"Ok"} labelcancel={"Cancelar"} />
                                    </Grid>
                                </Grid>
                           </div>
                        </div>
                    </Modal>





                  <Modal
                      open={openConfirm}
                      onClose={() => setOpenConfirm(false)}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description">
                        <div className={classes.bodymodal}>
                            <div className={classes.titlemodal}>
                              <h3 style={{fontSize:"1.2em"}}>¿Estas seguro que deseas psarlo a {nextState.name}?</h3>
                            </div>
                            <div style={{  padding: "2em"}}>
                                <Grid container spacing={3}>
                                  <Grid item sm={12} style={{textAlign:'right'}}>
                                     <Button variant="outlined" color="primary" style={{marginLeft:'30px'}} onClick={() => setOpenConfirm(false)}> Cancelar </Button>
                                     <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={saveFormConf}> Ok </Button>
                                  </Grid>
                                </Grid>
                           </div>
                        </div>
                    </Modal>






    </>

  );
}
