import React, { useState, useEffect }  from 'react';
import { useParams,useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles,drawerWidth } from "../../layout/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from '../../layout/Header';
import Menu from '../../layout/Menu';
import Copyright from '../../layout/Copyright';
import CrudTable from "../../crud/CrudTable"
import Modal from '@material-ui/core/Modal';
import {productlaundryService as service, categorylaundryService as categoryService,clientService, tpvService, printService, billService, paymenttypeService} from "../../../services"
import Form from "../../form/Form"
import {isAdmin,idLaundry,nameLaundry, operator, numDays,printticket} from '../../utils/Utils'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalClient from '../../client/ModalClient';
import Layout from '../../layout/Layout';

const moment = require("moment");


const initFilter =  {
    name : "",
    local:true
};

const initLine = {
  id: -1,
  product:null,
  name:"",
  image:null,
  amount: 1,
  price: "",
  total: 0.0,
  base:0.0,
  discount:0.0,
  discountPercent:0.0
}


const initTicket = {
    client:{id:null,name:""},
    laundry:{id:isAdmin()?null:idLaundry(),name:isAdmin()?"":nameLaundry()},
    numberTicket: null,
    total:0.0,
    base:0.0,
    discountPercent:0.0,
    discount:0.0,
    isSuscription:null,
    issueNote:"",
    lines:Array(),
    isPyament:false,
    operator: operator(),
    paymentType:{id:-1,name:""},
    numberKey: null

}

const initClient = {
     email:"",
     pass: "",
     name: "",
     lastname: "",
     isClient:true,
     rol: isAdmin()?{code:null,name:""}:{code: "CLIENT", name: "CLIENT"},
     enable: true,
     laundry:{id:null,name:""},
     picture:null,
     web:false,
     percentDiscount:null,
     numberKey: null,
     paymentType:{id:null,name:""},
     streetDefault:{
      id:null,
      name:"",
      street:"",
      phone:"",
      number:"",
      door:"",
      floor:"",
      stairs:"",
      city:"",
      country:"España",
      postalcode:"",
      latitude:"",
      longitude:"",
     }
  };

  const useStyles2 = makeStyles({
    bodymodal: {
      margin: "auto",
      width: "800px",
      maxWidth: "calc(100% - 40px)",
      background: "white",
      padding: "2em",
      marginTop: "100px",
      textAlign: "center"
    },
    inputformtime: {
      width: '100%',
      "& label": {
        transform: 'translate(0, 1.5px) scale(0.75)'
      }
    },
  });


export default function Tpv() {
  let { id } = useParams();
  const [idRow, setIdrow] = useState(id);
  const classes = useStyles();
  const classes2= useStyles2();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({... initFilter});
  const [open, setOpen] = useState(true);
  const [rows,setRows] = useState([{}]);
  const [rowsTotal,setRowsTotal] = useState(0);
  const [categories,setCategories] = useState([{}]);
  const [lines,setLines] = useState(Array());
  const [client,setClient] = useState({key:"-1",name:"", other: null});
  const [dateD,setDateD] = useState("");
  const [dateDForm,setDateDForm] = useState("");
  const [isseuNote,setIsseuNote] = useState("");
  const [numberKey,setNumberKey] = useState<any>(null);
  const [clients,setClients] = useState([{key:"-1",name:"", other:null}]);
  const [clientform,setClientform] = useState({...initClient});
  const [openClient, setOpenClient] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openEfective, setOpenEfective] = useState(false);
  const [openNewline, setOpenNewline] = useState(false);
  const [openTickets, setOpenTickets] = useState(false);
  const [openOtherPrint, setOpenOtherPrint] = useState(false);  
  const [openPrintFirst, setOpenPrintFirst] = useState(false);  
  
  const [cambio, setCambio] = useState(0);
  const [efective, setEfective] = useState(0);
  const [editable, setEditable] = useState(true);
  const [newLine,setNewLine] = useState({...initLine});

  const [ticket,setTicket] = useState({... initTicket});
  const [ticketsdelete,setTicketsdelete] = useState([]);
  const [ticketToPrint,setTicketToPrint] = useState({... initTicket});

  const [paymenttypes,setPaymenttypes] = useState<any>([]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const initDate = () => {
    
    var nDays = numDays() - 1;
    var day = moment().add(nDays,'days');
    if(day.day() == 6){
      day = moment().add(nDays + 2,'days');
    } else if(day.day() == 0){
      day = moment().add(nDays + 1,'days');
    }
    var dateDShow = day.format('YYYY-MM-DD');
    var dateDF= day.format('YYYY-MM-DD')+"T00:00:00Z";
    setDateD(dateDShow);
    setDateDForm(dateDF);

  }

  const createEstructureForm =  [
     {
       type:"input",
       name: "price",
       inputtype:"number",
       step:0.01,
       label: "Precio",
       col: 12
      },
     {
       type:"input",
       name: "salesprice",
       inputtype:"number",
       step:0.01,
       label: "Precio rebajado",
       col: 12
      },
  ];


  const estructureformClient =  [
          {
           type:"input",
           name: "email",
           label: "Email",
           col: 6
          },
          {
           type:"input",
           name: "nif",
           label: "NIF/CIF/DNI",
           col: 6
          },
          {
           type:"input",
           name: "name",
           label: "Nombre",
           col: 6
          },
          {
           type:"input",
           name: "lastname",
           label: "Apellido",
           col: 6
          },
          {
           type:"input",
           name: "phone",
           label: "Teléfono",
           col: 6
          },
          {
          type:"input",
          name: "percentDiscount",
          label: "Descuento por defecto",
          inputtype: "number",
          step:0.01,
          col: 6
          },
          {
            type:"autocomplete",
            name: "paymentType.id",
            label: "Forma de pago",
            service:paymenttypeService.combo,
            col: 6
           },
          {
           type:"title",
           name: "streettitle",
           label: "Dirección",
           col: 12
          },
          {
            type:"input",
            name: "streetDefault.street",
            label: "Calle",
            col: 8
           },
          {
            type:"input",
            name: "streetDefault.number",
            label: "Nº",
            col: 2
           },
          {
            type:"input",
            name: "streetDefault.stairs",
            label: "Escl.",
            col: 2
           },
          {
            type:"input",
            name: "streetDefault.floor",
            label: "Piso",
            col: 2
           },
          {
            type:"input",
            name: "streetDefault.door",
            label: "Puerta",
            col: 2
           },
          {
            type:"input",
            name: "streetDefault.city",
            label: "Ciudad",
            col: 4
           },
          {
            type:"input",
            name: "streetDefault.postalcode",
            label: "C.P",
            col: 4
           }
    ];




  const search = (page,size) => {
    setPage(page);
    setRowsPerPage(size);
    service.pagemyFilter(page,size,filter, (data, error) => {
        if(data){
          console.log(data);
          setRows(data.data);
          setRowsTotal(data.total);
        } else {
          setRows([{}]);
          setRowsTotal(0);
        }
    });
  }

  const searchCategories = () => {
    categoryService.search(0,(data, error) => {
        if(data){
          setCategories(data.data);
        } else {
          setCategories([{}]);
        }
    });
  }


    const searchClients = () => {
      clientService.combo((data, error) => {
          if(data){
            setClients(data.data);
          } else {
            setClients([]);
          }
      });
    }

    const searchPaymentTypes = () => {
      paymenttypeService.combo((data, error) => {
          if(data){
            setPaymenttypes(data.data);
          } else {
            setPaymenttypes([]);
          }
      });
    }


    const seachOneClient = () => {
      clientService.one(ticket['client']['id'], (data,error) => {
          setClientform(data.data);
          setOpenClient(true);
      });
    }



  const getUrlImage = function(namefile){
    if(namefile) {
      if (namefile.startsWith("http")) {
        return namefile;
      } else {
        var nameImage =namefile;
        nameImage = nameImage.startsWith("/")?nameImage:"/"+nameImage;
        return process.env.REACT_APP_HOST+'/image'+nameImage;
      }
    } else{
      return '';
    }
  }


  const clickCat = (cat) => {
      if (cat && cat['id'] && cat['id'] > 0) {
        filter['category'] = cat['id'];
      } else {
        filter['category'] = null;
      }

      search(0,200);

  }


  const paintCat = (cat) => {
    if (cat) {
      return <div style={{cursor: "pointer", textAlign:'right', marginBottom: '10px', padding:"5px", width:"160px", display:"inline-block"}} onClick={() => clickCat(cat)}>
              <div style={{border:"1px solid #ccc", borderRadius:"4px", padding:"5px"}}>
                 <div style={{textAlign:'right', marginBottom: '10px', backgroundImage:'url("'+getUrlImage(cat['imageprin'])+'")', height:"70px", backgroundRepeat: "no-repeat", backgroundSize: "100% auto"}}>
                 </div>
                  <div style={{textAlign:'center', marginBottom: '10px'}}>
                    <p style={{maxWidth: "100%",position: "relative",  wordBreak: "break-all", whiteSpace: "normal"}}>{cat['name']}</p>
                </div>
              </div>
          </div>
    } else {
      return <div style={{textAlign:'right', marginBottom: '10px', width:"150px"}}>

      </div>
    }

  }

  const changeLine = (line, value) => {
    if(value){
       line["amount"] = value;

       if(line["amount"] != 0){
        recalculateLine(line);
      } else {
        var i = 0;
        var pos = -1;
        ticket["lines"].forEach(element => {
          if(element['id'] == line["id"]){
            pos = i;
            return;
          }
          i++;
        });
  
        if(pos >= 0){
          ticket["lines"].splice(pos,1);
        }
  
        calculateTotalTicket();
  
  
      }
    }
  }

  const clickLessLine = (line) => {
    line["amount"] = line["amount"] - 1;
    if(line["amount"] != 0){
      recalculateLine(line);
    } else {
      var i = 0;
      var pos = -1;
      ticket["lines"].forEach(element => {
        if(element['id'] == line["id"]){
          pos = i;
          return;
        }
        i++;
      });

      if(pos >= 0){
        ticket["lines"].splice(pos,1);
      }

      calculateTotalTicket();


    }
  }

  const deleteLine = (line) => {
    var i = 0;
      var pos = -1;
      ticket["lines"].forEach(element => {
        if(element['id'] == line["id"]){
          pos = i;
          return;
        }
        i++;
      });

      if(pos >= 0){
        ticket["lines"].splice(pos,1);
      }

      calculateTotalTicket();
  }

  const clickMoreLine = (line) => {
    line["amount"] = line["amount"] + 1;
    if(line["amount"] != 0){
      recalculateLine(line);
    } else {
      var i = 0;
      var pos = -1;
      ticket["lines"].forEach(element => {
        if(element['id'] == line["id"]){
          pos = i;
          return;
        }
        i++;
      });

      if(pos >= 0){
        ticket["lines"].splice(pos,1);
      }

      calculateTotalTicket();


    }
  }

  const recalculateLine = (line) => {
    if(line["discountPercent"] > 0){
      line["discount"] = (line["amount"] * line["price"] ) * (line["discountPercent"]  / 100);
    } else {
      line["discount"] = 0;
    }

    line["total"] = (line["amount"] * line["price"] ) - line["discount"];

    ticket["total"] = 0;
    ticket["lines"].forEach(element => {
      if(element['id'] == line["id"]){
        element['amount'] = line["amount"];
        element['discount'] = line["discount"];
        element['total'] = line["total"];

      }
    });

    calculateTotalTicket();

  }

  const changeDiscount = (percent,line) => {
    line["discountPercent"] = percent;
    if(line["discountPercent"] > 0){
      line["discount"] = (line["amount"] * line["price"] ) * (line["discountPercent"]  / 100);
    } else {
      line["discount"] = 0;
    }

    line["total"] = (line["amount"] * line["price"] ) - line["discount"];

    ticket["lines"].forEach(element => {
      if(element['id'] == line["id"]){
        element['amount'] = line["amount"];
        element['discount'] = line["discount"];
        element['total'] = line["total"];

      }
    });


    calculateTotalTicket();
  }

  const paintLine = (line) => {
    return <Grid item sm={12}>
              <div style={{display: "flex", flexDirection:"row"}}>
                  <div style={{width:"15%"}}>
                        <img src={getUrlImage(line['image']?line['image']:(line['product'] && line['product']['imageprin']?line['product']['imageprin']:""))} style={{  width:'100%',  height:'auto',  maxWidth: '50px'}} />
                  </div>
                  <div style={{paddingTop:"1em", paddingLeft:"0.5em", width:"calc(30% - 30px)"}}>
                        {line['name']} <br/>
                        {line['numberTikcetRefund'] && line['refundTotal'] && <label>Se ha hecho la devolunción Total en el ticket {line['numberTikcetRefund']}</label>}
                        {line['numberTikcetRefund'] && !line['refundTotal'] && <label>Se ha hecho la devolunción Parcial de {line['amountRefund']} unidades en el ticket {line['numberTikcetRefund']}</label>}
                  </div>
                  <div style={{paddingTop:"1em", width: "30%"}}>
                      {editable && <Button variant="contained" color="primary" style={{marginRight:'10px', padding: '1px', minWidth: '30px', minHeight: '30px', borderRadius: '15px'}} onClick={() => clickLessLine(line)}> - </Button> }
                        <TextField value={line['amount']}  style={{width: "calc(100% - 90px)"}} type={"number"} inputProps={{step:0.01, style:{textAlign: "center", width:"100%"}}} onChange={(evt) => changeLine(line,evt.target.value)} />
                       {editable && <Button variant="contained" color="primary" style={{marginLeft:'10px', padding: '1px', minWidth: '30px', minHeight: '30px', borderRadius: '15px'}}onClick={() => clickMoreLine(line)} > + </Button> }
                  </div>
                  <div style={{paddingTop:"1em", width: "15%"}}>
                      <TextField name={"discountPercent"} label={"% Des."} value={line["discountPercent"]} style={{width:"100%"}} type={"number"} inputProps={{readOnly:!editable }}  onChange={(event) => changeDiscount(event.target.value,line)}/>
                  </div>
                  <div style={{paddingTop:"1em", textAlign:'right', width:"10%"}}>
                        <b>{line['total'].toFixed(2)}</b> €
                  </div>
                  <div style={{paddingTop:"1em", textAlign:'right', width:"30px"}}>
                        <span style={{cursor:"pointer"}} onClick={() => deleteLine(line)}><DeleteIcon /></span>
                  </div>
              </div>
        </Grid>
  }

  const addProduct = (product) => {
    if (editable) {
      const line = {...initLine};

      line['id'] = (Math.floor(Math.random() * (10000000-1)) + 1) * -1;
      line['product'] = product['product'];
      line['name'] = product['product']['name'];
      line['image'] = product['product']['imageprin'];
      line['amount'] = 1;
      line['price'] = product['salesprice'] && product['salesprice'] < product['price']?product['salesprice']:product['price'];
      line['total'] = Number(line['price']);

      ticket["lines"].push(line);

      calculateTotalTicket();

    }

  }

  const addProductNewLine = () => {

    newLine['id'] = (Math.floor(Math.random() * (10000000-1)) + 1) * -1;
    newLine['total'] = Number(newLine['price']) * newLine['amount'];

    ticket["lines"].push(newLine);

    calculateTotalTicket();

  }

  const calculateTotalTicket = () => {

    ticket["total"] = 0;

    ticket["lines"].forEach(element => {
      ticket["total"] = ticket["total"] + element['total'];
    });

    if(ticket['discountPercent'] && ticket['discountPercent'] > 0){
      ticket['discount'] = ticket["total"] * (ticket['discountPercent'] / 100);
      ticket["total"] = ticket["total"] - ticket['discount'];
    }



    setTicket(currentTicket => ({...currentTicket, ...ticket}))
    handleCloseNewline();
  }

  const paintProduct = (product) => {
    if (product && product['product']) {
      return <Grid item sm={3} style={{textAlign:'right', marginBottom: '10px', padding:"5px", cursor:"pointer"}} onClick={() => addProduct(product)}>
          <Grid container style={{border:"1px solid #ccc", borderRadius:"4px", padding:"5px"}}>
             <Grid item sm={12} style={{textAlign:'right', marginBottom: '10px', maxHeight:"100px", minHeight:"100px", overflow:"hidden"}}>
                <img src={getUrlImage(product['product']['imageprin'])} style={{  width:'100%',  height:'auto',  maxWidth: '100%'}} />
             </Grid>
              <Grid item sm={12} style={{textAlign:'center', marginBottom: '10px', minHeight:"37px", overflow:"hidden"}}>
                  <b>{product['product']['name']}</b>
             </Grid>
              <Grid item sm={12} style={{textAlign:'center', marginBottom: '10px', fontSize:"1.3em"}}>
                  {product['price']} {product['salesprice'] && product['salesprice'] < product['price']? " - " + product['salesprice']: ""} €

             </Grid>
          </Grid>
      </Grid>
    } else {
      return <Grid item sm={3} style={{textAlign:'right', marginBottom: '10px'}}>

      </Grid>
    }

  }


  const setClientCombo = (value) => {
    if(value && value.key){
      setClient(value);
      ticket['client'] = {id: value.key, name: value.name};
      setTicket(currentTicket => ({...currentTicket, ...ticket}))
    } else if(value && value.id){
      setClient(value);
      ticket['client'] = {id: value.id, name: value.name};
      setTicket(currentTicket => ({...currentTicket, ...ticket}))
    } else {
      setClient({key: "", name: "",other:null});
      ticket['client'] = {id: null, name: ""};
      setTicket(currentTicket => ({...currentTicket, ...ticket}))
    }

    if(value && value.other) {
      ticket['discountPercent'] = +value.other
      calculateTotalTicket();
    } else if(value && value.percentDiscount) {
      ticket['discountPercent'] = +value.percentDiscount
      calculateTotalTicket();
    } else {
      ticket['discountPercent'] = 0.0
    }
      
  }

  const handleCloseClient = () => {
    setOpenClient(false);
    setClientform({...initClient});
  }

  const handleClosePayment = () => {
    setOpenPayment(false);
  }

  const handleCloseEffective = () => {
    setOpenEfective(false);
    setEfective(0);
    setCambio(0);
  }

  const handleCloseNewline = () => {
    setNewLine({...initLine});
    setOpenNewline(false);
  }

  const saveFormClient = (object) => {
       handleCloseClient();
       if(object['id'] && object['id'] != "" && object['id'] != "-1"){
            clientService.edit(object['id'],object, (data, error) => {
              if(data){
                setClient({key:object.id, name: (data.data.name + " "+ (data.data.lastname ? data.data.lastname : "") + " - " + (data.data.email ? data.data.email : "") + " - " + (data.data.phone ? data.data.phone : "")), other: data.data.percentDiscount});
                ticket['client'] = data.data;
                setTicket(currentTicket => ({...currentTicket, ...ticket}))
                clients.push({key:object.id, name: (data.data.name + " "+ (data.data.lastname ? data.data.lastname : "") + " - " + (data.data.email ? data.data.email : "") + " - " + (data.data.phone ? data.data.phone : "")), other: data.data.percentDiscount});
                setClients(clients);
              }
          });
       } else {
            clientService.create(object, (data, error) => {
              if(data){
                setClient({key:object.id, name: (data.data.name + " "+ (data.data.lastname ? data.data.lastname : "") + " - " + (data.data.email ? data.data.email : "") + " - " + (data.data.phone ? data.data.phone : "")), other: data.data.percentDiscount});
                ticket['client'] = data.data;
                setTicket(currentTicket => ({...currentTicket, ...ticket}))
                clients.push({key:object.id, name: (data.data.name + " "+ (data.data.lastname ? data.data.lastname : "") + " - " + (data.data.email ? data.data.email : "") + " - " + (data.data.phone ? data.data.phone : "")), other: data.data.percentDiscount});
                setClients(clients);
              }
          });
       }
       

  }

  const changeEffective = (amount) => {
    setEfective(amount);
    setCambio(amount - ticket["total"]);
  }

  useEffect(() => {

    initDate();
    search(0,200);
    searchCategories();
    searchClients();
    searchPaymentTypes();
  }, []);


  useEffect(() => {
      if(idRow && idRow > 0){
        searchOne();
      };
  }, [idRow]);


  const saveTicket = (payment = false, recogido = false, effective = false) => {
    ticket["isPyament"] = payment;
    ticket["isPickup"] = recogido;
    ticket["effective"] = effective;
    if(efective){
      let paymentType = {id:1, name:"Efectivo"};
      ticket["paymentType"] = paymentType;
    }
    ticket["issueNote"] = isseuNote;
    ticket["numberKey"] = numberKey && numberKey != "" ? numberKey : null;
    ticket["operator"] = operator();
    if(dateDForm != ""){
      ticket["dateDelivery"] = dateDForm;
    } else {
      ticket["dateDelivery"] = null;
    }
    var continueSave = true;
    if ((payment || recogido) && (!ticket['client'] || !ticket['client']['id'] || ticket['client']['id'] == "-1" || ticket['client']['id'] == "")){
        continueSave = false;
          toast.notify("Debes indicar el cliente", {position:'top', duration: 10000})
    }

    if ((payment || recogido) && (!ticket['dateDelivery'])){
        continueSave = false;
          toast.notify("Debes indicar la fecha de recogida", {position:'top', duration: 10000})
    }

    if (continueSave){
      tpvService.create(ticket, (data, error) => {
          if(data){
            if((payment || recogido)){
              setTicketToPrint(data.data);
              
              if(printticket()) {
                printTicketFirst(data.data);
              } else {
                setOpenPrintFirst(true);
              }
            }
            cleanTicket();
            handleCloseEffective();
          }
      });

    }
  }

  const printTicket = (t) => {
    printService.ticket(t,(data,error)=>{});
  }

  const printTicketFirst = (t) => {
    printService.ticketFirst(t,(data,error)=>{
        setOpenOtherPrint(true);
    });
  }



  const checkPayment = () => {
      if (cambio >= 0){
         saveTicket(true,true,true);
      } else {
        toast.notify("El cambio no puede ser negativo", {position:'top', duration: 10000})
      }
  }

  const cleanTicket = () => {
      initDate();
      setEditable(true);
      initTicket["lines"] = [];
      initTicket["numberKey"] = null;
      setTicket({...initTicket})
      setClient({key:"-1",name:"",other:null})
      setIsseuNote("");
      setNumberKey("");
  }

  const recoveryTicket = (ticket) => {
      setTicket({...ticket})
      if(ticket['client'] && ticket['client']['name'] && ticket['client']['id']){
        setClient({key:ticket['client']['id'],name:ticket['client']['name'],other:ticket['client']['percentDiscount']?ticket['client']['percentDiscount']:0})
      } else {
        setClient({key:"-1",name:"",other:null})
      }
      if(ticket['date']){
        setDateD((moment(Date.parse(ticket['date']))).format('DD/MM/YYYY'));
        setDateDForm(ticket['date']);
      } else {
        initDate();
      }
      if(ticket["issueNote"]){
        setIsseuNote(ticket["issueNote"]);
      } else {
        setIsseuNote("");
      }

      if(ticket["numberKey"]){
        setNumberKey(ticket["numberKey"]);
      } else {
        setNumberKey("");
      }
      
      
      handleCloseTickets();
  }

  const searchOne = function(){
    tpvService.one(idRow,(data, error) => {

      if(data && data.data){
        var element = data.data;
        let ticket = JSON.parse(JSON.stringify(element));
        setEditable(!ticket['isPyament']);
        setTicket({...ticket})
        if(ticket['client'] && ticket['client']['name'] && ticket['client']['id']){
          setClient({key:ticket['client']['id'],name:ticket['client']['name'],other:ticket['client']['percentDiscount']?ticket['client']['percentDiscount']:0})
        } else {
          setClient({key:"-1",name:"",other:null})
        }
        if(ticket['dateDelivery']){
          setDateD((moment(Date.parse(ticket['dateDelivery']))).format('YYYY-MM-DD'));
          setDateDForm(ticket['dateDelivery']);
        } else {
          initDate();
        }

        if(ticket["issueNote"]){
          setIsseuNote(ticket["issueNote"]);
        } else {
          setIsseuNote("");
        }
        if(ticket["numberKey"]){
          setNumberKey(ticket["numberKey"]);
        } else {
         setNumberKey("");
        }
        

        

        if(ticket["message"] && ticket["message"] != ""){
          toast.notify(ticket["message"], {position:'top', duration: 10000})
        }

     }
    });
  }


  const deleteTicket = (ticket) => {
    tpvService.removeLogic(ticket['id'], (data, error) => {
        recoveryDeleteTickets();
    });
  }

  const handleCloseTickets = () => {
    setOpenTickets(false);
  }

  const recoveryDeleteTickets = () => {
    tpvService.pageFilter(0,100,{isPayment:false}, (data, error) => {
        if(data){
          setTicketsdelete(data.data);
        } else {
          setTicketsdelete([]);
        }
        setOpenTickets(true);
    });
  }


  const handleChangeDate = (event) => {
      let value = event.target.value;
      if (value && value != "") {
        var aux = (moment(Date.parse(value))).format('YYYY-MM-DD')+"T00:00:00Z";
        setDateD(value);
        setDateDForm(aux);
      } else {
        
        setDateD("");
        setDateDForm("");
      }

  }

  const handleOpenOtherPrint = (event) => {
    setOpenOtherPrint(false);
  }



  const handleOpenFirstPrint = (event) => {
    setOpenPrintFirst(false);
  }


  

  const createBill = (ticket) =>{
    billService.ticket(ticket['id'], (data,erro) =>{

      if (data && data.data && data.data.id){
        history.push("/bill/edit/"+data.data.id);
        location.reload();
      } else {
        toast.notify("Ha ocurrido un error al generar la factura", {position:'top', duration: 10000})
      }
    });
  }

  const selectPayment = (p) => {
    ticket["paymentType"] = {id:p.key, name:p.name};
    handleClosePayment(); 
     if(p.key == 1){
        setCambio(ticket["total"] * -1); 
        setEfective(0); 
        setOpenEfective(true);
     } else {
      saveTicket(true,true,false);
     }
  }

  return (
    <Layout titleWindow={"TPV"}>
        <>
        <Grid container>
            <Grid item sm={9} style={{marginBottom: "10px"}}>
                <Grid container>
                      <Grid item sm={8}>
                        <Autocomplete
                              options={clients}
                              getOptionLabel={ (option) => {return option.name}}
                              style={{width:"100%"}}
                              value={client}
                              onChange={(event,value) => setClientCombo(value)}
                              renderInput={(params) => <TextField {...params} label={"Cliente"}  inputProps={{...params.inputProps}} />}
                            />
                      </Grid>
                      {editable && ticket['client'] && ticket['client']['id'] && <Grid item xs={2} style={{textAlign:"right"}}><Button variant="contained" color="secondary" style={{marginLeft:'10px'}} onClick={() => seachOneClient()}> Editar cliente </Button>  </Grid>}
                      {editable && <Grid item xs={2} style={{textAlign:"right"}}><Button variant="contained" color="primary" style={{marginLeft:'10px'}} onClick={() => setOpenClient(true)}> Nuevo cliente </Button>  </Grid>}
                </Grid>
            </Grid>
            <Grid item sm={3} style={{marginBottom: "10px"}}>
                <Grid container>
                      <Grid item sm={12} style={{paddingLeft: "1em"}}>
                            <TextField id={"dateDelivery"} name={"dateDelivery"} label={"Día de entrega"} value={dateD} className={classes2.inputformtime} onChange={handleChangeDate} type={"date"}  InputLabelProps={{shrink: true}} />
                      </Grid>
                </Grid>
            </Grid>
            <Grid item sm={6} style={{textAlign:'left', marginBottom: '10px',height: "calc(100vh - 250px)", overflowX:"hidden", overflowY:"auto", borderRight:"1px solid #ccc"}}>
              <Grid container>
                <Grid item sm={12} style={{textAlign:'left', marginBottom: '10px'}}>
                   <h2> Categorias </h2>
                </Grid>
              </Grid>
              <div style={{overflowX:"scroll", overflowY:"hidden", width:"100%", whiteSpace:"nowrap", height:"150px"}}>
                {paintCat({id:-1,name:"Todos", imageprin:"1626417802929.jpeg"})}

                {(categories).map((cat) => (
                    paintCat(cat)
                ))}
              </div>
              <Grid container>
                <Grid item sm={12} style={{textAlign:'left', marginBottom: '10px'}}>
                   <h2> Productos </h2>
                </Grid>
              </Grid>
              <Grid container>
                {(rows).map((row) => (
                    paintProduct(row)
                ))}
              </Grid>
            </Grid>
            <Grid item sm={6} style={{textAlign:'left', marginBottom: '10px',height: "calc(100vh - 250px)", overflowX:"hidden", overflowY:"auto", padding:"0em 1em"}}>
                <div style={{height:"calc(100vh - 600px)"}}>
                    <h2 style={{height: "30px"}}>Productos</h2>
                    <div  style={{height:"calc(100% - 40px)", borderRadius:"6px", border:"1px solid #ccc", overflowY:"auto", padding:"1em"}}>
                        <Grid container>
                          {(ticket["lines"]).map((line) => (
                              paintLine(line)
                          ))}
                        </Grid>

                    </div>
                </div>
                <div style={{height:"50px", marginTop:"20px", display:"flex", alignItems:"center"}}>
                  <h3 style={{display:"inline-block", width: "100px"}}> Decuento </h3>
                  <TextField id={"discountPercent"} name={"discountPercent"} label={"% Descuento"} value={ticket['discountPercent']} className={classes2.inputformtime}  InputLabelProps={{shrink: true}} style={{margin: "0 0.5em"}} onChange={(evt) => {ticket['discountPercent'] = +evt.target.value; calculateTotalTicket();}}/>
                  <TextField id={"discount"} name={"discount"} label={"Descuento"} value={ticket['discount'] ? ticket['discount'].toFixed(2):0.00} className={classes2.inputformtime}  InputLabelProps={{shrink: true}}  inputProps={{readOnly: true}} style={{margin: "0 0.5em"}}/>

                </div>
                <div style={{height:"70px"}}>
                  <h3 style={{display:"inline-block", width: "100px"}}> TOTAL </h3>
                  <h1 style={{display:"inline-block", width: "calc(100% - 100px)", textAlign:"right"}}> {ticket['total'].toFixed(2)} € </h1>
                </div>
                <div style={{height:"70px", display:"flex", flexDirection:"row"}}>
                      <TextField id={"issueNote"} name={"issueNote"} label={"Notas de incidencia"} value={isseuNote} className={classes2.inputformtime} onChange={(event) => setIsseuNote(event.target.value)}  InputLabelProps={{shrink: true}} style={{marginRight:"1em"}}/>
                      <TextField id={"numberKey"} name={"numberKey"} label={"Etiqueta de lavado"} value={numberKey} className={classes2.inputformtime} onChange={(event) => setNumberKey(event.target.value)}  InputLabelProps={{shrink: true}} />
                      
                </div>
                <div style={{height:"100px"}}>
                 {editable && !ticket['numberTicketRec'] && <div>
                        <Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={()=>recoveryDeleteTickets()}> Recuperar </Button>  
                        <Button variant="outlined" color="primary"  style={{margin:'5px'}} onClick={() => {if(ticket["lines"].length > 0 )saveTicket(false,false,false); }}> Borrador </Button>
                        <Button variant="contained" color="secondary"  style={{margin:'5px'}} onClick={()=>cleanTicket()} > Limpiar </Button>
                        <Button variant="contained" color="secondary"  style={{margin:'5px'}} onClick={() => setOpenNewline(true)}> Nueva Linea </Button> 
                        <Button variant="outlined" color="primary"  style={{margin:'5px'}} onClick={() => {if(ticket["lines"].length > 0 ) saveTicket(false,true,false); }}> Recogido </Button> 
                        <Button variant="contained" color="primary"  style={{margin:'5px'}} onClick={() => {  if (ticket["lines"].length > 0 && ticket["client"] &&  ticket["client"]["id"] && ticket["client"]["id"] > 0 && (ticket['dateDelivery'] || dateDForm != "")) setOpenPayment(true); else
                          toast.notify("Debe añadir algun artículo, tener el cliente seleccionado y la fecha de recogida", {position:'top', duration: 10000})}}> Pagar </Button> 
                        {ticket['id'] && <Button variant="contained" color="secondary"  style={{margin:'5px'}} onClick={() => printTicket(ticket)}> Imprimir </Button> }
                  </div> }
                 {editable && ticket['numberTicketRec'] && <Grid container>
                        <Grid item xs={4} sm={4} lg={4}> <Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={()=>recoveryDeleteTickets()}> Recuperar </Button>  </Grid>
                        <Grid item xs={4} sm={4} lg={4}>  <Button variant="contained" color="secondary"  style={{margin:'5px'}} onClick={()=>cleanTicket()} > Limpiar </Button>  </Grid>
                        <Grid item xs={4} sm={4} lg={4}><Button variant="outlined" color="primary"  style={{margin:'5px'}} onClick={() => {saveTicket(true,true,false); }}> Generar </Button>  </Grid>
                  </Grid> }
                  {!editable &&  <Grid container>
                         <Grid item xs={4} sm={4} lg={2}>  <Button variant="contained" color="secondary"  style={{margin:'5px'}} onClick={()=>cleanTicket()} > Nuevo </Button>  </Grid>
                         <Grid item xs={4} sm={4} lg={2}><Button variant="contained" color="secondary"  style={{margin:'5px'}} onClick={() => printTicket(ticket)}> Imprimir </Button>  </Grid>
                         <Grid item xs={4} sm={4} lg={2}><Button variant="contained" color="secondary"  style={{margin:'5px'}} onClick={() => createBill(ticket)}> Crear factura </Button>  </Grid>
                   </Grid> }
                </div>

            </Grid>
        </Grid>
            <Modal
          open={openClient}
          onClose={handleCloseClient}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div className={classes2.bodymodal}>
            <div>
              <h2>Nuevo cliente</h2>
            </div>
            <div>
              <Form objectform={clientform} fields={estructureformClient}  searchFilter={saveFormClient} cleanFilter={handleCloseClient} labelsave={"Guardar"} labelcancel={"Cancelar"} />
            </div>
        </div>
        </Modal>


        <Modal
            open={openPayment}
            onClose={handleClosePayment}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <div className={classes2.bodymodal}>
                <div>
                  <h2>Forma de pago</h2>
                </div>
                <div>
                  {paymenttypes.sort((a, b) => (a.key < b.key) ? -1 : 1).map((p) => {
                     return <Button variant="contained" color="secondary" style={{marginRight:'10px', lineHeight:"6.75", minWidth:"100px"}} onClick={() => {selectPayment(p);}}> {p.name} </Button>
                  })}
                  
                </div>
            </div>
          </Modal>

          <Modal
              open={openEfective}
              onClose={handleCloseEffective}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              <div className={classes2.bodymodal}>
                  <div>

                    <Grid container>
                          <Grid item xs={6} style={{textAlign:"left", lineHeight:"4.0", fontSize:"1.4em"}}> <b>TOTAL</b> </Grid>
                          <Grid item xs={6} style={{textAlign:"right", lineHeight:"4.0", fontSize:"1.4em"}}> {ticket["total"].toFixed(2)} €</Grid>
                    </Grid>
                    <Grid container>
                          <Grid item xs={6} style={{textAlign:"left", fontSize:"1.4em"}}> Efectivo </Grid>
                          <Grid item xs={6} style={{textAlign:"right"}}>      <TextField name={"efectivo"} label={""} value={efective} style={{width:"100%"}} type={"number"}  onFocus={(event)=> {event.target.select()}} onChange={(event) => changeEffective(event.target.value)}/>
                          </Grid>
                          <Grid item xs={6} style={{textAlign:"left"}}></Grid>
                          <Grid item xs={6} style={{textAlign:"left", fontSize:"1em"}}>
                                  <Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={() => changeEffective(10)} > 10 </Button>
                                  <Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={() => changeEffective(15)} > 15 </Button>
                                  <Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={() => changeEffective(20)} > 20 </Button>
                                  <Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={() => changeEffective(25)} > 25 </Button>
                                  <Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={() => changeEffective(30)} > 30 </Button>
                                  <Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={() => changeEffective(40)} > 40 </Button>
                                  <Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={() => changeEffective(50)} > 50 </Button>
                                  <Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={() => changeEffective(60)} > 60 </Button>
                          </Grid>
                    </Grid>
                    <Grid container>
                          <Grid item xs={6} style={{textAlign:"left", lineHeight:"4.0", fontSize:"1.4em"}}> Cambio </Grid>
                          <Grid item xs={6} style={{textAlign:"right", lineHeight:"4.0", fontSize:"1.4em"}}> {cambio.toFixed(2)} €</Grid>
                    </Grid>
                  </div>
                  <div>
                    <Button variant="contained" color="secondary" style={{marginRight:'10px'}} onClick={() => checkPayment()}> Pagado </Button>
                  </div>
              </div>
            </Modal>



            <Modal
                open={openNewline}
                onClose={handleCloseNewline}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div className={classes2.bodymodal}>
                    <div>
                      <h2>Nueva linea </h2>
                      <Grid container>
                            <Grid item xs={12} style={{textAlign:"right"}}>  <TextField name={"name"} label={"Nombre"} value={newLine['name']} style={{width:"100%"}} onChange={(event) => setNewLine(currentNewline =>  ({...currentNewline, ["name"]:event.target.value}))}/></Grid>
                            <Grid item xs={12} style={{textAlign:"right"}}>  <TextField name={"amount"} label={"Cantidad"} value={newLine['amount']} style={{width:"100%"}} type={"number"} onFocus={(event)=> {event.target.select()}} onChange={(event) => setNewLine(currentNewline =>  ({...currentNewline, ["amount"]:+event.target.value}))}/></Grid>
                            <Grid item xs={12} style={{textAlign:"right"}}>  <TextField name={"price"} label={"Precio"} value={newLine['price']} style={{width:"100%"}} type={"number"} inputProps={{steps:0.01,min:-2000}} onFocus={(event)=> {event.target.select()}} onChange={(event) => {setNewLine(currentNewline =>  ({...currentNewline, ["price"]:event.target.value}))}}/></Grid>
                      </Grid>
                  </div>
                    <div style={{marginTop:"2em"}}>
                      <Button variant="contained" color="secondary" style={{marginRight:'10px'}} onClick={() => handleCloseNewline()}> Cancelar </Button>
                      <Button variant="contained" color="secondary" onClick={() => addProductNewLine()}> Añadir </Button>
                    </div>
                </div>
              </Modal>



              <Modal
                  open={openTickets}
                  onClose={handleCloseTickets}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description">
                  <div className={classes2.bodymodal}>
                      <div>
                        <h2>Recuperar ticket </h2>
                        {(ticketsdelete).map((t) => (
                            <Grid container>
                                <Grid item xs={6} style={{textAlign:"left"}}> { t['id'] + "- " + (t['client'] && t['client']['name']?t['client']['name']:"") + " - " + (t['client'] && t['client']['phone']?t['client']['phone']:"") } </Grid>
                                <Grid item xs={2} style={{textAlign:"center"}}><b>{Number(t['total']).toFixed(2)}€</b></Grid>
                                <Grid item xs={2} style={{textAlign:"center"}}><Button variant="outlined" color="primary" style={{margin:'5px'}} onClick={() => deleteTicket(t)}> Borrar </Button></Grid>
                                <Grid item xs={2} style={{textAlign:"center"}}><Button variant="contained" color="primary" style={{margin:'5px'}} onClick={() => recoveryTicket(t)}> Recuperar </Button></Grid>
                            </Grid>
                        ))}
                    </div>
                      <div style={{marginTop:"2em"}}>
                        <Button variant="contained" color="secondary" style={{marginRight:'10px'}} onClick={() => handleCloseTickets()}> Cancelar </Button>
                      </div>
                  </div>
                </Modal>

              <Modal
                open={openOtherPrint}
                onClose={handleOpenOtherPrint}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div className={classes2.bodymodal}>
                    <div>
                      <h2>¿Quieres imprimir copia del ticket?</h2>
                    </div>
                    <div>
                      <Button variant="contained" color="secondary" style={{marginRight:'10px', lineHeight:"6.75", minWidth:"100px"}} onClick={(evt) => {handleOpenOtherPrint(evt);}}> No </Button>
                      <Button variant="contained" color="primary" style={{lineHeight:"6.75", minWidth:"100px"}} onClick={(evt) => {handleOpenOtherPrint(evt);printTicket(ticketToPrint) }}> Sí </Button>
                    </div>
                </div>
              </Modal>


              <Modal
                open={openPrintFirst}
                onClose={handleOpenFirstPrint}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div className={classes2.bodymodal}>
                    <div>
                      <h2>¿Quieres imprimir el ticket?</h2>
                    </div>
                    <div>
                      <Button variant="contained" color="secondary" style={{marginRight:'10px', lineHeight:"6.75", minWidth:"100px"}} onClick={(evt) => {handleOpenFirstPrint(evt);}}> No </Button>
                      <Button variant="contained" color="primary" style={{lineHeight:"6.75", minWidth:"100px"}} onClick={(evt) => {handleOpenFirstPrint(evt);printTicketFirst(ticketToPrint) }}> Sí </Button>
                    </div>
                </div>
              </Modal>
        
        </>
    </Layout>
  );
}
